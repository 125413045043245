/* eslint-disable max-len */

import { NOTIFICATION_CTA_TRANSLATION_KEY_ENUM } from '~/Configurations/notificationUtilityMapping';

export default {
  notification_cta: {
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.ACCEPT_NOW]: 'Ambil Sekarang',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.COMPLETE_NOW]: 'Lengkapi Sekarang',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.FILL_TRUCK_DETAILS]: 'Isi Detail Truk',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.FIND_OTHER_PAYLOAD]: 'Cari Muatan Lain',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.MAKE_OFFER_NOW]: 'Beri Tawaran Sekarang',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.SEE_DETAILS]: 'Lihat Detail',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.VIEW_PARTNERSHIP_DETAILS]: 'Lihat Detail Kemitraan',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.VIEW_SHIPMENT_DETAILS]: 'Lihat Detail Pengiriman',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.VIEW_INVOICE_DETAILS]: 'Lihat Invoice',
  },
};
