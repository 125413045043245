import React, { lazy, useContext } from 'react';
import {
  Redirect,
  Switch,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid } from '@kargotech/tms-ui/components';
import { useFlag } from '@unleash/proxy-client-react';
import { get } from 'lodash-es';
import { PrivateRoute } from '~/Components/Route';
import { AVAILABLE_NFT_MODULES, COMPANY_VERIFICATION_LEVEL, LOCAL_SHIPMENT_STATUSES } from '~/Configurations/constants';

import { ProfileContext } from '~/Contexts/ProfileProvider';
import { NftModulesContext } from '~/Contexts/NftModulesProvider';
import useTheLorryIntegration from '~/Hooks/useTheLorryIntegration';

const { useBreakpoint } = Grid;

// New File System
const AllShipmentListPage = lazy(() => import(/* webpackChunkName: "AllShipmentListPage" */
  '~/Modules/Shipment/Pages/AllShipmentListPage/AllShipmentListPage'
));

const UpcomingShipmentListPage = lazy(() => import(/* webpackChunkName: "UpcomingShipmentListPage" */
  '~/Modules/Shipment/Pages/UpcomingShipmentListPage/UpcomingShipmentListPage'
));
const ShipmentListPage = lazy(() => import(/* webpackChunkName: "ShipmentListPage" */
  '~/Modules/Shipment/Pages/ShipmentListPage/ShipmentListPage'
));
const TransporterShipmentDetailProvider = lazy(() => import(/* webpackChunkName: "ShipmentDetailProvider.jsx" */
  '~/Modules/Shipment/Contexts/ShipmentDetailProvider'
));
const BrokerShipmentFeesPage = lazy(() => import(/* webpackChunkName: "BrokerShipmentFeesPage" */
  '~/Modules/Shipment/Pages/BrokerShipmentFeesPage/BrokerShipmentFeesPage'
));
const ShipmentDetailPage = lazy(() => import(/* webpackChunkName: "ShipmentDetailPage" */
  '~/Modules/Shipment/Pages/ShipmentDetailPage/ShipmentDetailPage'
));
const ShipmentFeesPageTransporter = lazy(() => import(/* webpackChunkName: "ShipmentFeesPage" */
  '~/Modules/Shipment/Pages/ShipmentFeesPage/ShipmentFeesPage'
));
const ShipmentExpensesPageTransporter = lazy(() => import(/* webpackChunkName: "ShipmentExpensesPage" */
  '~/Modules/Shipment/Pages/ShipmentExpensesPage/ShipmentExpensesPage'
));
const ShipmentTracePage = lazy(() => import(/* webpackChunkName: "ShipmentTracePage" */
  '~/Modules/Shipment/Pages/ShipmentTracePage/ShipmentTracePage'
));

const ShipmentRoutes = () => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const {
    profile,
  } = useContext(ProfileContext);
  const BTMS_DO_MP = useFlag('TC-5650_BTMS-DO-MP');
  const NFT_ROUTE_TRACING = useFlag('TC-6793_NFT-ROUTE-TRACING');
  const { isTheLorryTransporter } = useTheLorryIntegration();

  const verificationStatus = get(profile, 'company.pbiTransporterVerification.currentActiveCompanyStatus');
  const HAS_BTMS_PARTNER = BTMS_DO_MP && verificationStatus !== COMPANY_VERIFICATION_LEVEL.CREATED;
  const HAS_SHIPPER_PARTNER = Boolean(get(profile, 'company.hasConsentedCustomer'));
  const isUpcomingTabVisible = (HAS_BTMS_PARTNER || HAS_SHIPPER_PARTNER) && !isTheLorryTransporter;

  const {
    [AVAILABLE_NFT_MODULES.SHIPMENT_INCOME]: showShipmentIncome,
    [AVAILABLE_NFT_MODULES.EARNING_REPORT]: showEarningsReport,
  } = useContext(NftModulesContext);

  const shipmentPageTabNavigation = Object.keys(LOCAL_SHIPMENT_STATUSES)
    .filter(statusKey => {
      if (LOCAL_SHIPMENT_STATUSES[statusKey] === LOCAL_SHIPMENT_STATUSES.UPCOMING) {
        return isUpcomingTabVisible;
      }

      return !([
        LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE,
        LOCAL_SHIPMENT_STATUSES.AT_LOCATION,
        LOCAL_SHIPMENT_STATUSES.IN_TRANSIT,
      ].includes(LOCAL_SHIPMENT_STATUSES[statusKey]));
    })
    .map(
      statusKey => ({
        path: `/shipment/list/${LOCAL_SHIPMENT_STATUSES[statusKey]}`,
        title: t(
          `common:local_shipment_statuses.${LOCAL_SHIPMENT_STATUSES[statusKey]}`,
        ),
      }),
    );

  return (
    <Switch>
      <PrivateRoute
        exact={true}
        path="/shipment/list/all"
        tabNavigation={shipmentPageTabNavigation}
        title={t('common:shipment')}
      >
        <AllShipmentListPage />
      </PrivateRoute>

      {!isUpcomingTabVisible && screens.md && (
        <Redirect
          from="/shipment/list/upcoming"
          to="/shipment/list/planned"
        />
      )}
      <PrivateRoute
        exact={true}
        path="/shipment/list/upcoming"
        tabNavigation={shipmentPageTabNavigation}
        title={t('common:shipment')}
      >
        <UpcomingShipmentListPage />
      </PrivateRoute>

      <PrivateRoute
        exact={true}
        path="/shipment/list/:shipmentStatus"
        tabNavigation={shipmentPageTabNavigation}
        title={t('common:shipment')}
      >
        <ShipmentListPage />
      </PrivateRoute>
      <Redirect
        exact={true}
        from="/shipment"
        to="/shipment/list/all"
      />
      <Redirect
        exact={true}
        from="/shipment/list"
        to="/shipment/list/all"
      />

      <PrivateRoute
        exact={true}
        path="/shipment/details/:shipmentID"
        skipTabNavigationSetup={true}
      >
        <TransporterShipmentDetailProvider>
          <ShipmentDetailPage />
        </TransporterShipmentDetailProvider>
      </PrivateRoute>
      {showShipmentIncome && (
        <PrivateRoute
          exact={true}
          path="/shipment/income/:shipmentID"
          skipTabNavigationSetup={true}
        >
          <TransporterShipmentDetailProvider>
            <ShipmentFeesPageTransporter />
          </TransporterShipmentDetailProvider>
        </PrivateRoute>
      )}
      {showEarningsReport && (
        <PrivateRoute
          exact={true}
          path="/shipment/expenses/:shipmentID"
          skipTabNavigationSetup={true}
        >
          <TransporterShipmentDetailProvider>
            <ShipmentExpensesPageTransporter />
          </TransporterShipmentDetailProvider>
        </PrivateRoute>
      )}
      <PrivateRoute
        exact={true}
        path="/shipment/trace/:shipmentID"
        skipTabNavigationSetup={true}
      >
        <TransporterShipmentDetailProvider>
          <ShipmentTracePage />
        </TransporterShipmentDetailProvider>
      </PrivateRoute>
      <PrivateRoute
        exact={true}
        path="/shipment/fees/:shipmentID"
        skipTabNavigationSetup={true}
      >
        <TransporterShipmentDetailProvider>
          <BrokerShipmentFeesPage />
        </TransporterShipmentDetailProvider>
      </PrivateRoute>
      {NFT_ROUTE_TRACING && (
      <PrivateRoute
        exact={true}
        path="/shipment/trace/:shipmentID"
        skipTabNavigationSetup={true}
      >
        <TransporterShipmentDetailProvider>
          <ShipmentTracePage />
        </TransporterShipmentDetailProvider>
      </PrivateRoute>
      )}
    </Switch>
  );
};

export default ShipmentRoutes;
