import * as Sentry from '@sentry/react';

window.RELEASE_TAG = process.env.REACT_APP_RELEASE_TAG || undefined;

if (window.SENTRY_DSN) {
  Sentry.init({
    dsn: window.SENTRY_DSN,
    environment: window.location.origin.includes('localhost')
      ? 'LOCAL'
      : window.ENVIRONMENT,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    maxBreadcrumbs: 5,
    release: window.RELEASE_TAG,
  });
}

export default Sentry;
