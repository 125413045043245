/* eslint-disable max-len */

import {
  AR_AGING_LABELS,
  CUSTOM_INVOICE_FEE_UNITS,
  DOF2_ELIGIBILITY_STATUS,
  REIMBURSEMENT_TYPE,
  SHIPMENT_STATUSES,
  SHIPMENT_POINT_TYPES,
  SHIPMENT_TEMPLATE_SAVE_TYPE,
  DOCUMENT_TYPES,
  BTMS_DRIVER_REASSIGN_STATUS,
  INVOICE_FORMAT_DEFAULT,
  MONTH_INVOICE_FORMAT,
  YEAR_INVOICE_FORMAT,
  PROJECT_DOCUMENT_STATUSES,
  WHT_SELECTION_TYPE,
} from '~/Configurations/constants';
import { DOCUMENT_REQUEST_TYPE, SHIPMENT_DOCUMENT_STATUSES } from '~/Modules/Shipment/Utilities/constants';

export default {
  account_deactivation_warning_message: 'Anda tidak akan bisa gunakan rekening bank ini untuk membuat invoice',
  account_successfully_updated_as_default: 'Rekening {{account_number}} berhasil disimpan sebagai rekening utama',
  activity_log_time: 'Aktivitas: {{time}} {{timezone}}',
  additional_notes: 'Catatan: {{notes}}',
  add_additional_detail: 'Detail {{payment_type}}',
  add_additional_fee: 'Tambah {{payment_type}}',
  add_additional_fee_for_shipment: 'Tambah Biaya Lain untuk {{shipment_number}}',
  additional_fee_successfully_added: '{{payment_type}} berhasil ditambahkan.',
  additional_fee_successfully_updated: '{{payment_type}} berhasil diperbaharui.',
  against_the_invoice_value: '{{percentage}} terhadap nilai invoice',
  amount_per_unit_type: {
    [CUSTOM_INVOICE_FEE_UNITS.PER_CBM]: '{{amount}} CBM',
    [CUSTOM_INVOICE_FEE_UNITS.PER_KG]: '{{amount}} KG',
  },
  ar_aging_label_map: {
    [AR_AGING_LABELS.AGING_DUE]: 'Jatuh tempo dalam {{days}} hari',
    [AR_AGING_LABELS.AGING_DUE_TODAY]: 'Jatuh tempo pada hari ini',
    [AR_AGING_LABELS.AGING_OVERDUE]: 'Lewat jatuh tempo selama {{days}} hari',
  },
  average_load_time: 'Rata-rata Waktu Muat - {{vehicleLabel}}: {{time}} jam',
  average_unload_time: 'Rata-rata Waktu Bongkar - {{vehicleLabel}}: {{time}} jam',
  average_vehicle_load_time: 'Rata-rata Waktu Muat - Semua Jenis Armada: {{time}} jam',
  average_vehicle_unload_time: 'Rata-rata Waktu Bongkar - Semua Jenis Armada: {{time}} jam',
  bidding_delivery_details: 'Tawaran Pengiriman {{biddingNumber}}',
  bidding_point_multi: 'Poin {{pointName}}',
  bidding_point_multi_map: {
    [SHIPMENT_POINT_TYPES.PICKUP]: 'Muat',
    [SHIPMENT_POINT_TYPES.DROPOFF]: 'Bongkar',
    [SHIPMENT_POINT_TYPES.PICKUP_DROPOFF]: 'Bongkar + Muat',
  },
  bidding_point_schedule: 'Jadwal {{pointType}}: {{date}} WIB',
  bidding_point_slot: 'Tawaran Pengiriman {{slot}} Slot Unit',
  bill_need_to_be_more_than_amount: 'Tagihan Kolektif harus diatas {{amount}}',
  btms_driver_assignment_status: {
    [BTMS_DRIVER_REASSIGN_STATUS.REASSIGNMENT_PENDING]: 'Perubahan {{updatedItems}} diajukan oleh {{actor}}',
    [BTMS_DRIVER_REASSIGN_STATUS.REASSIGNMENT_CANCELLED]: 'Perubahan {{updatedItems}} dibatalkan oleh {{actor}}',
    [BTMS_DRIVER_REASSIGN_STATUS.REASSIGNMENT_REJECTED]: 'Perubahan {{updatedItems}} ditolak oleh {{actor}}',
    [BTMS_DRIVER_REASSIGN_STATUS.REASSIGNMENT_APPROVED_BY_BROKERAGE]: 'Perubahan {{updatedItems}} diverifikasi oleh {{actor}}',
    [BTMS_DRIVER_REASSIGN_STATUS.REASSIGNMENT_APPROVED_BY_SHIPPER]: 'Perubahan {{updatedItems}} diverifikasi oleh {{actor}}',
    [BTMS_DRIVER_REASSIGN_STATUS.REASSIGNMENT_APPROVED]: 'Perubahan {{updatedItems}} diverifikasi oleh {{actor}}',
    toast_update_assignment: 'Pengajuan perubahan no.polisi dan supir berhasil',
    toast_cancel_assignment: 'Pengajuan perubahan no.polisi dan supir berhasil dibatalkan',
  },
  btms_do_slot_calculation: '({{capacity}}{{unit}}{{price}}{{slotUnits}})',
  btms_document_status_tag: {
    [SHIPMENT_DOCUMENT_STATUSES.PENDING]: '{{docType}} Sedang Diverifikasi',
    [SHIPMENT_DOCUMENT_STATUSES.APPROVED]: '{{docType}} Diverifikasi',
    [SHIPMENT_DOCUMENT_STATUSES.REJECTED]: 'Unggah Ulang {{docType}}',
    [SHIPMENT_DOCUMENT_STATUSES.INVALID]: 'Tidak Valid',
  },
  btms_upload_cta: {
    [DOCUMENT_REQUEST_TYPE.REUPLOAD]: {
      [DOCUMENT_TYPES.POD]: 'Unggah ulang SJ Bongkar {{sequence}}',
      [DOCUMENT_TYPES.POL]: 'Unggah ulang SJ Muat {{sequence}}',
    },
    [DOCUMENT_REQUEST_TYPE.UPLOAD]: {
      [DOCUMENT_TYPES.POD]: 'Unggah SJ Bongkar {{sequence}}',
      [DOCUMENT_TYPES.POL]: 'Unggah SJ Muat {{sequence}}',
    },
  },
  bulk_upload_csv: {
    column_code: 'Kolom {{column_code}}',
    column_error: 'Error  {{ column_name }}',
    column_line: 'Baris {{ line }}',
    column_error_count: 'Terdapat {{count}} error',
  },
  cancel_adding_fee: 'Batal menambahkan biaya ini?',
  cancel_do: 'Batalkan {{do_number}}?',
  change_invoice_status: 'Ubah status Invoice {{invoice_number}} {{status}}?',
  // const NEW_INVOICE_TABLE_CHANGES = useFlag('TC-5218_NEW_INVOICE_TABLE');
  // delete change_invoice_status_old on delete of flag
  change_invoice_status_old: 'Ubah status Invoice {{invoice_number}}?',
  change_invoice_status_message: 'Ubah status menjadi {{status}}?',
  change_shipment_status: 'Ubah status {{do_number}}?',
  change_status_time: 'Ubah waktu status {{do_number}}?',
  changed_by: 'oleh <strong>{{actor}}</strong>. ',
  chip_document_status_tag: {
    [PROJECT_DOCUMENT_STATUSES.REJECTED]: 'Tidak Sesuai',
    [PROJECT_DOCUMENT_STATUSES.APPROVED]: 'Sesuai',
    [PROJECT_DOCUMENT_STATUSES.UNDER_VERIFICATION]: 'Sedang Diverifikasi',
  },
  choose_shipment_from_shipper: 'Pilih pengiriman dari {{shipper}}',
  confirm_revise_invoice: 'Konfirmasi Revisi Invoice ke-{{sequence}}',
  contact_kargo_to_change_status_of_fast_payment: 'Hubungi Kargo untuk mengubah status Pembayaran Cepat',
  create_customer_data_for_funding: 'Daftar dan tambahkan data customer untuk kebutuhan pendanaan operasional armada pengiriman Anda.',
  created_at: 'Dibuat {{date}}',
  custom_invoice_delete_fee_disabled: 'Untuk hapus biaya ini, silakan ubah dan hapus dari rincian tagihan invoice {{invoice}} terlebih dahulu',
  custom_invoice_edit_fee_warning: 'Ubahan detail biaya ini akan mengubah rincian tagihan invoice {{invoice}} secara otomatis',
  custom_invoice_total_fee_message: 'Total Biaya dihitung dari Nominal Biaya x {{unit}}',
  custom_shipment_total_fee_message: 'Total Biaya dihitung dari Harga Pengiriman x {{unit}}',
  customer_division: 'Divisi {{division}}',
  customer_restrict_create_do: 'Tidak bisa membuat pengiriman untuk {{name}}',
  customer_successfully_created: '{{name}} berhasil ditambahkan.',
  customer_successfully_updated: '{{name}} berhasil diubah.',
  customer_successfully_uploaded: '{{name}} berhasil diunggah.',
  date: 'Tanggal {{date}}',
  date_changed_on: 'Diubah pada tanggal {{date}}',
  date_range_invalid: 'Rentang tanggal {{field_start}} dan {{field_end}} tidak valid',
  delete_entity_warning: 'Apakah Anda ingin menghapus {{entity}}',
  delete_payment_from_invoice: 'Hapus {{payment_type}} dari {{shipment_number}}?',
  delete_payment_warning: 'Biaya ini akan dihapus dari pengiriman {{shipment_number}}',
  delete_shipment_from_invoice: 'Hapus {{shipment_number}} dari Invoice ini?',
  delete_this_bank_account: 'Hapus rekening bank ini?',
  delivery_approved: '{{percentage}}% Pengiriman Disetujui',
  detail_of: 'Detail {{object}}',
  detail_of_shipment: 'Detail {{shipment_number}}',
  do_approved_success: 'DO Successfully Approved',
  do_assigned_success: 'DO Successfully Assigned',
  do_location_change_driver_instruction: 'Segera telepon driver Anda untuk memberitahukan kontak PIC {{location}} serta perubahan lokasi bongkar ini.',
  document_deleted_successfully: 'Dokumen telah berhasil dihapus',
  document_uploaded_successfully: 'Dokumen telah berhasil diunggah',
  dof2_eligibility_message_map: {
    [DOF2_ELIGIBILITY_STATUS.SHIPMENT_NOT_COMPLETED]: 'Pengiriman belum selesai',
    [DOF2_ELIGIBILITY_STATUS.DO_IS_ALREADY_IN_ANOTHER_DOF2_REQUEST]: 'Pengiriman sudah terdaftar pada {{dof_2_number}}',
    [DOF2_ELIGIBILITY_STATUS.DO_IS_ALREADY_IN_ANOTHER_INVOICE]: 'Pengiriman sudah terdaftar pada {{do_invoice_number}}',
    [DOF2_ELIGIBILITY_STATUS.DOF_NOT_DISBURSED_YET]: 'DOF pencairan pertama belum selesai',
    [DOF2_ELIGIBILITY_STATUS.UNDEFINED_BACKEND_CODE]: 'UNDEFINED_BACKEND_CODE',
    [DOF2_ELIGIBILITY_STATUS.DOF_REQUEST_NOT_FOUND]: 'Pengiriman belum terdaftar pada DOF',
  },
  dof2_invoice_keyword_not_found: 'Tidak menemukan invoice {{keyword}} di Nexus for Transporters',
  dof2_request_review_successfully_submitted: 'Hasil review {{dof2_number}} berhasil dikirim ke {{applicant}}',
  dof2_request_successfully_disbursed: 'Detail Pencairan {{dof2_number}} berhasil dikirim ke {{applicant}}',
  dof2_request_successfully_submitted: 'Pengajuan {{dof2_number}} Berhasil ditambahkan.',
  dof2_third_disbursement_proceed: 'Pencairan ke-3 {{dof2Number}} sedang diproses',
  dof2_total_first_disbursement_formula: '{{firstDisbursementPercentage}}% Pengiriman - Kargo Fee - VAT',
  dof2_total_second_disbursement_formula: '{{secondDisbursementPercentage}}% Pengiriman + {{additionalFeePercentage}}% Additional Fee',
  dof2_total_third_disbursement_formula: '{{thirdInovicePercentage}}% Invoice + PPN {{pphSign}} PPH {{adjustmentSign}} Adjustment {{kargoFeeAdjustmentSign}} Kargo Fee Adjustment - Late Fee',
  dof_first_disbursement_summary: '{{percentage}} dari {{total}} Pengiriman',
  dof_request_review_successfully_submitted: 'Hasil review {{dof_number}} berhasil dikirim ke {{applicant}}',
  dof_request_successfully_disbursed: 'Detail Pencairan {{dof_number}} berhasil dikirim ke {{applicant}}',
  dof_request_successfully_submitted: 'Pengajuan {{dof_number}} Berhasil ditambahkan.',
  dof_third_disbursement_formula: '{{invoicePercentage}}% invoice + PPN - PPH + Adjustment {{adjustmentFeeSign}} Kargo Fee Adjustment - Late Fee',
  done_review_with_progress: '{{progress}}/{{total}} Selesai Review',
  driver_deactivation_confirmation: 'Nonaktifkan {{name}}?',
  driver_deactivation_warning_message: 'Apakah kamu yakin?',
  driver_successfully_created: '{{name}} berhasil ditambahkan.',
  driver_successfully_updated: '{{name}} berhasil diubah.',
  driver_successfully_uploaded: '{{name}} berhasil diunggah',
  email_revised_invoice: 'Email Revisi Invoice ke-{{sequence}}',
  file_error: {
    1: 'Perusahaan tidak ditemukan',
    9: 'Gudang tidak ditemukan',
    13: 'Lokasi alamat tidak valid',
    45: 'Anda sudah punya Biaya Lokasi dengan Tipe Biaya, Tipe Pengiriman, Tipe Truk, dan Subtipe truk yang sama',
    46: 'Anda sudah punya Biaya Lokasi dengan Tipe Biaya, Tipe Pengiriman, Tipe Truk, dan Subtipe truk yang sama',
    55: 'ID jenis kendaraan tidak valid',
    60: 'Alamat ({{column_name}}) tidak ditemukan',
    61: 'Alamat ({{column_name}}) berlokasi di luar Indonesia',
    62: 'Tanggal muat tidak valid',
    63: 'Tanggal bongkar tidak valid',
    64: 'Armada tidak ditemukan',
    65: 'Jenis barang tidak valid',
    66: 'Customer tidak ditemukan',
    67: 'Supir tidak ditemukan',
    96: 'BL not found',
    97: 'Invalid container status',
    98: 'Invalid container type',
    99: 'Warehouse not found',
    474: 'Header file CSV tidak valid',
    475: 'Kolom {{column_name}} tidak valid',
    476: 'File CSV tidak valid',
    477: 'Header file CSV kosong',
    478: 'Kesalahan yang tidak diketahui',
    479: 'Urutan pelolosan yang tidak valid ditemukan dalam satu baris',
    480: 'Panjang kolom tidak valid untuk satu baris',
    481: 'Baris memiliki kutipan yang menyimpang',
    483: 'Nomor Lisensi Kendaraan sudah terdaftar di perusahaan',
    2501: 'Location ID tidak ditemukan',
    2502: 'Fee type tidak valid',
    2503: 'Shipment type tidak valid',
    2504: 'Max amount tidak valid',
    2505: 'Reimbursement percentage tidak valid',
    2506: 'Reimbursement requirement tidak valid',
    2507: 'Truck type/subtype tidak ditemukan',
    2508: 'Reimbursement type tidak valid',
  },
  fill_disbursement_details_dof2_request: 'Isi Detail Pencairan ke-3 {{request_name}}',
  fill_disbursement_details_dof_request: 'Mengisi Detail Pencairan {{request_name}}',
  fill_disbursement_details_podf_request: 'Mengisi Detail Pencairan {{request_name}}',
  fill_first_disbursement_details_podf_request: 'Mengisi Detail Pencairan Pertama {{request_name}}',
  fill_in_the_truck_details: 'Isi Detail Truk',
  first_disbursement_calculation: '{{shipment_fee_percentage}}% dari Pengiriman - Kargo Fee - VAT',
  form_dialog: {
    fill_in_this_CSV_file_to_upload_a_entity: 'Isi file CSV ini untuk menunggah {{entity}}.',
    upload_entity_from_csv_file: 'Unggah {{entity}} dari file CSV',
  },
  form_error_message: {
    choose_category: 'Pilih {{field}}',
    document_is_required: 'This document has to be uploaded.',
    invalid: '{{field}} tidak valid.',
    invalid_format: 'Masukkan {{field}} yang valid',
    mandatory: 'Anda wajib melengkapi {{field}}',
    max_length: 'Maksimum panjang {{field}} adalah {{max}}',
    max_value: '{{field}} harus kurang dari {{max}}',
    min_length: 'Minimum panjang {{field}} adalah {{min}}',
    min_value: 'Minimum {{field}} adalah {{min}}',
    need_to_be_filled: '{{field}} harus diisi.',
    percentage_length: 'Total Persentase harus 100%',
    required: 'Masukkan {{field}}',
    strong_password_rule: '{{field}} setidaknya harus memiliki satu karakter huruf besar, huruf kecil, angka, dan karakter spesial',
  },
  general_activation_success_message: '{{name}} berhasil aktifkan',
  general_add: 'Tambah {{name}}',
  general_againts: 'Terhadap {{entity}}',
  general_change_details: 'Ubah Detail {{name}}',
  general_choose: 'Pilih {{name}}',
  general_confirmation: 'Konfirmasi {{name}}',
  general_create: 'Buat {{name}}',
  general_days: '{{days}} hari',
  general_deactivation_confirmation: 'Nonaktifkan {{name}}?',
  general_deactivation_success_message: '{{name}} berhasil dinonaktifkan',
  general_deactivation_warning_message: 'Anda yakin ingin menonaktifkan {{name}}',
  general_edit: 'Ubah {{name}}',
  general_edit_confirmation: 'Edit {{name}}\'s Shipper Status',
  general_edit_detail: 'Ubah Detail {{name}}',
  general_new_item: '{{name}} Baru',
  general_removal_confirmation: 'Hapus {{name}}?',
  general_removal_success_message: '{{name}} berhasil dihapus',
  general_removal_warning_message: 'Anda yakin ingin menghapus {{name}}?',
  general_remove: 'Hapus {{name}}',
  general_request: 'Permintaan {{name}}',
  general_save: 'Save {{name}}',
  general_search: 'Cari {{name}}',
  general_search_placeholder: 'Cari {{fields}}',
  general_see: 'Lihat {{object}}',
  general_shipper: '{{name}} Enterprise Shipper',
  general_submit_success_message: '{{name}} berhasil terkirim.',
  general_summary: 'Ringkasan {{name}}',
  general_upload_success_message: '{{name}} berhasil diunggah',
  generic_failed_uploaded: '{{name}} gagal diunggah',
  generic_successfully_uploaded: '{{name}} berhasil diunggah',
  generic_text_input_placeholder: 'Ketik {{field}}',
  generic_date: 'Tanggal {{name}}',
  generic_date_column: 'Tgl. {{name}}',
  hours: '{{field}} Jam',
  initial_kargo_fee: 'Kargo Fee Awal ({{percentage}}%)',
  initial_vat_kargo_fee: 'VAT Awal ({{percentage}}%)',
  invoice_customer_code_delete_question: 'Hapus kode customer {{customerName}}?',
  invoice_date_is: 'Tanggal Invoice {{date}}',
  invoice_format_display_translation: {
    [INVOICE_FORMAT_DEFAULT['/']]: '/',
    [INVOICE_FORMAT_DEFAULT['-']]: '-',
    [INVOICE_FORMAT_DEFAULT[':']]: ':',
    [INVOICE_FORMAT_DEFAULT.MONTH_CODE]: 'Bulan ({{format}})',
    [INVOICE_FORMAT_DEFAULT.YEAR_CODE]: 'Tahun ({{format}})',
    [INVOICE_FORMAT_DEFAULT.CUSTOMER_CODE]: 'Kode Customer',
    [INVOICE_FORMAT_DEFAULT.INVOICE_CODE]: 'Kode Invoice ({{format}} Angka)',
    [INVOICE_FORMAT_DEFAULT.COMPANY_CODE]: 'Kode Perusahaan',
    [MONTH_INVOICE_FORMAT.NUMBER]: 'Angka',
    [MONTH_INVOICE_FORMAT.ROMAN]: 'Romawi',
    [MONTH_INVOICE_FORMAT.LETTER]: 'Huruf',
    [YEAR_INVOICE_FORMAT.COMPLETE]: 'Lengkap',
    [YEAR_INVOICE_FORMAT.LAST_TWO_NUMBER]: 'Dua angka terakhir',
  },
  invoice_percentage: '{{percentage}}% Invoice',
  invoice_sent_successfully: 'Invoice {{invoice_number}} berhasil terkirim.',
  invoice_subject_template: 'Proforma Invoice No. {{invoice_number}} dari {{publisher}}',
  invoice_successfully_added: '{{invoice_number}} Berhasil Ditambahkan',
  invoice_value: '{{value}} Terhadap nilai Invoice',
  invoicing_without_tax_applied: 'Lanjut meng-Invoice customer ini tanpa detail pajak?',
  invoicing_without_tax_applied_desc: 'Invoice ini belum termasuk pemotongan pajak karena Anda belum menambahkan detail pembayaran dan pajak untuk {{name}}.Apakah Anda ingin lanjut?',
  km: '{{km}} Km',
  KTP_uploaded_successfully: 'KTP berhasil diunggah',
  last_update_at: 'Update terakhir {{datetime}}',
  leave_page_warning_msg: 'Setiap biaya dan detail di proforma invoice ini akan hilang.',
  leave_this_page_title: 'Tinggalkan halaman ini?',
  load_at: 'Muat {{date}}',
  load_time: 'Muat {{datetime}}',
  location_count: '{{count}} Locations',
  lost_fee_warning_msg: 'Biaya ini akan hilang.',
  mark_as_payment_received: 'Menandakan Penerimaan Pembayaran {{title}}',
  mileage_filename: 'Rincian jarak tempuh total - {{plateNumber}} - {{from}} - {{to}}',
  multi_shipment_activity_map: {
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Tiba di lokasi bongkar {{shipmentPoint}}',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'Sampai di lokasi muat {{shipmentPoint}}',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Selesai proses muat {{shipmentPoint}}',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Selesai proses bongkar {{shipmentPoint}}',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'Dalam perjalanan ke lokasi bongkar {{shipmentPoint}}',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'Dalam perjalanan ke lokasi muat {{shipmentPoint}}',
    [SHIPMENT_STATUSES.START_LOADING]: 'Mulai proses muat {{shipmentPoint}}',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Mulai proses bongkar {{shipmentPoint}}',
  },
  not_listed: 'Tidak Terdaftar',
  not_registered: 'Belum Terdaftar',
  NPWP_uploaded_successfully: 'NPWP berhasil diunggah',
  number_of_seconds: '{{seconds}} detik',
  pagination_indicator: 'Halaman {{page}} dari {{total}}',
  payment_type_ppn: 'PPN {{ppn_percentage}}%',
  percentage_additional_fee: '{{percentage}}% Biaya Tambahan',
  percentage_additional_fees: '{{percentage}}% Additional Fee',
  percentage_approved_shipment: '{{percentage}}% Pengiriman Disetujui',
  percentage_from_kargo_fee: '{{percentage}}% dari Kargo Fee',
  percentage_kargo_fee: '{{percentage}}% Kargo Fee',
  percentage_of_x: '{{percentage}}% Terhadap {{x}}',
  percentage_vat_kargo_fee: '{{percentage}}% VAT',
  pic_sequence: 'PIC {{sequence}}',
  pod_from_source: 'POD dari {{source}}',
  podf_invoice_keyword_not_found: 'Tidak menemukan invoice {{keyword}} di Nexus for Transporters',
  podf_request_review_successfully_submitted: 'Hasil review {{podf_number}} berhasil dikirim ke {{applicant}}',
  podf_request_successfully_disbursed: 'Detail Pencairan {{podf_number}} berhasil dikirim ke {{applicant}}',
  podf_request_successfully_submitted: 'Pengajuan {{podf_number}} Berhasil ditambahkan.',
  podf_second_disbursement_formula: '{{inovicePercentage}}% Invoice {{adjustmentSign}} Adjustment {{kargoFeeAdjustmentSign}} Kargo Fee Adjustment - Late Fee',
  podf_second_disbursement_proceed: 'Pencairan ke-2 {{podfNumber}} sedang diproses',
  pph15_percentage: 'PPH 15 {{percentage}}%',
  pph21_percentage: 'PPH 21 {{percentage}}%',
  pph23_percentage: 'PPH 23 {{percentage}}%',
  pph_percentage: {
    [WHT_SELECTION_TYPE.WHT15]: 'PPH 15 {{percentage}}%',
    [WHT_SELECTION_TYPE.WHT21]: 'PPH 21 {{percentage}}%',
    [WHT_SELECTION_TYPE.WHT23]: 'PPH 23 {{percentage}}%',
  },
  preview_invoice: 'Preview Invoice {{invoiceNumber}}',
  pricing_sceheme: {
    cycle_month: 'Bulan ke {{currentCycle}} dari {{totalCycle}}',
    do_details: '{{createdDo}} DO dibuat dari {{totalDo}} Total Kuota DO',
    remaining_quota: `{{remainingQuota}} DO Tersisa`,
  },
  rejection_confirmation: 'Konfirmasi Penolakan {{doNumber}}?',
  reimbursement_type_map: {
    [REIMBURSEMENT_TYPE.KG]: '{{shipmentType}}-Kg',
    [REIMBURSEMENT_TYPE.OTHER]: 'Lainnya',
    [REIMBURSEMENT_TYPE.ROUTE]: '{{shipmentType}}',
    [REIMBURSEMENT_TYPE.UNIT]: '{{shipmentType}}-Unit',
  },
  resend_otp_countdown_message: 'Kirim ulang kode dalam {{countdown}}',
  review_entity: 'Review {{entity}}',
  review_request_submission: 'Review Pengajuan {{request_name}}',
  revise_invoice: 'Revisi Invoice ke-{{sequence}}',
  shipment_point_multi: 'Poin {{pointName}}',
  shipment_point_multi_map: {
    [SHIPMENT_POINT_TYPES.PICKUP]: 'Muat {{pointName}}',
    [SHIPMENT_POINT_TYPES.DROPOFF]: 'Bongkar {{pointName}}',
    [SHIPMENT_POINT_TYPES.PICKUP_DROPOFF]: 'Bongkar + Muat {{pointName}}',
  },
  shipment_point_schedule: 'Jadwal {{pointType}}: {{date}} ',
  shipment_status_location_changed: 'Lokasi {{point}} diubah dari <strong>{{fromLocation}}</strong> menjadi <strong>{{toLocation}}</strong> oleh {{actor}}. ',
  shipment_status_driver_reassignment: '{{updatedItems}} diubah oleh {{actor}}',
  shipment_status_inactive_reason: 'Alasan: {{inactiveReason}}',
  shipment_status_location_reason: 'Alasan: {{changeReason}} {{changeNotes}}',
  shipment_status_manual_update_log: 'Status diubah menjadi <strong>{{updated_status}}</strong> oleh {{actor}} {{viaQrCode}}',
  shipment_status_point_changed: '{{point}} diubah dari <strong>{{fromLocation}}</strong> menjadi <strong>{{toLocation}}</strong>',
  shipment_status_revert_note: 'Status otomatis dikembalikan ke Menuju Lokasi {{point}}.',
  shipment_status_tracker_map: {
    [SHIPMENT_STATUSES.ACTIVATE_VEHICLE_ALERT]: 'Kondisi armada {{licensePlate}} diubah menjadi <strong>Sedang Bermasalah</strong> oleh {{actor}}',
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Tiba di lokasi bongkar',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'Sampai di lokasi muat',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Truk & supir dipilih',
    [SHIPMENT_STATUSES.BUMP_DOWN_ACTIVE_SHIPMENT]: 'Status diubah menjadi "Dalam Antrean" oleh sistem'
      + ' karena pengiriman lain ({{doNumber}}) diberikan kepada supir dengan Jadwal Muat {{loadingDate}}',
    [SHIPMENT_STATUSES.BUMP_UP_QUEUED_SHIPMENT]: 'Status diubah kembali menjadi "Aktif" oleh sistem'
      + ' karena pengiriman lain ({{doNumber}}) telah selesai dan tidak ada lagi pengiriman dengan antrean lebih awal',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Dibatalkan Customer, alasan: {{inactive_reason}}',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Dibatalkan Vendor',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Waktu Konfirmasi Habis',
    [SHIPMENT_STATUSES.DEACTIVATE_VEHICLE_ALERT]: 'Kondisi armada {{licensePlate}} diubah menjadi <strong>Normal</strong> kembali oleh {{actor}}',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Selesai muat',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Selesai bongkar',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'Dalam perjalanan ke lokasi muat',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'Dalam perjalanan ke lokasi bongkar',
    [SHIPMENT_STATUSES.PLANNED]: 'Truk Belum Dipilih',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Customer Mengalihkan Penugasan',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Tidak Terpenuhi',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Pengiriman selesai',
    [SHIPMENT_STATUSES.START_LOADING]: 'Mulai muat',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Mulai bongkar',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Tidak Terpenuhi',
    [SHIPMENT_STATUSES.UPCOMING]: 'Pengiriman dibuat oleh {{actor}}',
    change_driver: 'No.Polisi dan Supir diubah oleh Yoghi',
  },
  shipment_template_save_as_type_map: {
    [SHIPMENT_TEMPLATE_SAVE_TYPE.CREATE]: 'Simpan sebagai Template Baru',
    [SHIPMENT_TEMPLATE_SAVE_TYPE.UPDATE]: 'Perbarui Template saat ini',
  },
  shipping_offer: 'Tawaran Pengiriman {{doNumber}}',
  SPPKP_uploaded_successfully: 'SPPKP berhasil diunggah',
  slot_number: 'Slot {{value}}',
  submit_truck_details: 'Kirim Detail Truk',
  submitted_at: 'Dikirim pada {{timestamp}}',
  submitted_on: 'Diajukan {{date}}',
  template: 'Template {{name}}',
  template_name: 'Template: {{templateName}}',
  truck_availability_file_name: 'Ketersediaan Armada - {{truckType}} - {{fromDate}} - {{toDate}}',
  total_cost_is: 'Total Biaya {{cost}}',
  total_first_disbursement_formula_with_percentage: '{{percentage}} dari Pencairan Pertama - Kargo Fee - VAT',
  total_second_disbursement_formula_with_percentage: '{{percentage}} Pengiriman - Pencairan Pertama - Additional Fee',
  truck_and_driver_details: 'Detail Truk Dan Supir',
  truck_deactivation_warning_message: 'Anda yakin ingin menonaktifkan {{name}}',
  update_additional_fee: 'Ubah {{payment_type}}',
  update_additional_fee_for_shipment: 'Ubah Biaya Lain untuk {{shipment_number}}',
  updated_at: 'Diperbaharui {{updatedAt}}',
  upload_pod_for_shipment: 'Unggah POD untuk {{shipment_number}}',
  upload_receipt_with_maximum_size: 'Unggah kwitansi dengan ukuran maksimal {{max_size}}.',
  upload_the_KTP_with_a_maximum_size: 'Unggah KTP dengan ukuran maksimal {{size}}.',
  upload_the_NPWP_with_a_maximum_size: 'Unggah NPWP dengan ukuran maksimal {{size}}.',
  upload_the_SPPKP_with_a_maximum_size: 'Unggah SPPKP dengan ukuran maksimal {{size}}.',
  uploaded_file_medium_subtitle: 'Diunggah pada {{datetime}} dari {{source}}',
  uploaded_file_short_subtitle: 'Diunggah pada {{datetime}}',
  user_deactivation_confirmation: 'Nonaktifkan {{name}}?',
  user_deactivation_success_message: '{{name}} berhasil dinonaktifkan',
  user_deactivation_warning_message: '{{name}} tidak akan dapat mengakses Nexus for Transporters, namun informasinya akan tetap disimpan. Anda tidak dapat mengaktifkannya kembali.',
  user_successfully_created: '{{name}} berhasil ditambahkan.',
  user_successfully_updated: '{{name}} berhasil diubah.',
  utilization_tab: {
    warehouse_label: 'Lokasi ke-{{number}}',
    utilization_details_filename: 'Rincian Utilisasi - {{licensePlate}} - {{date}}',
    utilization_search_result_filename: 'Utilisasi - {{date}}',
  },
  vehicle_alert_log_reason: 'Keterangan: {{reason}}',
  vehicle_alert_successfully_activated: '{{name}} peringatan kendaraan berhasil diaktifkan.',
  vehicle_alert_successfully_deactivated: '{{name}} peringatan kendaraan berhasil dinonaktifkan.',
  vehicle_successfully_created: '{{license_plate}} berhasil ditambahkan.',
  vehicle_count: '{{count}} Armada',
  vehicle_successfully_updated: '{{license_plate}} berhasil diubah.',
  vehicle_successfully_uploaded: '{{license_plate}} berhasil diunggah',
  warehouse_successfully_created: '{{name}} berhasil ditambahkan.',
  warehouse_successfully_updated: '{{name}} berhasil diubah.',
  warehouse_successfully_uploaded: '{{name}} berhasil diunggah',
  x_confirmation_warning: 'Apakah Anda ingin konfirmasi {{x}} Pengiriman?',
  x_container_selected: '{{total_selected}} container terpilih',
  x_DOF: '{{x}} DOF',
  x_invoice: '{{x}} Invoice',
  x_invoices: '{{x}} Invoice',
  x_out_of_y_shipments: '{{x}} dari {{y}} Pengiriman',
  x_shipment_from_y_dof_selected: '{{x}} Pengiriman dari {{y}} DOF Terpilih',
  x_shipment_selected: '{{total_selected}} pengiriman dari {{shipper}} terpilih',
  x_shipment_selected_short: '{{x}} Pengiriman Terpilih',
  x_shipments_selected_mobile: 'Lanjut ({{x}} DO)',
  x_shipments: '{{x}} Pengiriman',
  x_shipments_cant_be_disbursed_yet: '{{x}} pengiriman belum dapat dicairkan',
  x_thing_for_y: '{{x}} untuk {{y}}',
  input_x: 'Tulis {{x}}',
  blocked_button_min_limit_amount: 'Jumlah Tagihan Minimum {{amount}}',
};
