import React, { lazy, useContext } from 'react';
import {
  Redirect,
  Switch,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PrivateRoute } from '~/Components/Route';
import { NftModulesContext } from '~/Contexts/NftModulesProvider';
import { AVAILABLE_NFT_MODULES } from '../constants';

const EarningsReportPage = lazy(() => import(/* webpackChunkName: "FinanceList" */
  '~/Modules/Finance/Pages/EarningsReportPage/EarningsReportPage'
));
const IncentivesPage = lazy(() => import(/* webpackChunkName: "Incentives" */
  '~/Modules/Finance/Pages/IncentivesPage/IncentivesPage'
));

export default function FinanceRoutes() {
  const { t } = useTranslation();
  const {
    [AVAILABLE_NFT_MODULES.BTMS_INTEGRATION]: showIncentiveTab,
    [AVAILABLE_NFT_MODULES.EARNING_REPORT]: showEarningsReport,
  } = useContext(NftModulesContext);

  const financePageNavigation = [
    showEarningsReport && {
      path: '/finance/reports',
      title: t('common:income_statement'),
    },
    showIncentiveTab && {
      path: '/finance/incentives',
      title: t('common:incentives'),
    },
  ].filter(Boolean);

  const financeTabConditionalRouting = () => {
    if (showEarningsReport && showIncentiveTab) {
      return ([
        <PrivateRoute
          exact={true}
          path="/finance/reports"
          tabNavigation={financePageNavigation}
          title={t('common:finance_report')}
        >
          <EarningsReportPage />
        </PrivateRoute>,
        <PrivateRoute
          exact={true}
          path="/finance/incentives"
          tabNavigation={financePageNavigation}
          title={t('common:finance_report')}
        >
          <IncentivesPage />
        </PrivateRoute>,
        <Redirect
          exact={true}
          from="/finance"
          to="/finance/reports"
        />,
      ]);
    }
    if (!showEarningsReport && showIncentiveTab) {
      return (
        <PrivateRoute
          exact={true}
          path="/finance"
          title={t('common:finance_report')}
        >
          <IncentivesPage />
        </PrivateRoute>
      );
    }
    if (showEarningsReport && !showIncentiveTab) {
      return (
        <PrivateRoute
          exact={true}
          path="/finance"
          title={t('common:finance_report')}
        >
          <EarningsReportPage />
        </PrivateRoute>
      );
    }
    return null;
  };

  return (
    <Switch>
      {financeTabConditionalRouting()}
    </Switch>
  );
}
