import { Grid } from '@kargotech/tms-ui/components';
import { useFlag } from '@unleash/proxy-client-react';
import FEATURE_FLAG from '~/Configurations/constants/featureFlag';

export default function useResponsiveUtils() {
  const ENABLE_SUP_557_THE_LORRY_NFT_WHITE_LABEL = useFlag(FEATURE_FLAG.SUP_557_THE_LORRY_NFT_WHITE_LABEL);
  const screens = Grid.useBreakpoint();

  const isMobileScreen = ENABLE_SUP_557_THE_LORRY_NFT_WHITE_LABEL && !screens.md;

  return {
    isMobileScreen,
  };
}
