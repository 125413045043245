/* eslint-disable no-nested-ternary */
import { FullScreenDrawer, Space, Typography } from '@kargotech/tms-ui/components';
import { get } from 'lodash-es';
import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const ScrollableDiv = styled.div`
  max-height: 900px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FullScreenModal = styled(FullScreenDrawer.Paper)`
  height: 100vh;
  margin: 0 !important;
  max-width: 100vw;
  padding: 16px 0;

  h3.ant-typography {
    box-shadow: 0px 1px 0px rgba(4, 7, 12, 0.1);
    display: flex;
    margin: 0 !important;
    padding: 0 56px 16px 56px;
  }
`;

const DialogContainer = styled(Space)`
  margin: 0 0 32px 0;
  padding: 0 56px 0 56px;

  div.ant-space-item {
    margin: 0 !important;
  }

`;

const ContentContainer = styled.ol`
  margin: 0;
  padding: 0;
`;

const Title = styled(Typography.Text)`
  font-weight: bold;
`;

const Description = styled.li`
  list-style-type: ${props => (props.noBullet ? 'none' : props.decimalBullet ? 'decimal' : 'disc')};
  margin-left: ${props => (props.noBullet ? '0' : '24px')};
`;

const TncDialog = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(true);
  const tncBids = t('bidding:bid_tnc', { returnObjects: true });
  const tncContentList = Object.values(get(tncBids, 'content_list'));

  const handleClose = () => {
    setOpenModal(!openModal);
    history.push('/bidding/available');
  };

  return (
    <FullScreenDrawer closable={true} destroyOnClose={true} onClose={handleClose} placement="top" visible={openModal}>
      <FullScreenModal
        title={get(tncBids, 'dialog_title')}
      >
        <ScrollableDiv>
          {tncContentList.map((content, indexContent) => (
            <DialogContainer key={content.title} direction="vertical">
              <Title>{content.title}</Title>
              <ContentContainer>
                {content.description.map((desc, indexDesc) => (
                  <Description
                    decimalBullet={indexContent === 4}
                    noBullet={(indexDesc === 0 && indexContent === 0)}
                    strong={true}
                  >
                    <Trans i18nKey={desc} />
                  </Description>
                ))}
              </ContentContainer>
            </DialogContainer>
          ))}
        </ScrollableDiv>
      </FullScreenModal>
    </FullScreenDrawer>
  );
};

export default TncDialog;
