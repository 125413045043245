import { useEffect, useState } from 'react';
import { Grid } from '@kargotech/tms-ui/components';
import usePWAInstall from './usePWAInstall';
import useCookies from './useCookie';
import { INSTALL_BANNER_STORAGE_KEY } from '~/Configurations/constants';

function usePWAPrompt() {
  const [displayBanner, setDisplayBanner] = useState(false);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { getCookie, setCookie } = useCookies();
  const { install, isInstalled } = usePWAInstall();

  const isEligibleToInstall = () => {
    const installBannerState = getCookie(INSTALL_BANNER_STORAGE_KEY);
    if (screens.md) return false; // download PWA only eligible for mobile
    if (installBannerState) return false;
    return !isInstalled || displayBanner;
  };

  const handleCloseBanner = () => {
    setCookie(INSTALL_BANNER_STORAGE_KEY, true, { expires: 7 }); // Set the cookie to hide the banner for 7 days
    setDisplayBanner(false);
  };

  const handleInstallClick = () => {
    install(); // Call the install function from the hook
  };

  useEffect(() => {
    setDisplayBanner(!isInstalled);
  }, [isInstalled]);

  return {
    handleInstallClick,
    canInstall: isEligibleToInstall(),
    handleClose: handleCloseBanner,
  };
}

export default usePWAPrompt;
