import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/messaging';
import 'firebase/remote-config';

const config = {
  apiKey: window.FIREBASE_API_KEY,
  projectId: window.FIREBASE_PROJECT_ID,
  appId: window.FIREBASE_APP_ID,
  messagingSenderId: window.FIREBASE_MESSAGING_SENDER_ID,
};

const shouldDisableFirebase = window.FORCE_DISABLE_FIREBASE === 'true' || window.location.hostname.includes('china.');

if (shouldDisableFirebase) {
  // eslint-disable-next-line no-console
  console.warn('Firebase is disabled');
}
const firebaseApp = shouldDisableFirebase ? null : firebase.initializeApp(config);

let messagingInstance = null;
if (!shouldDisableFirebase && firebase.messaging.isSupported()) {
  messagingInstance = firebaseApp.messaging();
}
const messaging = messagingInstance;

let remoteConfigInstance = null;
if (!shouldDisableFirebase) {
  remoteConfigInstance = firebase.remoteConfig(firebaseApp);
  remoteConfigInstance.settings.minimumFetchIntervalMillis = window.ENVIRONMENT === 'PRD' ? (30 * 60 * 1000) : 0;
}

const remoteConfig = remoteConfigInstance;

export {
  config,
  firebaseApp,
  messaging,
  remoteConfig,
  shouldDisableFirebase,
};
