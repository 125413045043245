import * as colorPresets from './colors';

export default {
  colors: colorPresets.default,
  fonts: ['Roboto', 'sans-serif'],
  fontSizes: {
    xsmall: '0.8rem',
    small: '0.9rem',
    medium: '1rem',
    large: '1.5rem',
    xlarge: '2rem',
  },
  layout: {
    mainLayoutSection: {
      padding: '0 4rem',
      responsivePadding: '0 1rem',
    },
  },
  breakpoints: {
    // from antd breakpoints
    xs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
  },
};
