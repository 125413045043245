import React from 'react';

export default function GlobalStyle() {
  return (
    <style>
      {`
        @media (max-width: 768px) {
          .ant-modal-body {
            padding: 16px 12px;
          }
        }
      `}
    </style>
  );
}
