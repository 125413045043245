import React, { createContext, useCallback, useMemo } from 'react';
import platformConfig from '../Configurations/platform';
import i18n from '../Services/i18n';

const { lang: DEFAULT_LANG } = platformConfig;

if (platformConfig.lang) {
  i18n.changeLanguage(DEFAULT_LANG);
}

export const AppContext = createContext(platformConfig);

function AppProvider({ children }) {
  const changeLanguage = useCallback(lang => {
    if (!lang) {
      return;
    }

    // Adding a flag to only compile translation files
    // for app with multi-language supports.
    // Currently only B-TMS support this.
    if (process.env.APP_HAS_MULTI_LOCALES) {
      if (lang !== DEFAULT_LANG) {
        import(`../Translations/${lang}`).then(({ default: newDictionary }) => {
          Object.keys(newDictionary || {}).forEach(namespace => {
            i18n.addResourceBundle(lang, namespace, newDictionary[namespace], true, true);
          });
          i18n.changeLanguage(lang);
        });
      } else {
        i18n.changeLanguage(lang);
      }
    }
  }, []);

  const availableContext = useMemo(() => ({
    ...platformConfig,
    changeLanguage,
  }), [changeLanguage]);

  return (
    <AppContext.Provider value={availableContext}>
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
