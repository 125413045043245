import Cookies from 'js-cookie';

const useCookies = () => {
  const getCookie = key => {
    const saved = Cookies.get(key);
    if (saved) {
      try {
        return JSON.parse(saved);
      } catch (err) {
        return saved;
      }
    }
    return null;
  };

  const setCookie = (
    key,
    value,
    options = { expires: 1 },
  ) => {
    let stringified;

    if (typeof value === 'string') {
      stringified = value;
    } else {
      stringified = JSON.stringify(value);
    }

    Cookies.set(key, stringified, options);
  };

  const removeCookie = key => Cookies.remove(key);

  const getAllCookies = () => Cookies.get();

  return {
    getCookie,
    setCookie,
    removeCookie,
    getAllCookies,
  };
};

export default useCookies;
