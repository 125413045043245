import { useEffect, useState } from 'react';

const usePWAInstall = () => {
  const [beforeInstallPromptEvent, setBeforeInstallPromptEvent] = useState();
  const [isInstalled, setIsInstalled] = useState(false);

  const install = async () => {
    if (!beforeInstallPromptEvent) return;

    beforeInstallPromptEvent.prompt();

    const { outcome } = await beforeInstallPromptEvent.userChoice;

    if (outcome === 'accepted') {
      setIsInstalled(true);
    }
  };

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      setBeforeInstallPromptEvent(e);
    };

    window.addEventListener('beforeinstallprompt', handler);

    if (!beforeInstallPromptEvent) {
      setIsInstalled(true);
    } else {
      setIsInstalled(false);
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, [beforeInstallPromptEvent]);

  useEffect(() => {
    const handler = () => {
      setIsInstalled(true);
      // For apply setIsInstalled(true) when install and popup new pwa
      window.location.reload();
    };

    window.addEventListener('appinstalled', handler);

    return () => {
      window.removeEventListener('appinstalled', handler);
    };
  }, []);

  return { isInstalled, install };
};

export default usePWAInstall;
