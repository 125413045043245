import gql from 'graphql-tag';

export default gql`
  query JOB_DETAILS($jobKsuid: String!) {
    jobDetails(jobKsuid: $jobKsuid) {
    # jenis truk
    vehicleType {
      id
      name
      type
      subType
    }
    
    # tonase
    loadsCapacity {
      capacity
      unit
    }
    
    # SKU of NFS DO
    companyProducts {
      group {
        ksuid
        name
      }
      ksuid
      name
      productId
      quantity
      quantityType {
        ksuid
        name
      }
    }
    pricingRateType # PER_KG, PER_CBM, PER_UNIT, PER_ROUTE
    
    # catatan Transporter used by NFS
    description
    # catatan Transporter used by BTMS
    additionalNotes {
      additionalTransporterNote
      additionalShipperNote
    }
    
    shipper {
      name
    }
    
    # to know group load detail
    scope {
      isGroupLoad
      isBtmsJob
      isStmsJob
    }

    availableSlot
    
    # muat & bongkar
    shipmentPoints {
      ksuid
      warehouseKsuid
      googlePlaceId
      gpsLatitude
      gpsLongitude
      sequence
      status
      type
      name
      expectedDate {
        datetime
      }
      location {
        address
        city
      }
    }
    
    shipment {
      ksuid
      customer {
        name
      }
    }
    
    # tawaran
    budget {
      amount
      currencyCode
    }
    incentivePercentage
    minMarginBidPrice
    maxMarginBidPrice
    bidAmountLimit
    productType
    }
  }
`;
