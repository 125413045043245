import gql from 'graphql-tag';

export default ({ QUOTA_LIMIT_AND_DEACTIVATION = false } = {}) => gql`
query ONGOING_SUBSCRIPTION_DETAIL(
  $companyKsuid: String!
) {
  ongoingSubscriptionDetail(
    companyKsuid: $companyKsuid
  ) {
    ksuid
    startDatetime
    endDatetime
    endGracePeriodAt        # use this to determine grace period end datetime
    timezone
    numberOfCommittedDo
    cyclesCount
    upgradeQuota
    type
    status
    activeCycle {
      cycleNumber
      startDatetime
      endDatetime
      numberOfCommittedDo
      upgradeQuota
      freeQuota
      createdDoCount
      incrementalDoCount
      totalQuota
      status
      isActive
    }
    ${QUOTA_LIMIT_AND_DEACTIVATION ? `
    lastActiveCycle {
      cycleNumber
      startDatetime
      endDatetime
      numberOfCommittedDo
      upgradeQuota
      freeQuota
      createdDoCount
      incrementalDoCount
      totalQuota
      status
      isActive
    }
    ` : ``}
  }
}`;
