/* eslint-disable max-len */

import {
  DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS,
  DOCUMENT_UPLOAD_TERMS,
  PBI_PARTNERSHIP_STATUSES,
  PBI_COMPANY_REQUIRED_DOCUMENTS,
  PBI_COMPANY_OPTIONAL_DOCUMENTS,
  PBI_PERSONAL_REQUIRED_DOCUMENTS,
  PBI_VEHICLE_STATUS,
  VEHICLE_DOCUMENT_TYPE,
  PBI_INFO_BANNER_REJECTED_STATUS,
  PBI_DOCUMENT_REJECTION_REASON,
  PBI_DRIVER_VERIFICATION_STATUSES,
  PBI_DRIVER_DOCUMENTS,
  PBI_PERSONAL_OPTIONAL_DOCUMENTS,
  TLO_COMPANY_DOCUMENTS,
} from '~/Configurations/constants';

export default {
  add_contact: 'Tambah Kontak',
  armada_requirement_message: 'Daftarkan dan pilih minimal satu unit untuk melanjutkan proses pendaftaran',
  armada_section_title: 'Tambah Unit Armada',
  armada_section_title_v2: 'Daftar Unit',
  banner_status_info_map: {
    [PBI_PARTNERSHIP_STATUSES.BLOCKED]: 'Anda tidak dapat menerima DO dari PBI hingga waktu yang tidak ditentukan. Silakan hubungi Kargo untuk informasi lebih lanjut.',
    [PBI_PARTNERSHIP_STATUSES.NOT_REGISTERED]: 'Daftar untuk mendapatkan akses ke Muatan Rutin Kargo.',
    [PBI_PARTNERSHIP_STATUSES.PENDING]: 'Kami akan kabari status kemitraan Anda selambatnya dalam 2 jam kerja.',
    [PBI_PARTNERSHIP_STATUSES.REJECTED]: 'Pastikan dokumen lengkap dan lakukan pendaftaran ulang untuk mendapatkan akses ke Muatan Rutin Kargo.',
    [PBI_PARTNERSHIP_STATUSES.SUSPENDED]: 'Anda tidak dapat menerima DO dari PBI selama {{days}}x24 jam karena akun mitra Panthera Biru Indonesia Anda telah terkena suspend.',
    [PBI_PARTNERSHIP_STATUSES.TRUSTED]: 'Anda mendapatkan akses ke Muatan Rutin Kargo dan tidak perlu konfirmasi ulang order.',
    [PBI_PARTNERSHIP_STATUSES.VERIFIED]: 'Anda telah mendapatkan akses ke Muatan Rutin Kargo.',
  },
  banner_rejected_info_map: {
    [PBI_INFO_BANNER_REJECTED_STATUS.DOCS_REUPLOAD_NEEDED]: 'Perlu mengunggah kembali dokumen.',
  },
  bidding_blocked: 'Anda telah diblokir dan tidak dapat menawar/mengambil muatan',
  bidding_confirmation: {
    confirm_bid_success: '{{jobNumber}} berhasil diambil',
    confirm_modal_content: 'Membatalkan pengiriman ini setelah konfirmasi akan mempengaruhi peluang Anda untuk mendapatkan pengiriman selanjutnya.',
    confirm_modal_title: 'Ambil Pengiriman {{jobNumber}}?',
    mass_confirm_content: 'Apakah Anda ingin konfirmasi {{count}} pengiriman?',
    mass_confirm_success: '{{count}} DO berhasil dikonfirmasi',
    reject_bid_success: '{{jobNumber}} berhasil ditolak',
    reject_confirmation: 'Ya, Tolak',
    reject_modal_content: 'Jika menolak, Anda tidak dapat mengambil pengiriman ini.',
    reject_modal_title: 'Tolak Pengiriman {{jobNumber}}?',
  },
  bidding_suspended: 'Anda tidak dapat menawar/mengambil muatan selama {{days}}x24 jam',
  cancel: 'Batalkan',
  company_document_type_map: {
    NIK: 'NIK',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKB]: 'SKB',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKT]: 'SKT',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SPPKP]: 'SPPKP',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.TDP]: 'TDP',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS['SPPKP/SKB']]: 'SPPKP',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.AKTA]: 'AKTA',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.NPWP]: 'NPWP',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.NIB]: 'NIB',
    [PBI_COMPANY_REQUIRED_DOCUMENTS['SIUP/SIUJPT']]: 'SIUP/SIUJPT',
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.BANK_ACCOUNT_BOOK]: 'Buku Tabungan',
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.KK]: 'Kartu Keluarga (KK)',
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.SURAT_KUASA]: 'Surat Kuasa',
    [PBI_PERSONAL_REQUIRED_DOCUMENTS.KTP]: 'KTP',
    [TLO_COMPANY_DOCUMENTS.NRIC]: 'NRIC',
    [TLO_COMPANY_DOCUMENTS.SST]: 'SST',
    [TLO_COMPANY_DOCUMENTS.SSM]: 'SSM',
  },
  company_document_title_map: {
    NIK: 'NIK',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKB]: 'SKB',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKT]: 'SKT',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SPPKP]: 'SPPKP',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.TDP]: 'TDP',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS['SPPKP/SKB']]: 'SPPKP',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.AKTA]: 'AKTA Perusahaan',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.NPWP]: 'NPWP',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.NIB]: 'NIB',
    [PBI_COMPANY_REQUIRED_DOCUMENTS['SIUP/SIUJPT']]: 'SIUP/SIUJPT',
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.BANK_ACCOUNT_BOOK]: 'Buku Tabungan',
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.KK]: 'Kartu Keluarga (KK)',
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.SURAT_KUASA]: 'Surat Kuasa',
    [PBI_PERSONAL_REQUIRED_DOCUMENTS.KTP]: 'KTP Pemilik',
    [TLO_COMPANY_DOCUMENTS.NRIC]: 'NRIC',
    [TLO_COMPANY_DOCUMENTS.SST]: 'SST',
    [TLO_COMPANY_DOCUMENTS.SSM]: 'SSM',
  },
  company_document_field_type_map: {
    NIK: 'NIK',
    account_book_name: 'Nama Pemilik Buku Tabungan',
    date: 'Tanggal {{field}}',
    expiry_date: 'Tanggal Kedaluwarsa {{field}}',
    kk_number: 'Nomor KK',
    ktp_name: 'Nama Sesuai KTP',
    number: 'Nomor {{field}}',
    string: '{{field}}',
  },
  company_document_field_name_map: {
    KTP: 'KTP',
    NIK: 'NIK',
    NPWP: 'NPWP',
  },
  company_document_label_map: {
    KTP: 'KTP',
    NIK: 'NIK',
    number: 'Nomor',
  },
  company_name: 'Nama Perusahaan',
  company_information: 'Informasi Perusahaan',
  complete_now: 'Lengkapi Sekarang',
  declare_info_correct: 'Saya menyatakan semua informasi benar',
  document_completed: 'Dokumen belum lengkap',
  document_optional_message: {
    kk_number: 'Pastikan nama Pemilik Rekening tercantum di lampiran Kartu Keluarga',
    surat_kuasa: 'Lampirkan Surat Kuasa yang ditandatangani oleh Pemilik Perusahaan dan Pemilik Rekening',
  },
  driver_registration_status_info_map: {
    DRIVER_VERIFICATION_REJECTED: 'Lengkapi data supir sekarang untuk menjalankan pengiriman dari Panthera Biru Indonesia',
    DRIVER_VERIFICATION_PENDING: 'Data supir sedang diverifikasi oleh Panthera Biru Indonesia',
    [PBI_PARTNERSHIP_STATUSES.PENDING]: 'Siapkan KTP dan SIM supir untuk proses verifikasi supir', // documents processing
  },
  driver_verification_status_info: 'Lengkapi dan kirim data supir Anda untuk dapat menjalankan pengiriman dari Panthera Biru Indonesia',
  driver_verification_in_progress_status_info: 'Data supir sedang diverifikasi oleh Panthera Biru Indonesia',
  driver_sim_type: 'SIM {{type}}',
  driver_status_filters_map: {
    [PBI_DRIVER_VERIFICATION_STATUSES.DRAFT]: 'Draft',
    [PBI_DRIVER_VERIFICATION_STATUSES.PENDING]: 'Pending',
    [PBI_DRIVER_VERIFICATION_STATUSES.ACTIVE]: 'Aktif',
    [PBI_DRIVER_VERIFICATION_STATUSES.REJECTED]: 'Ditolak',
  },
  driver_statuses_map: {
    [PBI_DRIVER_VERIFICATION_STATUSES.DRAFT]: 'Belum Diverifikasi',
    [PBI_DRIVER_VERIFICATION_STATUSES.PENDING]: 'Menunggu Verifikasi',
    [PBI_DRIVER_VERIFICATION_STATUSES.ACTIVE]: 'Aktif',
    [PBI_DRIVER_VERIFICATION_STATUSES.REJECTED]: 'Ditolak',
  },
  email: 'Email',
  expiration_date: 'Tanggal Kedaluwarsa',
  fill_in_bank_info: 'Isi Info Bank',
  fill_in_bank_info_v2: 'Info Bank',
  fill_in_company_info: 'Isi Info Perusahaan',
  fill_in_company_info_v2: 'Info Perusahaan',
  i_have_read_and_agree: 'Saya telah membaca dan menyetujui',
  incomplete_document: 'Dokumen belum lengkap',
  input: 'Input',
  leave_page: 'Tinggalkan halaman',
  leave_page_warning: 'Semua perubahan yang Anda buat akan hilang jika Anda meninggalkan halaman ini',
  leave_this_page: 'Tinggalkan halaman ini?',
  license_need_renewal: 'SIM perlu diperbaharui',
  n_slot_unit: '{{number}} Slot Unit',
  npwp_number: 'Nomor NPWP',
  npwp_number_format: 'XX.XXX.XXX.X-XXX.000',
  optional: 'Opsional',
  owner_email: 'Email Pemilik',
  owner_info: 'Info Pemilik',
  owner_name: 'Nama Pemilik',
  pbi_partners: 'Mitra {{name}}',
  phone_number: 'No. Telepon',
  postal_code: 'Kode Pos',
  placeholder_company_info_input: {
    choose_city: 'Pilih kota',
    company_address: 'Ketik alamat perusahaan',
    company_name: 'Ketik nama perusahaan',
    email: 'Ketik email perusahaan',
    owner_name: 'Ketik nama pemilik perusahaan',
    phone_number: 'Ketik nomor telepon perusahaan',
    postal_code: 'Ketik kode pos',
  },
  placeholder_owner_info_input: {
    account_book_name: 'Ketik nama pemilik buku tabungan',
    address: 'Ketik alamat sesuai KTP',
    choose_city: 'Pilih kota sesuai KTP',
    company_name: 'Ketik nama perusahaan',
    kk_number: 'Masukkan nomor KK',
    ktp_name: 'Ketik nama sesuai KTP',
    ktp_nik: 'Ketik NIK sesuai KTP',
    owner_email: 'Ketik email pemilik',
    owner_name: 'Ketik nama pemilik',
    phone_number: 'Ketik nomor handphone pemilik',
    postal_code: 'Ketik kode pos sesuai KTP',

  },
  placeholder_representative_company_contact_input: {
    name: 'Ketik nama wakil',
    position: 'Ketik jabatan wakil',
    phone_number: 'Ketik nomor handphone wakil',
    email: 'Ketik email wakil',
  },
  program_pbi_partners: 'Program Mitra {{name}}',
  representative: 'Perwakilan {{number}}',
  representative_contact_company: 'Kontak Perwakilan',
  representative_email: 'Email Wakil',
  representative_email_v2: 'Email',
  representative_info: 'Info Wakil',
  representative_name: 'Nama Wakil',
  representative_name_v2: 'Nama',
  representative_of_company: 'Saya wakil perusahaan ini',
  representative_phone_number: 'Nomor Telepon Wakil',
  representative_phone_number_v2: 'No. Handphone',
  representative_position: 'Jabatan Wakil',
  representative_position_v2: 'Jabatan',
  send_document_financial_email_representative: 'Kirim dokumen finansial ke email wakil ini',
  sign_up_now: 'Daftar Sekarang',
  sim_type: 'Tipe SIM',
  siup_siujpt_number: 'Nomor SIUP/SIUJPT',
  success_partnership_form_submitted: 'Program Kemitraan dengan Panthera Biru Indonesia telah berhasil diajukan',
  terms_and_conditions: 'syarat dan ketentuan',
  terms_and_conditions_agreed: 'Saya telah membaca dan menyetujui syarat dan ketentuan',
  today: 'hari ini',
  verification_status_info_map: {
    [PBI_PARTNERSHIP_STATUSES.BLOCKED]: 'Anda tidak dapat menerima DO dari PBI hingga waktu yang tidak ditentukan. Hubungi Kargo untuk informasi lebih lanjut.',
    [PBI_PARTNERSHIP_STATUSES.NOT_REGISTERED]: 'Daftar untuk mendapatkan akses ke Muatan Rutin Kargo.',
    [PBI_PARTNERSHIP_STATUSES.PENDING]: 'Kami akan kabari status kemitraan Anda selambatnya dalam 2 jam kerja.',
    [PBI_PARTNERSHIP_STATUSES.REJECTED]: 'Pastikan dokumen lengkap dan lakukan pendaftaran ulang untuk mendapatkan akses ke Muatan Rutin Kargo.',
    [PBI_PARTNERSHIP_STATUSES.SUSPENDED]: 'Anda tidak dapat menerima DO dari PBI selama {{days}}x24 jam karena akun mitra Panthera Biru Indonesia Anda telah terkena suspend.',
    [PBI_PARTNERSHIP_STATUSES.TRUSTED]: 'Sebagai pengguna terpercaya Anda telah mendapatkan akses ke Muatan Rutin Kargo dan tidak perlu konfirmasi ulang order.',
    [PBI_PARTNERSHIP_STATUSES.VERIFIED]: 'Anda telah mendapatkan akses ke Muatan Rutin Kargo.',
  },
  verification_status_map: {
    [PBI_PARTNERSHIP_STATUSES.BLOCKED]: 'Diblokir',
    [PBI_PARTNERSHIP_STATUSES.NOT_REGISTERED]: 'Belum terdaftar',
    [PBI_PARTNERSHIP_STATUSES.PENDING]: 'Sedang proses verifikasi',
    [PBI_PARTNERSHIP_STATUSES.REJECTED]: 'Pengajuan kemitraan ditolak',
    [PBI_PARTNERSHIP_STATUSES.SUSPENDED]: 'Suspend',
    [PBI_PARTNERSHIP_STATUSES.TRUSTED]: 'Pengguna terpercaya',
    [PBI_PARTNERSHIP_STATUSES.VERIFIED]: 'Pengguna berhasil diverifikasi',
  },
  view_detail: 'Lihat Detail',
  validity_expires_in: 'Masa berlaku habis dalam {{days}}',
  validity_expired: 'Tidak berlaku sejak {{date}}',
  update_successfully_requested: 'Pembaruan berhasil diajukan',
  upload_additional_documents: 'Unggah Dokumen Tambahan',
  upload_additional_documents_v2: 'Dokumen Tambahan',
  upload_required_documents: 'Unggah Dokumen Wajib',
  upload_required_documents_v2: 'Dokumen Wajib',
  upload: 'Unggah',
  upload_document_map: {
    // REQUIRED DOCS
    KTP: 'KTP',
    NIP: 'NIK',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.NPWP]: 'NPWP',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.SIUP]: 'SIUP',
    // OPTIONAL DOCS
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKT]: 'SKT',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SPPKP]: 'SPPKP',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.TDP]: 'TDP',
  },
  upload_photo_general_instructions: 'Petunjuk Umum',
  upload_photo_message: 'Unggah Foto {{name}}',
  upload_photo_rejection_reason_map: {
    [PBI_DOCUMENT_REJECTION_REASON.BLUR]: 'Buram',
    [PBI_DOCUMENT_REJECTION_REASON.DOCUMENT_INCOMPLETE]: 'Dokumen Tidak Lengkap',
    [PBI_DOCUMENT_REJECTION_REASON.EXPIRED]: 'Kedaluwarsa',
    [PBI_DOCUMENT_REJECTION_REASON.OTHER]: 'Lainnya: {{reason}}',
    [PBI_DOCUMENT_REJECTION_REASON.WRONG_DOCUMENT]: 'Salah Dokumen',
  },
  upload_photo_terms: 'Ketentuan Foto {{name}}',
  upload_instructions: {
    [PBI_COMPANY_REQUIRED_DOCUMENTS.AKTA]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY_CLEAR,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_PERSONAL_REQUIRED_DOCUMENTS.KTP]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY_CLEAR,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_COMPANY_REQUIRED_DOCUMENTS.NPWP]: {
      photo_terms: [
        'Nomor NPWP dan masa berlaku terbaca dengan jelas.',
        'Pastikan masa berlaku NPWP lebih dari 5 bulan.',
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_COMPANY_REQUIRED_DOCUMENTS['SIUP/SIUJPT']]: {
      photo_terms: [
        'Alamat dapat terbaca jelas.',
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
      ],
    },
    [PBI_COMPANY_REQUIRED_DOCUMENTS.NIB]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKT]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY_CLEAR,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_COMPANY_OPTIONAL_DOCUMENTS['SPPKP/SKB']]: {
      photo_terms: [
        'Nomor NPWP dan masa berlaku terbaca dengan jelas.',
        'Pastikan masa berlaku NPWP lebih dari 5 bulan.',
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKB]: {
      photo_terms: [
        'Pastikan masa berlaku terlihat jelas.',
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SPPKP]: {
      photo_terms: [
        'Nomor NPWP dan masa berlaku terbaca dengan jelas.',
        'Pastikan masa berlaku NPWP lebih dari 5 bulan.',
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.TDP]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY_CLEAR,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.BANK_ACCOUNT_BOOK]: {
      photo_terms: [
        'Pastikan laman yang terdapat nama dan nomor rekening terlihat jelas',
        'Nama pemilik buku tabungan harus sama dengan nama yang didaftarkan.',
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    // Need to find a way to combine these constants
    [PBI_PERSONAL_REQUIRED_DOCUMENTS.KK]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.KK]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_PERSONAL_REQUIRED_DOCUMENTS.SURAT_KUASA]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.SURAT_KUASA]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_DRIVER_DOCUMENTS.SIM]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY_CLEAR,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
  },
  onboarding_steps: {
    benefits: {
      dialog_title: 'Keuntungan Program Mitra Panthera Biru Indonesia',
      content_title: 'Dapatkan Akses ke Muatan Rutin Kargo',
      content_description: 'Nikmati lebih banyak keuntungan dan akses lengkap ke Muatan Rutin Kargo',
      benefit_list: {
        1: {
          title: 'Ambil Muatan Secara Instan',
          description: 'Tanpa menawar dan sesuai harga di aplikasi',
        },
        2: {
          title: 'Lacak Lokasi Supir Setiap Saat',
          description: 'Berdasarkan posisi supir dengan aplikasi',
        },
        3: {
          title: 'Pilihan Skema Pembayaran 50:50',
          description: 'Lebih cepat untuk optimalkan cashflow Anda',
        },
      },
    },
    terms_conditions: {
      dialog_title: 'Syarat dan Ketentuan Program Mitra Panthera Biru Indonesia',
      terms: {
        1: {
          title: 'Syarat & Ketentuan Menjadi Vendor',
          terms: {
            1: 'Harus melengkapi dokumen legalitas dan truk Anda di aplikasi Kargo Nexus for Transporters sebelum mengambil muatan.',
            2: 'Pastikan Mitra pengemudi Anda sudah download aplikasi Mitra Kargo saat sedang mengambil muatan Kargo.',
          },
        },
        2: {
          title: 'Asuransi Muatan',
          terms: {
            1: 'Harga yang tertera akan dipotong asuransi muatan dengan premi  <strong>Rp 40,000,- (Empat Puluh Ribu Rupiah)</strong> yang hanya akan menanggung biaya klaim sampai dengan sebesar <strong>Rp 20,000,000,- (Dua Puluh Juta Rupiah) untuk setiap order.</strong>',
            2: 'Asuransi akan menanggung 95% dari total klaim <strong>sampai dengan Rp 20,000,000,</strong> - dan 5% dari nilai klaim (min. Rp 25,000, max Rp. 1,000,000) ditanggung oleh Mitra Transporter.',
            3: 'Untuk nilai klaim dibawah Rp 65,000 tidak akan dikenakan pemotongan 5% (min Rp 25,000 dan max Rp 1,000,000) dengan catatan Surat Jalan kembali tepat waktu (Sesuai dengan ketentuan Surat Jalan).',
            4: 'Asuransi tidak menanggung klaim karena barang kurang/hilang tanpa bukti (contoh berita acara dari pihak kepolisian) atau lebih barang yang di charge oleh shipper. Maka dari itu, diharapkan dari mitra untuk mempersiapkan bukti lengkap (contoh foto/video/tanda terima retur) agar dilampirkan ke pihak shipper.',
            5: 'Segala keputusan terkait asuransi merupakan kewenangan perusahaan asuransi dan Kargo tidak dapat dimintakan pertanggungjawaban.',
          },
        },
        3: {
          title: 'Pembayaran',
          terms: {
            1: 'Pembayaran On-Call dapat diatur dengan TOP, atau 100% di akhir atau 50% setelah muat dan 50% setelah bongkar (Tanpa TOP).',
            2: 'Mitra Vendor dengan NPWP akan dipotong PPh 2%. Mitra Transporter tanpa NPWP akan dipotong PPh 4%.',
            3: 'Harap lampirkan SKB di awal, jika ada. Bila hingga proses penempatan Pengemudi selesai dan dokumen perpajakan tidak dilampirkan, maka ketentuan perpajakan akan diterapkan sesuai dengan keadaan dokumentasi yang ada, dengan tunduk pada ketentuan perpajakan yang berlaku di Indonesia.',
            4: 'Mitra Vendor dengan status Pengusaha Kena Pajak (PKP) diwajibkan melakukan upload dokumen pengukuhan PKP.',
            5: 'Bila hingga proses penempatan Pengemudi selesai dan dokumen tidak dilampirkan, maka ketentuan perpajakan akan diterapkan sesuai dengan keadaan dokumentasi yang ada, dengan tunduk pada ketentuan perpajakan yang berlaku di Indonesia.',
            6: 'Harap konfirmasi preferensi pembayaran On-Call dan Status Usaha Anda melalui email <strong>vendormanagement@kargo.tech</strong> dengan format:',
            7: 'Nama Akun',
            8: 'Email:',
            9: 'Pilihan Pembayaran On-Call: 50:50 / TOP ... hari (Pilih salah satu)',
            10: 'Status Usaha: PKP dan SKB / Non-PKP / Individu dengan NPWP / Individu tanpa NPWP (Pilih salah satu)',
            11: 'Mitra Transporter tanpa TOP akan menerima DP 50% pertama <strong>saat Surat Jalan Muat sudah difoto dengan baik dan disetujui oleh Kargo melaluli Aplikasi (dengan catatan Mitra Pengemudi menggunakan aplikasi Mitra Kargo dengan baik dan benar)</strong>. Pembayaran akan dirilis di hari itu juga jika Surat Jalan Muat diterima <strong>sebelum 15.00 WIB.</strong>',
            12: 'Mitra Transporter dengan TOP akan menerima full 100% pembayaran <strong>saat Surat Jalan Muat dan Bongkar sudah difoto dengan baik dan diterima Kargo di Pitstop Kargo (dengan catatan Mitra Pemgemudi menggunakan aplikasi Mitra Kargo dengan baik dan benar)</strong>. Pembayaran akan dirilis di hari itu juga jika Surat Jalan diterima <strong>sebelum jam 15:00 WIB.</strong>',
          },
        },
        4: {
          title: 'Ketentuan Claim',
          terms: {
            1: 'Dokumentasikan (foto / video) situasi unit setelah muat & bongkar, susunan produk, segel unit, dll) agar apabila terjadi klaim, dokumentasi tersebut dapat diajukan untuk dipertimbangkan kembali kepada pihak Shipper.',
            2: 'Mitra Transporter yang <strong>tidak mengikuti program Asuransi Muatan akan bertanggung jawab atas biaya klaim secara penuh.</strong>',
            3: 'Pemotongan biaya klaim dan 5% dari asuransi akan dilakukan di saat proses <strong>pelunasan.</strong>',
            4: 'Berita Acara Klaim yang sudah ditandatangani oleh mitra pengemudi akan dianggap final, maka harap dicek dengan seksama jumlah dan catatan yang tertera di Berita Acara Klaim.',
            5: 'Keputusan terakhir klaim tetap ada di pihak Shipper, sehingga nominal klaim perlu menunggu dari pihak Shipper terlebih dahulu.',
            6: 'Untuk nilai klaim dibawah Rp 65,000 tidak akan dikenakan pemotongan 5% (min Rp 25,000 dan max Rp 1,000,000) dengan catatan Surat Jalan kembali tepat waktu (Sesuai dengan ketentuan Surat Jalan).',
            7: 'Jika langsung diarahkan oleh pihak gudang untuk retur, tanda terima retur yang menyatakan barang telah diterima oleh gudang (wajib) & foto barang telah di retur/diterima (jika ada) perlu dikirimkan ke Kargo.',
          },
        },
        5: {
          title: 'Penundaan Pembayaran',
          terms: {
            1: 'Mitra Transporter diharapkan dapat memantau agar Mitra Pengemudi / Supir selalu menggunakan aplikasi Mitra Kargo saat mengambil DO dari Kargo Tech.',
            2: 'Hal ini dikarenakan terdapat penundaan pembayaran hingga 10 hari kerja apabila Mitra Pengemudi tidak menggunakan aplikasi. Berikut penjelasannya:',
          },
        },
        6: {
          title: 'Mitra Pengemudi hanya menggunakan Aplikasi saat proses muat.',
          subterms: {
            1: {
              title: 'Transporter dengan pembayaran 50:50',
              terms: {
                1: '<strong>Pembayaran pertama (First Payment)</strong> - Dibayarkan setelah SJ muat diterima dan di approve tim Kargo via Aplikasi',
                2: '<strong>Pembayaran pelunasan (Second Payment)</strong> - Dibayarkan 10 hari kerja setelah semua Surat Jalan dan dokumen lainnya (seperti Invoice dan Faktur Pajak, jika dibutuhkan) diterima di Pitstop.',
              },
            },
            2: {
              title: 'Transporter dengan pembayaran TOP',
              terms: {
                1: 'Pembayaran pertama dan Pembayaran Pelunasan dibayarkan sesuai dengan TOP masing-masing Mitra dengan tambahan (+) 10 hari kerja setelah semua Surat Jalan dan dokumen lainnya (seperti Invoice dan Faktur Pajak, jika dibutuhkan) diterima dan di approve tim Kargo via Aplikasi dan diterima tim Kargo di Pitstop.',
                2: 'Simulasi pembayaran: Jika transporter mempunyai TOP 5 hari, maka total 15 hari kerja akan dibayarkan.',
              },
            },
          },
        },
        7: {
          title: 'Mitra Pengemudi hanya menggunakan Aplikasi saat bongkar',
          subterms: {
            1: {
              title: 'Transporter dengan pembayaran 50:50',
              terms: {
                1: 'Pembayaran pertama akan dibayarkan bersamaan dengan Pembayaran pelunasan setelah semua Surat Jalan dan dokumen lainnya (seperti Invoice dan Faktur Pajak, jika dibutuhkan) diterima dan di approve tim Kargo via Aplikasi dan diterima di Pitstop.',
              },
            },
            2: {
              title: 'Transporter dengan pembayaran TOP',
              terms: {
                1: 'Pembayaran pertama dan pembayaran pelunasan dibayarkan sesuai dengan TOP masing-masing Mitra dengan tambahan (+) 10 hari kerja setelah semua Surat Jalan dan dokumen lainnya (seperti Invoice dan Faktur Pajak, jika dibutuhkan) diterima dan di approve oleh tim Kargo via aplikasi dan diterima di Pitstop.',
                2: 'Simulasi pembayaran: Jika transporter mempunyai TOP 5 hari, maka total 15 hari kerja akan dibayarkan.',
              },
            },
          },
        },
        8: {
          title: 'Mitra Pengemudi yang tidak menggunakan Aplikasi Mitra Kargo sama sekali',
          subterms: {
            1: {
              title: 'Transporter dengan pembayaran 50:50',
              terms: {
                1: 'Pembayaran pertama dan Pembayaran pelunasan akan dibayarkan (+) 10 hari kerja dengan catatan semua Surat Jalan dan dokumen lainnya (seperti Invoice dan Faktur Pajak, jika dibutuhkan) sudah diterima Kargo baik via Aplikasi maupun di Pitstop.',
              },
            },
            2: {
              title: 'Transporter dengan pembayaran TOP',
              terms: {
                1: 'Pembayaran pertama dan pembayaran pelunasan dibayarkan sesuai dengan TOP masing-masing Mitra dengan tambahan (+) 10 hari kerja setelah semua Surat Jalan dan dokumen lainnya (seperti Invoice dan Faktur Pajak, jika dibutuhkan) diterima dan di approve oleh tim Kargo via aplikasi dan diterima di Pitstop.',
                2: 'Simulasi pembayaran: Jika transporter mempunyai TOP 5 hari, maka total 15 hari kerja akan dibayarkan.',
              },
            },
          },
        },
        9: {
          title: 'Alamat Pengembalian Surat Jalan dan Pengiriman Invoice',
          subterms: {
            1: {
              title: 'Surat Jalan dan Invoice dapat dikirimkan ke alamat dibawah ini:',
              terms: {
                1: 'Pitstop Cibitung - pengiriman Jawa Barat dan sekitarnya Kawasan Industri MM2100, Jl. Kalimantan, Gandamekar, Kec. Cikarang Bar., Bekasi, Jawa Barat 17530',
                2: 'Pitstop Bawen - pengiriman Jawa Tengah dan sekitarnya Parkir Umum Kargo, Jl. Raya Semarang - Bawen Km 32, Jl. Soekarno - Hatta No.Kel, Harjosari, Kec. Ambarawa, Semarang, Jawa Tengah 50661',
                3: 'Pitstop Tangerang - pengiriman daerah Tangerang dan sekitarnya Jl. MH. Thamrin, RT.002/RW.001, Cikokol, Kec. Tangerang, Kota Tangerang, Banten 15117',
                4: 'Pitstop Pasuruan - pengiriman daerah Pasuruan dan sekitarnya Parkiran Umum Kargo, Jl. Raya Surabaya - Malang Desa No.Km. 48, Petung Sari, Tawang Rejo, Kec. Pandaan, Pasuruan, Jawa Timur 67156',
              },
            },
          },
        },
        10: {
          title: 'Prosedur Denda Keterlambatan Pengembalian Surat Jalan',
          terms: {
            1: 'Pengembalian POD/Surat Jalan maksimal H+3 setelah proses bongkar. Terdapat denda sebesar Rp 100,000,- per hari keterlambatan sampai POD dikembalikan ke tim Kargo (denda terhitung di hari ke-4).',
            2: 'POD yang tidak dikembalikan/tidak diterima Kargo lebih dari 7 hari akan dianggap hilang dan Mitra Transporter akan dikenakan denda dengan detil sebagai berikut:',
            3: 'Mitra Transporter dengan pembayaran 50% - 50%, biaya pelunasan tripnya tidak akan dibayarkan.',
            4: 'Mitra Transporter dengan pembayaran TOP atau 100% di akhir, biaya tripnya yang berkaitan dengan POD yang hilang tersebut akan dipotong sebesar 50%.',
            5: 'Mitra Transporter yang kehilangan POD hanya akan menerima 50% pembayaran pada trip yang berkaitan dengan POD tersebut.',
            6: 'Apabila dalam Catatan Transporter terdapat pesan "Transporter Tidak Perlu Mengirimkan Surat Jalan ke Kargo", maka POD akan diurus langsung antara Pengemudi/Transporter dengan Shipper. Segala bentuk biaya tambahan akan diselesaikan langsung dengan Shipper.',
          },
        },
        11: {
          title: 'Penerbitan Invoice',
          terms: {
            1: 'Mitra Transporter membuat invoice ditujukan kepada PT Panthera Biru Indonesia dengan kop surat perusahaan, tanda tangan, cap basah dan materai.',
            2: 'Invoice untuk DO menggunakan Transporter App Kargo (melalui bidding) harap dipisah dengan DO yang tidak melalui Transporter App Kargo (melalui offline/grup Whatsapp).',
            3: 'Harap selalu melampirkan Faktur Pajak di setiap invoice yang dikirim.',
            4: 'Dianjurkan untuk memisahkan Invoice pembayaran ritase/trip dengan pembayaran biaya muat, bongkar (TKBM) dan biaya menginap.',
            5: 'Kargo dapat menolak melakukan pembayaran atas Invoice yang menurut pendapat Kargo belum lengkap sampai dianggap lengkap.',
          },
        },
        12: {
          title: 'Prosedur Pembayaran',
          terms: {
            1: 'Mitra Transporter diharapkan untuk mengirim invoice dalam bentuk softcopy ke email <strong>paymentteam@kargo.tech</strong>',
            2: 'Proses pembayaran invoice bagi DO yang tidak memiliki klaim akan membutuhkan waktu 0-7 hari kerja setelah invoice diterima pihak Kargo.',
            3: 'Proses pembayaran invoice bagi DO yang memiliki klaim akan membutuhkan waktu lebih lama. Proses pelunasan bagi DO yang memiliki klaim dengan estimasi 1-30 hari kerja dari Invoice diterima karena menunggu kepastian dari pihak shipper.',
            4: 'Mitra Transporter yang terdaftar sebagai <strong>Pengusaha Kena Pajak (PKP) dan pembayaran Kargo dengan down payment 50% dan pelunasan 50%, dihimbau untuk mengeluarkan Invoice dan Faktur Pajak pada pembayaran pelunasan.</strong>',
            5: 'Mitra Transporter dihimbau juga untuk memisahkan Invoice untuk pembayaran antara plat kuning dan plat hitam (jika ada), demi kemudahan proses pembayaran dan perpajakan yang menyangkut di dalamnya.',
          },
        },
        13: {
          title: 'Pusat Bantuan Pembayaran',
          terms: {
            1: 'Segala bentuk pertanyaan, pengaduan, laporan dan informasi pembayaran akan diinformasikan atau ditanggapi melalui email agar memudahkan pihak transporter dan pihak Kargo dalam melakukan pelacakan dan memberikan tanggapan secara cepat.',
            2: 'Email yang dapat digunakan transporter untuk mengajukan pertanyaan, pengaduan dan laporan pembayaran : <strong>paymentteam@kargo.tech</strong>',
            3: 'Terdapat kriteria yang harus dipenuhi transporter dalam melakukan laporan atau pengaduan, yaitu: a. Subject yang jelas. b. Pada badan email harus menyertakan nomor DO atau setidaknya tanggal shipment, nomor plat mobil, asal dan tujuan shipment. Ini bertujuan untuk memudahkan kami dalam melakukan pengecekan.',
          },
        },
        14: {
          title: 'Lain-lain',
          terms: {
            1: 'Kargo dapat merubah atau menyesuaikan isi syarat dan ketentuan ini dari waktu ke waktu jika dianggap perlu. Semua penyesuaian atau perubahan berlaku sejak ditampilkan.',
          },
        },
      },
    },
  },
  stnk: 'STNK',
  stnk_upload: '(2 Foto)',
  stnk_expiry_date: 'Tanggal Kedaluarsa STNK',
  stnk_tax_expiry_date: 'Tanggal Kedaluarsa Pajak (per tahun)',
  stnk_expiry_warning: 'STNK perlu diperbarui',
  kir: 'KIR',
  kir_upload: '(3 Foto)',
  kir_expiry_date: 'Tanggal Kedaluarsa KIR',
  kir_number: 'Nomor Inspeksi',
  kir_expiry_warning: 'KIR perlu diperbarui',
  number_of_documents: '({{number}} Foto)',
  stnk_kir_expiry_warning: 'STNK dan KIR perlu diperbarui',
  about_to_expired: 'Masa berlaku habis dalam {{days}} hari',
  is_expired: 'Tidak berlaku sejak {{date}}',
  fleet_document_upload_dialog: {
    [VEHICLE_DOCUMENT_TYPE.STNK]: {
      title: 'Unggah Foto STNK',
      info: 'Pastikan STNK dikeluarkan dari plastik dan foto tidak buram',
      guide: {
        0: {
          condition: 'Lembar yang terlihat Nomor Polisi Kendaraan',
          photo: 'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/stnk-1.png',
        },
        1: {
          condition: 'Lembar yang terlihat Dimensi / Ukuran Box',
          photo: 'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/stnk-2.png',
        },
      },
    },
    [VEHICLE_DOCUMENT_TYPE.KIR]: {
      title: 'Unggah Foto KIR',
      info: 'Pastikan KIR masih berlaku setidaknya 1 (satu) bulan dari saat Anda registrasi di aplikasi ini.',
      guide: {
        0: {
          condition: 'Lembar yang terlihat Nomor Polisi Kendaraan',
          photo: 'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/kir-1.png',
        },
        1: {
          condition: 'Lembar yang terlihat Dimensi / Ukuran Box',
          photo: 'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/kir-2.png',
        },
        2: {
          condition: 'Lembar yang terlihat masa berlaku KIR',
          photo: 'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/kir-3.png',
        },
      },
    },
    [VEHICLE_DOCUMENT_TYPE.PUSPAKOM_INSPECTION]: {
      title: 'Unggah foto Puspakom Inspection',
      info: 'Pastikan Pusakom Inspection masih berlaku setidaknya 1 (satu) bulan dari saat Anda registrasi di aplikasi ini.',
    },
    [VEHICLE_DOCUMENT_TYPE.ROAD_TAX]: {
      title: 'Unggah foto Road Tax',
      info: 'Pastikan Road Tax masih berlaku setidaknya 1 (satu) bulan dari saat Anda registrasi di aplikasi ini.',
    },
    [VEHICLE_DOCUMENT_TYPE.VEHICLE_REGISTRATION]: {
      title: 'Unggah foto Vehicle Registration',
      info: 'Pastikan foto Vehicle Registration tidak buram.',
    },
  },
  vehicle_inspection: 'Vehicle Inpsection',
  vehicle_document_completion: {
    0: 'Dokumen belum lengkap',
    1: 'Dokumen belum lengkap',
    2: 'Dokumen sudah lengkap',
  },
  vehicle_document_complete: {
    true: 'Dokumen sudah lengkap',
    false: 'Dokumen belum lengkap',
  },
  vehicle_document_type: {
    [VEHICLE_DOCUMENT_TYPE.STNK]: 'STNK',
    [VEHICLE_DOCUMENT_TYPE.KIR]: 'KIR',
    [VEHICLE_DOCUMENT_TYPE.PUSPAKOM_INSPECTION]: 'Puspakom Inspection',
    [VEHICLE_DOCUMENT_TYPE.ROAD_TAX]: 'Road Tax',
    [VEHICLE_DOCUMENT_TYPE.VEHICLE_REGISTRATION]: 'Vehicle Registration',
  },
  vehicle_status: {
    [PBI_VEHICLE_STATUS.ACTIVE]: 'Aktif',
    [PBI_VEHICLE_STATUS.DRAFT]: 'Belum Verifikasi',
    [PBI_VEHICLE_STATUS.PENDING]: 'Menunggu Verifikasi',
    [PBI_VEHICLE_STATUS.REJECTED]: 'Ditolak',
  },
};
