import { get } from 'lodash-es';

// eslint-disable-next-line import/prefer-default-export
export const SHIPPER_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  TRUCK_REQUIREMENT: 'TRUCK_REQUIREMENT',
  REVIEW: 'REVIEW',
};

export const CONTRACT_DOCUMENT_TYPE = {
  CONTRACT_WORK_ORDER: 'Work Order',
  CONTRACT_AGREEMENT: 'Agreement',
};

export const PRODUCT_CATEGORY = {
  ALCOHOLIC_BEVARAGES: 'Alcoholic Beverages',
  DRINKING_WATER: 'Drinking Water',
  COFFEE: 'Coffee',
  FRUIT_FLAVORED_DRINKS: 'Fruit Flavored Drinks',
  JUICE: 'Juice',
  MILK: 'Milk',
  NON_DAIRY: 'Non-Dairy Milk',
  POWDERED_BEVERAGES_MIXES: 'Powdered Beverage Mixes',
  SODA: 'Soda',
  SPORTS_ENERGY_DRINKS: 'Sports & Energy Drinks',
  TEA_INFUSIONS: 'Tea & Infusions',
  WATER: 'Water',
  BAKERY: 'Bakery',
  SNAKES: 'Snacks',
  CONDIMENTS_SOUCES: 'Condiments & Sauces',
  COOKING_BAKING_INGREDIENTS: 'Cooking & Baking Ingredients',
  DAIRY: 'Dairy Products (Chilled)',
  DIPS: 'Dips & Spreads',
  FOOD: 'Food Gift Baskets',
  FROZEN: 'Frozen Desserts & Novelties',
  FRUITS: 'Fruits & Vegetables',
  GRAINS: 'Grains, Rice & Cereal',
  MEAT: 'Meat, Seafood & Eggs',
  NUTS: 'Nuts & Seeds',
  PASTA: 'Pasta & Noodles',
  PREPARED: 'Prepared Foods',
  SPICES: 'Seasonings & Spices',
  SNACKS: 'Snack Foods',
  SOUPS: 'Soups & Broths',
  TOUFU: 'Tofu, Soy & Vegetarian Products',
  FITNESS_AND_NUTRITION: 'Fitness & Nutrition',
  MEDICINE_AND_DRUGS: 'Medicine & Drugs',
  FMCG: 'FMCG',
  PLASTIC: 'Plastic Pellets',
  PACKAGING: 'Packaging',
};

export const INDUSTRY = {
  FMCG: 'FMCG',
  RAW_MATERIAL_OR_ORM: 'Raw Materials/OEM',
  LOGISTIC_COMPANY: 'Logistics Company',
  BROKER_TRANSPORTER: 'Broker/Transporter',
  E_COMMERCE: 'E-Commerce',
  COMMODITY_AND_TRADING: 'Commodity and Trading',
};

export const AGREEMENT_STATUS_TYPE = {
  AGREEMENT_SIGNED_BY_BOTH: 'Agreement Signed by Both',
  AGREEMENT_SIGNED_BY_KARGO: 'Agreement Signed by Kargo',
  DRAFT_AGREEMENT: 'Draft Agreement',
  QUOTATION_SIGNED_BY_BOTH: 'Quotation Signed by Both',
  QUOTATION_SIGNED_BY_KARGO: 'Quotation Signed by Kargo',
  UNDOCUMENTED: 'Undocumented',
  WORK_ORDER_SIGNED_BY_BOTH: 'Work Order Signed by Both',
  WORK_ORDER_SIGNED_BY_KARGO: 'Work Order Signed by Kargo',
};

export const TERM_OF_PAYMENT = {
  CALENDAR_DAYS: 'Calendar Days',
  WORKING_DAYS: 'Working Days',
};

export const PAYMENT_METHOD_OPTIONS = {
  CASH: 'Cash',
  CHECK: 'Check',
  BANK_TRANSFER: 'Bank Transfer',
  GIRO: 'Giro',
};

export const CLAIMS_TYPE = [
  'claimDamage',
  'claimMissing',
  'claimPackagingDamage',
  'claimNearExpiryDate',
  'claimWrongGoods',
  'claimTooMany',
  'claimTooFew',
];

export const CUSTOMER_PIC_TYPE = {
  LOGISTICS: 'Logistics',
  PROCUREMENT: 'Procurement',
  FINANCE: 'Finance',
  TREASURY: 'Treasury',
  CLAIMS: 'Claims',
};

export const CUSTOMER_PIC_DESCRIPTION = {
  LOGISTICS: 'In charge of operations, monitoring, and DO ordering',
  PROCUREMENT: 'In charge of price negotiations and creating Purchase Orders (PO).',
  FINANCE: 'In charge of account payables. ',
  TREASURY: 'In charge of disbursing payments.',
  CLAIMS: 'In charge of handling claims.',
};

export const COMPANY_DOCUMENT_TYPE_ATTRIBUTE = {
  TDP: ['number', 'expiryDate'],
  KTP: ['number'],
  'SIUP/SIUJPT': ['number', 'expiryDate'],
};

export const COMPANY_DOCUMENT_TYPE = {
  TDP: 'TDP',
  KTP: 'KTP',
  'SIUP/SIUJPT': 'SIUP',
};

export const OVERNIGHT_PROOF_TYPE = {
  SHIPPER_APPROVAL: 'SHIPPER_APPROVAL',
  FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY: 'FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY',
  WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS: 'WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS',
  DRIVER_APP_GPS_TIMESTAMPS: 'DRIVER_APP_GPS_TIMESTAMPS',
  OTHER: 'OTHER',
};

export const CANCELLATION_FEE_TYPES = [
  'Cancellation After Truck Arrival at Origin',
  'Cancellation after Truck Details are Confirmed',
];

export const CANCELLATION_FEE_TYPE_TO_BACKEND = {
  'Cancellation After Truck Arrival at Origin': 'TRUCK_ARRIVAL',
  'Cancellation after Truck Details are Confirmed': 'TRUCK_DETAILS_CONFIRMED',
};

export const OVERNIGHT_FEE_TYPES = [
  'Overnight Loading Fee',
  'Overnight Unloading Fee',
];

export const OVERNIGHT_FEE_TYPE_TO_BACKEND = {
  'Overnight Loading Fee': 'LOADING',
  'Overnight Unloading Fee': 'UNLOADING',
};

export const MULTIPLE_SHIPMENT_FEE_TYPES = [
  'Multiple Shipment Fee',
];

export const FEE_TYPES = [
  ...CANCELLATION_FEE_TYPES,
  ...OVERNIGHT_FEE_TYPES,
  ...MULTIPLE_SHIPMENT_FEE_TYPES,
];

export const CANCELLATION_FEE_FEE_TYPE = {
  FIXED_AMOUNT: 'common:fixed_amount',
  PERCENTAGE_OF_SHIPPER_PRICE: 'common:percentage_of_shipper_price',
};

export const PROOF_OF_CANCELLATION_TYPE = {
  WHATSAPP_APPROVAL: 'common:proof_of_cancellation_type:WHATSAPP_APPROVAL',
  EMAIL_APPROVAL: 'common:proof_of_cancellation_type:EMAIL_APPROVAL',
  DRIVER_APP_GPS: 'common:proof_of_cancellation_type:DRIVER_APP_GPS',
};

export const OVERNIGHT_FEE_PRICING_TYPE = {
  FLAT: 'common:flat_pricing_type',
  PROGRESSIVE: 'common:progressive_pricing_type',
};

export const SPECIAL_DAY_INCLUSION_TYPE = {
  SUNDAYS: 'SUNDAYS',
  NATIONAL_HOLIDAYS: 'NATIONAL_HOLIDAYS',
};

export const SHOW_CLAIMS_OPTIONS = {
  POD: 'POD',
  BERITA_ACARA: 'Berita Acara',
  LOAD_ITEMS: 'Load Items',
};

export const ALTERNATIVE_KIR_OPTIONS = [
  'EXPIRED_KIR',
  'VALID_PROOF_OF_RENEWAL',
  'EXPIRED_PROOF_OF_RENEWAL',
  'VALID_TRAFFIC_TICKET',
  'EXPIRED_TRAFFIC_TICKET',
];

export const ALTERNATIVE_STNK_OPTIONS = [
  'EXPIRED_STNK',
  'VALID_PROOF_OF_RENEWAL',
  'TRAFFIC_TICKET_PHOTOCOPY',
  'VALID_TRAFFIC_TICKET',
  'EXPIRED_TRAFFIC_TICKET',
];

export const ALTERNATIVE_LEMBAR_PAJAK_OPTIONS = [
  'VALID_TRAFFIC_TICKET',
  'EXPIRED_TRAFFIC_TICKET',
  'PROOF_OF_DOCUMENTS_OR_LICENSE_RENEWAL',
  'PROOF_OF_EXPIRED_TAX',
];

export const ALTERNATIVE_SIM_OPTIONS = [
  'EXPIRED_SIM',
  'PHOTOCOPY_OF_SIM',
  'VALID_TRAFFIC_TICKET',
  'EXPIRED_TRAFFIC_TICKET',
];

export const feeTypeToLabel = (t, feeType) => {
  if (CANCELLATION_FEE_TYPES.includes(feeType)) {
    return t(`common:additional_fee_type:cancellation:${CANCELLATION_FEE_TYPE_TO_BACKEND[feeType]}`);
  }
  if (MULTIPLE_SHIPMENT_FEE_TYPES.includes(feeType)) {
    return t(`common:additional_fee_type:multishipment`);
  }
  if (OVERNIGHT_FEE_TYPES.includes(feeType)) {
    return t(`common:additional_fee_type:overnight:${OVERNIGHT_FEE_TYPE_TO_BACKEND[feeType]}`);
  }
  return '';
};

export const truckRequirementName = (t, truckRequirementData, position) => {
  if (get(truckRequirementData, 'truckSubType.value')) {
    return `${get(truckRequirementData, 'truckType.label')} ${get(truckRequirementData, 'truckSubType.label')}`;
  }
  return `${t('common:truck_type')} ${position + 1}`;
};

export const majorAnchors = {
  BASIC_PROFILE: '#BasicProfile',
  TRUCK_REQUIREMENT: '#TruckRequirement',
  REVIEW: '#Review',
  SKIP: '#SkipTruckRequirements',
};

export const anchorLinks = [
  {
    id: SHIPPER_FORM_SEGMENTS.DETAILS_FORM,
    href: majorAnchors.BASIC_PROFILE,
    title: 'basic_profile',
    subAnchors: [
      {
        id: 'CompanyDetails',
        href: '#CompanyDetails',
        title: 'company_details',
      },
      {
        id: 'Documents',
        href: '#Documents',
        title: 'documents',
      },
      {
        id: 'Payments',
        href: '#Payments',
        title: 'payments',
      },
      {
        id: 'DocumentReturns',
        href: '#DocumentReturns',
        title: 'document_returns',
      },
      {
        id: 'ClaimsAndReturns',
        href: '#ClaimsAndReturns',
        title: 'shipment_document_type_map.claims-returns',
      },
      {
        id: 'Contacts',
        href: '#Contacts',
        title: 'contacts',
      },
    ],
  },
  {
    id: SHIPPER_FORM_SEGMENTS.TRUCK_REQUIREMENT,
    href: majorAnchors.TRUCK_REQUIREMENT,
    title: 'truck_requirements',
    subAnchors: [],
  },
  {
    id: SHIPPER_FORM_SEGMENTS.REVIEW,
    href: majorAnchors.REVIEW,
    title: 'review_shipper',
    subAnchors: [
      {
        id: 'EditBasicProfile',
        href: majorAnchors.BASIC_PROFILE,
        title: 'edit_basic_profile',
        className: 'edit-nav',
      },
      {
        id: 'EditTruckRequirement',
        href: majorAnchors.TRUCK_REQUIREMENT,
        title: 'edit_truck_requirement',
        className: 'edit-nav',
      },
    ],
  },
];

export const UNCAUGHT_NETWORK_ERROR = {
  NO_CURRENT_USER: 'No current user',
};
