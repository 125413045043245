import initializeTracker from '@kargotech/tms-analytics';
import firebaseAdapter from '@kargotech/tms-analytics/adapters/firebase-adapter';
import moment from 'moment/moment';
import { firebaseApp } from '~/Services/firebase';

const firebaseTracker = firebaseApp !== null ? initializeTracker(firebaseAdapter(), {
  firebaseApp,
}) : null;
export const setTrackerData = data => firebaseTracker?.getTrackerInstance().set(data);
export const trackerEvent = ({ action, ...props }) => firebaseTracker?.trackEvent(
  action,
  {
    timestamp: moment(),
    platform: 'NFT',
    env: window.ENVIRONMENT,
    ...props,
  },
);
