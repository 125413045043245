import { FullScreenDrawer, Space, Typography } from '@kargotech/tms-ui/components';
import { get } from 'lodash-es';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const ScrollableDiv = styled.div`
  max-height: 600px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FullScreenModal = styled(FullScreenDrawer.Paper)`
  height: 100vh;
  margin: 0 !important;
  max-width: 100vw;
  padding: 16px 0;

  h3.ant-typography {
    box-shadow: 0px 1px 0px rgba(4, 7, 12, 0.1);
    display: flex;
    margin: 0 !important;
    padding: 0 56px 16px 56px;
  }
`;

const DialogContainer = styled(Space)`
  margin: 0 0 32px 0;
  padding: 0 56px 0 56px;

  div.ant-space-item {
    margin: 0 !important;
  }

`;

const FaqDialog = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(true);
  const faqBids = t('bidding:bid_faq', { returnObjects: true });
  const faqContentList = Object.values(get(faqBids, 'content_list'));

  const handleClose = () => {
    setOpenModal(!openModal);
    history.push('/bidding/available');
  };

  return (
    <FullScreenDrawer closable={true} destroyOnClose={true} onClose={handleClose} placement="top" visible={openModal}>
      <FullScreenModal
        title={get(faqBids, 'dialog_title')}
      >
        <ScrollableDiv>
          {faqContentList.map(content => (
            <DialogContainer key={content.title} direction="vertical">
              <Typography.Text strong={true}>{content.question}</Typography.Text>
              <Typography.Text>{content.answer}</Typography.Text>
            </DialogContainer>
          ))}
        </ScrollableDiv>
      </FullScreenModal>
    </FullScreenDrawer>
  );
};

export default FaqDialog;
