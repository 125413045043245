import gql from 'graphql-tag';

export default gql`
query COMPANY_NFT_MODULES(
  $companyKsuid: String!,
  $ids: [String]
  ) {
  companyNftModules(
    companyKsuid: $companyKsuid,
    ids: $ids
  ) {
    id #format: parent-name_module-name
    name
    dependencyModules
    isActive
  }
}
`;
