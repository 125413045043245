/* eslint-disable max-len */

import {
  FIRST_MILE_USER_ACCESS_TYPE,
  PBI_PARTNERSHIP_STATUSES,
  USER_ACCESS_TYPE,
  SHIPMENT_INVOICE_TYPE,
  INVOICE_PAYMENT_STATUS,
  CUSTOMIZABLE_INVOICE_STATUSES,
} from '~/Configurations/constants';

export default {
  accept_successfuly_submitted: 'Anda sudah kirim permintaan untuk {{doNumber}}',
  activate_vehicle_alert_warning: 'Anda telah mengubah kondisi armada menjadi Sedang Bermasalah. Armada ini akan Tidak Bisa Digunakan. Silakan isi keterangan pada kolom di bawah ini.',
  actual_time: 'Waktu Kedatangan Aktual',
  add_account_helper_text: 'Tambah rekening bank yang akan dicantumkan di invoice',
  add_prices_profile: 'Mari tambahkan biaya pengiriman untuk melengkapi profil pengirim Anda',
  add_shipper_profile: 'Mari Tambahkan biaya lokasi untuk melengkapi profil pengirim Anda',
  already_have_an_account: 'Sudah mempunyai akun?',
  attachment_invoice_message: 'Lampiran berikut dikirimkan bersama invoice melalui email',
  back_navigation_alert_desc: 'Semua perubahan yang Anda buat akan hilang jika Anda meninggalkan halaman ini',
  bank_account_successfully_saved: 'Rekening bank tersimpan',
  bank_is_valid: 'Pastikan informasi bank Anda valid, karena semua pembayaran dari pelanggan akan diteruskan ke rekening ini.',
  before_preceded_by_another_vendor: 'sebelum Anda didahului vendor lain',
  being_damaged: 'Sedang Rusak',
  bid_loose_notification_msg_end: 'belum terpilih.',
  bid_loose_notification_msg_start: 'Tawaran Anda untuk',
  bid_too_high_msg: 'Tawaran maksimal adalah {{amount}}',
  bid_win_notification_msg_end: 'terpilih!',
  bid_win_notification_msg_start: 'Selamat, tawaran Anda untuk',
  btms_do: {
    claim_radio_info_message: 'Beberapa barang bisa rusak selama pengiriman sehingga Anda perlu mengirim klaim barang. Apakah pada bongkar ini terdapat klaim barang?',
    driver_assignment_changed_message: 'Perubahan detail truk Anda sedang diproses oleh Kargo. Hubungi Kargo untuk mempercepat verifikasi.',
    driver_assignment_cancel_message: 'Anda dapat mengubah kembali detail truk dengan membatalkan perubahan ini terlebih dahulu.',
  },
  btms_invoice: {
    banner_status_description_map: {
      [INVOICE_PAYMENT_STATUS.SUBMITTED]: 'Cetak dan kirim invoice fisik ke PT Panthera Biru Indonesia',
      [INVOICE_PAYMENT_STATUS.APPROVED]: 'Cetak dan kirim invoice fisik ke PT Panthera Biru Indonesia',
      [INVOICE_PAYMENT_STATUS.REJECTED]: 'Terdapat kesalahan {{revise_item}}. Harap kirim revisi invoice agar tim PT. Panthera Biru Indonesia dapat membetulkan invoice.',
      [INVOICE_PAYMENT_STATUS.REVISION_CREATED]: 'Revisi invoice telah dibuat. Invoice dengan nomor <0>{{invoice_number}}</0> telah dibatalkan secara otomatis',
      [INVOICE_PAYMENT_STATUS.REVISION_SUBMITTED]: 'Revisi invoice telah dikirim dan sedang ditinjau. Invoice dengan nomor <0>{{invoice_number}}</0> telah dibatalkan secara otomatis',
      [INVOICE_PAYMENT_STATUS.PHYSICALLY_SENT]: 'Invoice fisik sedang dalam proses pengiriman. Anda akan melihat pembaruan status jika invoice telah diterima',
      [INVOICE_PAYMENT_STATUS.PHYSICALLY_RECEIVED]: 'Invoice fisik telah diterima dan sedang ditinjau oleh PT Panthera Biru Indonesia',
      [INVOICE_PAYMENT_STATUS.PHYSICALLY_APPROVED]: 'Pembayaran sedang diajukan dan akan segera diproses. Anda akan melihat pembaruan status jika pembayaran telah dijadwalkan',
      [INVOICE_PAYMENT_STATUS.ON_HOLD]: 'Invoice fisik kurang meterai. Harap kirim ulang invoice fisik dengan meterai dan edit informasi nomor resi pada detail invoice Anda',
      [INVOICE_PAYMENT_STATUS.CANCELLED]: 'Invoice dibatalkan secara otomatis dan digantikan dengan invoice baru karena terjadi revisi. Nomor invoice baru: <0>{{invoice_number}}</0>',
      [INVOICE_PAYMENT_STATUS.PAYMENT_REQUESTED]: 'Pembayaran segera dikirimkan ke nomor rekening yang tertera',
      [INVOICE_PAYMENT_STATUS.PAID]: 'Pembayaran sebesar {{invoice_amount}} telah dikirim',
    },
    banner_status_title_map: {
      [INVOICE_PAYMENT_STATUS.SUBMITTED]: 'Invoice Digital Ditinjau',
      [INVOICE_PAYMENT_STATUS.APPROVED]: 'Invoice Digital Disetujui',
      [INVOICE_PAYMENT_STATUS.REJECTED]: 'Perlu Revisi',
      [INVOICE_PAYMENT_STATUS.REVISION_CREATED]: 'Revisi Dibuat',
      [INVOICE_PAYMENT_STATUS.REVISION_SUBMITTED]: 'Revisi Sedang Ditinjau',
      [INVOICE_PAYMENT_STATUS.PHYSICALLY_SENT]: 'Invoice Fisik Dikirim',
      [INVOICE_PAYMENT_STATUS.PHYSICALLY_RECEIVED]: 'Invoice Fisik Ditinjau',
      [INVOICE_PAYMENT_STATUS.PHYSICALLY_APPROVED]: 'Invoice Fisik Disetujui',
      [INVOICE_PAYMENT_STATUS.ON_HOLD]: 'Invoice Ditahan',
      [INVOICE_PAYMENT_STATUS.CANCELLED]: 'Invoice Dibatalkan',
      [INVOICE_PAYMENT_STATUS.PAYMENT_REQUESTED]: 'Pembayaran Diproses',
      [INVOICE_PAYMENT_STATUS.PAID]: 'Invoice Lunas',
    },
    faktur_pajak_need_to_be_uploaded: 'Segera unggah faktur pajak agar kami dapat memproses pembayaran invoice ini',
    faktur_pajak_not_uploaded_banner: 'Faktur Pajak belum diunggah',
    submit_invoice_dialog_description: 'Anda yakin ingin mengirimkan invoice digital ini?',
    submit_invoice_disabled_reason: 'Unggah Faktur Pajak untuk mengirimkan invoice digital ke PT Panthera Biru Indonesia (Kargo)',
    submit_invoice_success_message: 'Invoice Digital berhasil dikirim. Selanjutnya invoice akan ditinjau',
  },
  bulk_upload_csv: {
    csv_upload_msg: '2. Isi data sesuai pengiriman. Kolom dengan latar belakang biru muda wajib diisi.',
    csv_upload_template_msg: '4. Setelah itu, unggah dokumen CSV dari template yang sudah diisi tersebut di sini',
    invalid_format: 'Error Template CSV',
    invalid_separator: 'Error Pemisah CSV',
    check_csv_upload_file_description: 'Cek kembali dokumen CSV yang Anda unggah di bawah ini.',
    csv_download_excel_template: '3. Unduh dokumen Excel yang telah Anda isi tersebut ke dalam bentuk CSV',
    csv_download_template_description: '1. Unduh contoh dokumen Excel.',
    csv_separator_msg1: 'Beberapa perangkat mungkin memiliki pemisah yang berbeda untuk CSV File\n',
    csv_separator_msg2: 'Harap pilih pemisah CSV yang Anda gunakan saat ini (koma, titik koma, dll)',
    csv_separator_msg3: 'Pemisah standar yang umumnya digunakan adalah koma (,)',
    csv_separator_msg4: 'Catatan: Anda dapat melihat pemisah CSV saat ini dengan membuka file di Notepad (atau aplikasi editor teks lainnya)',
    csv_upload_description: 'Saat ini template hanya untuk pengiriman dengan titik muat dan bongkar tunggal.',
    invalid_separator_description: 'Mohon memilih Pemisah CSV yang sesuai dengan perangkat Anda.',
    invalid_format_description: 'Mohon menggunakan Template CSV yang sesuai seperti yang disediakan.',
  },
  bulk_upload_excel: {
    check_excel_upload_file_description: 'Cek kembali dokumen Excel yang Anda unggah di bawah ini.',
    excel_download_template: '1. Unduh dokumen Excel',
    excel_fill_data_msg: '2. Isi data sesuai pengiriman.',
    excel_upload_from_template: '3. Setelah itu, unggah dokumen Excel dari template yang sudah diisi tersebut di sini',
    invalid_format: 'Error Template Excel',
    invalid_headers_description: 'Mohon menggunakan Template Excel yang sesuai seperti yang disediakan.',
  },
  calculate_average_mileage_info: 'Perhitungan dihasilkan dari total jarak tempuh terhadap rute yang dicari dibagi jumlah DO dalam rute tersebut. Jarak tempuh di luar DO tidak akan dihitung. Contoh, jika driver telah menyelesaikan DO, lalu driver membawa kendaraan ke bengkel, maka itu tidak dihitung.',
  calculate_total_mileage_info: 'Perhitungan didapatkan dari total jarak yang ditempuh setiap kendaraan dari dan menuju lokasi sesuai DO. Jarak tempuh yang ada di luar DO tidak akan dihitung. Contoh, jika driver telah menyelesaikan DO, lalu driver membawa kendaraan ke bengkel, maka itu tidak dihitung.',
  cancel_btms_do_warning: 'Jika dibatalkan, akun Anda akan diblokir untuk menerima tawaran dari Panthera Biru Indonesia selama 1 x 24 jam',
  cancel_bid_warning_msg: 'Jika dibatalkan, pelanggan tidak akan dapat melihat dan memilih penawaran anda',
  cancel_do_warning: 'Pengiriman ini akan tetap tersimpan, namun tidak akan bisa diaktifkan kembali.',
  cancel_do_warning_new: 'Pengiriman ini akan dipindahkan ke riwayat dan tidak akan bisa diaktifkan kembali',
  cancel_this_offer: 'Batalkan penawaran ini?',
  cannot_be_used: 'Tidak Bisa Digunakan',
  cannot_change_status_shipment_to_complete: 'Tidak dapat mengubah status ke Pengiriman Selesai. Hubungi Shipper untuk menyelesaikan pengiriman',
  change_shipment_status_message_title: 'Mengubah status akan....',
  choose_account_number: 'Pilih Nomor Rekening',
  choose_customer: 'Pilih Customer',
  choose_customer_pic: 'Pilih Customer PIC',
  choose_date: 'Pilih Tanggal',
  choose_fee_type: 'Pilih Jenis Biaya',
  choose_plate_color: 'Pilih Warna Plat',
  choose_shipment_to_create_invoice: 'Pilih pengiriman dari satu customer',
  choose_type_of_goods: 'Pilih Jenis Barang',
  choose_shipment_number: 'Pilih No. Pengiriman',
  confirmation_failed: 'Anda tidak dapat melakukan konfirmasi ulang untuk pengiriman ini.',
  confirmation_in_progress: 'Konfirmasi sedang dalam proses',
  created_by_table: 'Created by {{name}} on {{date}}',
  customer_central_address_help_field: 'Alamat ini akan ditampilkan sebagai alamat customer saat Anda membuat invoice',
  deactivate_vehicle_alert_warning: 'Anda akan megubah status kondisi Armada menjadi dalam kondisi yang Baik. Armada ini akan siap digunakan untuk pengiriman.',
  delete_do_template_warning: 'Data form yang sudah terisi sebelumnya yang akan terhapus permanen dan diperlukan menginput ulang data pengiriman baru.',
  delete_invoice_number_template_question: 'Hapus format nomor invoice?',
  delete_invoice_number_template_warning: 'Kode customer ini akan dihapus dan tidak dapat dikembalikan. Tetap hapus?',
  delete_list_invoice_number_template_warning: 'Format nomor invoice ini akan dihapus dan tidak dapat dikembalikan. Tetap hapus?',
  delete_shipment_from_invoice_warning: 'Pengiriman dan dokumen yang Anda telah masukkan untuk Invoice ini akan hilang.',
  do_already_expired_msg: 'Mohon maaf, pengiriman {{doNumber}} tidak tersedia.',
  do_already_taken_msg: 'Mohon maaf, pengiriman {{doNumber}} tidak tersedia karena sudah diterima.',
  do_already_expired_msg_none: 'Mohon maaf, pengiriman ini tidak tersedia.',
  do_already_taken_msg_none: 'Mohon maaf, pengiriman ini tidak tersedia karena sudah diterima.',
  do_container_update_success: 'Container Successfully Updated',
  do_created_successfully: 'DO berhasil dibuat.',
  do_late_warning: 'Jadwal DO lain yang bisa jadi telat',
  do_expired_grace_period_reminder_banner_description: 'Anda masih memiliki akses ke akun Kargo Nexus Anda hingga tanggal {{date}}. Hubungi kami agar tetap terhubung dengan seluruh fitur Kargo Nexus for Transporters',
  do_expired_grace_period_reminder_banner_title: 'Kontrak Anda sudah tidak aktif sejak {{date}}',
  do_expired_today_reminder_banner_description: 'Hubungi kami agar dapat terus terhubung dengan seluruh fitur Kargo Nexus for Transporters',
  do_expired_today_reminder_banner_title: 'Periode kontrak Anda habis hari ini',
  documents_will_soon_be_verified_result_will_be_notified: 'Dokumen yang sudah Anda upload akan segera diverifikasi oleh tim Kargo. Hasil verifikasi akan diinformasikan melalui halaman ini atau melalui email yang terdaftar',
  does_document_meet_requirements: 'Apakah dokumen sudah memenuhi syarat?',
  dof2_total_disbursement_formula: 'Pencairan Pertama + Kedua + Ketiga',
  dof2_total_second_disbursement_formula: 'Pencairan Pertama + Kedua',
  dont_have_an_account_yet: 'Belum mempunyai akun?',
  download_locations_csv_msg: 'Atau unduh templatenya ',
  empty_notification: 'Belum ada notifikasi',
  enter_invoice_number: 'Masukkan Nomor Invoice',
  enter_verification_code_that_we_sent_to: 'Masukkan kode verifikasi yang kami kirim ke',
  errors: {
    backend: {
      acceptDeliveryOrder: {
        9: 'Mohon maaf, pengiriman ini tidak tersedia karena sudah diterima',
      },
      approveDofRequest: {
        83: 'Pengajuan pembayaran cepat tidak terdaftar di sistem',
        84: 'Satu atau beberapa pengiriman yang ditolak bukan bagian dari pengajuan pembayaran ini',
      },
      assignPlannedShipment: {
        108: 'Shipment is not assignable(current status is not PLANNED)',
        131: 'Company Vehicle has ongoing shipment',
        132: 'Company Driver has ongoing shipment',
      },
      assignShipperSalesAccess: {
        465: 'Email sudah diambil di dalam perusahaan',
      },
      common: {
        401: 'Anda tidak memiliki izin',
        500: 'Terjadi kesalahan internal',
        '018-5500': 'Terjadi kesalahan internal',
      },
      createBillOfLading: {
        94: 'No. Bill of Lading sudah pernah digunakan sebelumnya',
      },
      createFirstMileInvoice: {
        422: 'Nomor invoice sudah ada di sistem, nomor invoice harus unik',
      },
      createSmeSales: {
        465: 'Email sudah diambil di dalam perusahaan',
      },
      editBillOfLading: {
        94: 'No. Bill of Lading sudah pernah digunakan sebelumnya',
        422: 'Store tidak bisa diubah',
      },
      generateCompanyHandles: {
        34: 'Harap gunakan hanya alfa numerik dan huruf kecil',
      },
      saveBrokerageVendor: {
        34: 'Harap gunakan hanya alfa numerik dan huruf kecil',
      },
      saveCompanyDetails: {
        34: 'Harap gunakan hanya alfa numerik dan huruf kecil',
      },
      saveCompanyVehicle: {
        1: 'Perusahaan tidak terdaftar',
        2: 'Armada belum tidak terdaftar',
        3: 'Gagal mengunggah STNK',
        4: 'Gagal mengunggah KIR',
        14: 'Armada sudah terdaftar',
        15: 'Tipe kendaraan tidak valid',
      },
      saveCompanyVehicles: {
        422: 'Ada beberapa data yang tidak valid',
      },
      saveCustomer: {
        1: 'Perusahaan tidak terdaftar',
        5: 'Perusahaan customer tidak terdaftar',
      },
      saveDeliveryOrder: {
        53: 'Lokasi bongkar tidak valid',
        54: 'Lokasi muat tidak valid',
        63: 'Tanggal bongkar tidak valid',
        66: 'Customer tidak valid',
        422: 'Invalid CSV parameter/attributes',
        482: 'Hmm you need to input this field if it is a single shipment type',
        483: 'Input a date and time format using DD-MM-YYYY HH:MM',
        484: 'Input an active Location registered in B-TMS',
        485: 'Input an active Location registered in B-TMS',
        486: 'Hmm, tidak ada pengirim dengan nama ini.',
        488: 'Hmm, this truck type doesn\'t have Overnight Fees registered for this Shipper and Shipment Type. Let\'s pick another one.',
        490: 'Hmm..this Location doesnt have a Loading Fee for the chosen Shipper, Truck Type, Body Type, and Shipment Type',
        491: 'Hmm..this Location doesnt have a Unloading Fee for the chosen Shipper, Truck Type, Body Type, and Shipment Type',
        493: 'Deadline datetime must be in DD/MM/YYYY format.',
        494: 'Input a date and time format using DD-MM-YYYY HH:MM',
        497: 'Hmm all Group Loads must be posted to Marketplace, try again with "Yes" or "TRUE"',
        498: 'Hmm Per Unit Price Rate Type cannot be posted to Marketplace, try again with "No" or "FALSE"',
        499: 'Hmm, for a Group Load, number of external identifiers must match the number of shipments (with each external identifier separated by semicolon) or must be left blank; for a non-Group Load, enter one external identifier or leave blank',
        501: 'Posted DO  must have origin or posted price.',
        502: 'Isi harga yang mau Anda posting ke Marketplace atau pakai harga rekomendasi kami.',
        505: 'Hmm, this price_rate_type doesn\'t match the Goods Capacity Unit. Pick "Per Route" (or "Route"), "Per {Capacity Unit}" (or "Capacity Unit")',
        506: 'Cannot post capacity unit.',
        507: 'Input YES/TRUE or NO/FALSE',
        508: 'Only posted DO have max transported bid price',
        509: 'Hmm the value here must match the Max Bid Limit (%)',
        510: 'Only posted DO have max bid limit percentage.',
        512: 'Hmm, enter 1 or leave blank to create a Group Load; enter more than 1 and less than 21 to create a Group Load',
        513: 'Hmm, tipe pengirimannya belum tepat. Pilih "f2w", "w2w", "f2dist", "w2dist", "f2mt", "w2depo", "f2depo", "w2mt", "mt", "gt", "multidrop", "multipickup", atau "multidrop_multipickup".',
        516: 'Hmm, tipe truk ini tidak ada. Pilih tipe truk lain, ya.',
        517: 'Hmm, subtipe truk ini tidak ada. Pilih subtipe truk lain, ya.',
        518: 'Gunakan angka 0 atau angka positif dengan maksimal 8 angka di belakang koma.',
        519: 'Hmm, this capacity_unit doesn\'t seem right. Pick "Kg", "CBM", "Unit"',
        523: 'Hmmm you must input a positive number up to 8 decimal points',
        524: 'Hmmm you must input a number from 0-100',
        525: 'Hmmm you must input a positive number up to 8 decimal points',
        526: 'Hmm, this Shipper doesn\'t have this price_rate_type registered for this shipment. Let\'s register the Shipper Price',
        530: 'Input valid shipment type',
        531: 'Invalid point type',
        532: 'Input a date and time format using YYYY-MM-DD HH:MM:SS',
        533: 'Hmm number of locations inputted must be equal to the number of pickup and dropoff points and they must be unique from each other',
        534: 'Input a Location registered in B-TMS',
      },
      saveEmployee: {
        26: 'Email atau telepon sudah digunakan',
      },
      savePartnershipPricing: {
        1001: 'Transporter Tidak Valid',
        1002: 'Harga Duplikat',
        1003: 'Harga kemitraan tidak ditemukan',
        1004: 'Gagal menghapus harga kemitraan',
      },
      saveWarehouseFee: {
        45: 'Anda sudah punya Biaya Lokasi dengan Tipe Biaya, Tipe Pengiriman, Tipe Truk, dan Subtipe truk yang sama',
        46: 'Anda sudah punya Biaya Lokasi dengan Tipe Biaya, Tipe Pengiriman, Tipe Truk, dan Subtipe truk yang sama',
      },
      uploadContainers: {
        422: 'Invalid container file',
      },
    },
    different_origin_and_destination: 'Asal dan tujuan harus berbeda',
    disbursement_form_wrong_input: 'Kesalahan bidang masukan',
    error_code: {
      401: 'Anda tidak memiliki izin',
      445: 'Nomor belum terdaftar.',
      500: 'Terjadi kesalahan internal',
      4112: 'Kata Sandi Salah',
    },
    network: {
      500: 'Terjadi kesalahan internal',
      common: 'Periksa kembali koneksi internet Anda',
    },
    otp_required_please_retry: 'Kode verifikasi tidak ditemukan. Silakan ulangi',
    phone_number_already_registered: 'Nomor sudah terdaftar',
    status_code: {
      401: 'Anda tidak memiliki izin',
      500: 'Terjadi kesalahan internal',
      '018-5500': 'Terjadi kesalahan internal',
    },
    unexpected: 'Terjadi kesalahan, Tim Kargo segera menyelidiki hal ini',
    verification_code_mismatch: 'Kode verifikasi yang anda masukkan tidak sesuai',
  },
  file_has_not_been_selected: 'File belum dipilih',
  file_should_not_contain_mixed_file_types: 'Tidak dapat mengunggah dokumen dengan jenis file yang berbeda-beda',
  file_too_big_failed_upload: 'Ukuran file melebihi {{fileSize}}MB. Mohon cek kembali',
  fill_account_details: 'Isi Detail Akun',
  fill_bid_price: 'Isi harga tawaran',
  fill_company_details: 'Isi Detail Perusahaan',
  financing_adjustment_microcopy: 'Perubahan Harga Customer - Claim - Transfer Fee',
  first_or_last_shipment_points_type_invalid: 'Shipment point pertama harus berupa muat dan shipment point terakhir harus berupa bongkar',
  free_trial_banner: {
    contact_kargo: 'Hubungi Kargo',
    description_expired: 'Agar dapet mengakses seluruh fitur Kargo Nexus, hubungi kami untuk mengaktifkan kembali akun Anda',
    description_expiring: 'Agar tetap terhubung ke akun Anda, <Link>hubungi Kargo</Link> untuk meningkatkan status akun',
    message_expire_today: 'Uji coba gratis berakhir hari ini',
    message_expired: 'Uji coba gratis Anda telah berakhir',
    message_expired_specific_company: 'Uji coba gratis {{companyName}} telah berakhir',
    message_expiring: 'Uji coba gratis akan berakhir pada {{date}}',
    whatsapp_text: 'Halo tim Kargo Nexus, saya ingin meningkatkan status akun Kargo Nexus for Transporters saya. Bisa jadwalkan demo?',
  },
  general_failed_confirmed: '{{name}} gagal dikonfirmasi',
  general_successfully_confirmed: '{{name}} berhasil dikonfirmasi',
  general_successfully_saved: '{{name}} berhasil disimpan',
  generic_add: 'Tambah {{name}}',
  generic_add_more: 'Tambah Lebih {{name}}',
  generic_edit: 'Ubah {{name}}',
  generic_select: 'Pilih {{name}}',
  generic_upload: 'Unggah {{name}}',
  generic_send: 'Kirim {{name}}',
  generic_successfully_downloaded: '{{name}} berhasil diunduh',
  grace_period_banner: {
    contact_kargo: 'Hubungi Kargo',
    description_expired: 'Hubungi kami untuk mengaktifkan kembali akun Kargo Nexus for Transporters Anda',
    message_expired: 'Kontrak Anda telah habis',
    whatsapp_text: 'Halo tim Kargo Nexus, saya ingin meningkatkan status akun Kargo Nexus for Transporters saya. Bisa jadwalkan demo?',
  },
  income_details_displayed_here: 'Rincian pendapatan akan ditampilkan disini',
  ineligible_dof_shipment_status: 'Hanya bisa mengajukan Pengiriman yang paling tidak POL-nya sudah diterima',
  insert_company_code: 'Masukkan kode perusahaan',
  insert_date_to_calculate_actual_top: 'Masukkan tanggal untuk menghitung Actual ToP',
  invalid_bank_account: 'Masukkan nomor rekening yang valid',
  invalid_bank_account_holder_name: 'Masukkan nama pemegang rekening yang valid',
  invalid_phone_number: 'Harus dimulai dengan tanda +',
  invoice_cannot_create_number_description: 'Silakan pilih pelanggan terlebih dahulu sebelum menggunakan format nomor invoice otomatis.',
  invoice_cannot_create_number_warning: 'Tidak bisa membuat format nomor invoice',
  invoice_create_number_question: 'Buat format nomor invoice?',
  invoice_create_number_warning: 'Anda belum memiliki format nomor invoice untuk customer ini',
  invoice_code_format_example: 'Contoh: Jika Anda memasukkan 4 digit maka kode invoice XX/XX/XX/<strong>0001</strong>',
  invoice_code_format_generation: 'Atur jumlah digit kode untuk invoice yang dikeluarkan. Setelah itu kode invoice akan diurutkan secara otomatis.',
  invoice_company_code_format_generation: 'Masukkan kode perusahaan Anda untuk dicantumkan pada nomor invoice.',
  invoice_create_auto_format_generation: 'Buat format nomor invoice untuk menghasilkan nomor invoice secara otomatis',
  invoice_customer_code_format_generation: 'Pilih kode customer untuk penanda bahwa invoice ditujukan pada customer tertentu.',
  invoice_delete_dialog: 'Template invoice akan dihapus secara permanen dan tidak dapat dikembalikan',
  invoice_fee_changes_text: 'Terdapat perubahan pada tabel rincian tagihan.',
  invoice_note_placeholder: {
    [SHIPMENT_INVOICE_TYPE.ONCALL]: '',
    [SHIPMENT_INVOICE_TYPE.RENTAL]: 'Tuliskan instruksi khusus atau pesan untuk pelanggan',
  },
  invoice_pdf_should_only_one_file: 'Tidak dapat menunggah lebih dari satu dokumen PDF untuk invoice',
  invoice_receipt_successfully_added: 'Tanda Terima Invoice berhasil ditambahkan',
  invoice_setup_format: 'Atur format komponen invoice sesuai yang Anda butuhkan',
  invoice_template_can_be_saved: 'Format invoice ini dapat disimpan sebagai template dan digunakan untuk pembuatan invoice berikutnya',
  invoice_template_successfully_deleted: 'Template Invoice Telah Dihapus',
  invoice_template_successfully_duplicated: 'Template Invoice Berhasil Diduplikat',
  invoice_template_successfully_saved: 'Template Invoice Berhasil Disimpan',
  invoice_template_successfully_updated: 'Template Invoice Berhasil Diubah',
  invoice_will_be_shown_here: 'Invoice akan tampil disini',
  kargo_will_process_your_do: 'Kargo akan memproses pesananan Anda. Mohon menunggu untuk proses konfirmasi.',
  leave_dof2_disbursed_form_warning: 'Detail pencairan yang sudah diisi akan hilang.',
  leave_form_warning: 'Semua informasi yang telah Anda masukkan akan hilang',
  leave_shipment_form_warning: 'Pengiriman ini tidak akan tersimpan jika Anda meninggalkan halaman ini sekarang.',
  leave_shipper_form_warning: 'Pengirim tidak akan disimpan jika Anda meninggalkan halaman ini sekarang.',
  leave_this_page_question: 'Tinggalkan halaman ini?',
  max_x_shipment_selection_exceeded: 'Batas jumlah Pengiriman yang dapat dipilih adalah {{x}} Pengiriman.',
  no_invoice_approved: 'Tidak ada Invoice yang disetujui',
  no_invoice_rejected: 'Tidak ada Invoice yang ditolak',
  not_registered_with_kargo_dof_program: 'Anda belum terdaftar program DOF Kargo',
  not_registered_with_kargo_podf_program: 'Anda belum terdaftar program PODF Kargo',
  number_invoice_format_has_been_changed: 'Format nomor invoice berhasil diubah',
  number_invoice_format_has_been_deleted: 'Format nomor invoice berhasil dihapus',
  number_invoice_format_has_been_made: 'Format nomor invoice berhasil dibuat',
  offer_bidding_cancelled: 'Bidding penawaran {{doNumber}} berhasil dibatalkan',
  offer_bidding_non_slot_submitted: 'Penawaran untuk {{doNumber}} terkirim',
  offer_bidding_slot_submitted: 'Penawaran untuk {{amount}} Slot Unit terkirim',
  offer_successfuly_canceled: 'Penawaran {{doNumber}} berhasil dibatalkan',
  offer_successfuly_submitted: 'Anda berhasil kirim tawaran untuk {{doNumber}}',
  on_hold_reason: 'Pengajuan ditunda, segera Hubungi admin Kargo',
  one_day_error: 'Pilih lebih dari 1 hari pada "Rentang Tanggal"',
  only_kargo_admin_can_edit: 'Hanya admin Kargo yang dapat mengubah informasi ini.',
  otp_instruction: 'Ketik password baru, lalu masukkan kode\nverifikasi yang kami kirim ke',
  panthera_do_number_info: 'Nomor DO Panthera ini akan digunakan untuk menampilkan lokasi GPS di TMS Shipper.',
  password_doesnt_match: 'Kedua password yang anda masukkan tidak sesuai',
  password_placeholder: 'Ketik Password',
  password_successfully_changed: 'Password berhasil diubah',
  payment_project_save_as_draft_msg: 'Data pengajuan yang sudah Anda masukkan akan tersimpan sebagai draft dan proses pengajuan dapat Anda lanjutkan kembali nanti',
  pbi_registration: {
    i_have_read_and_agree: 'Saya telah membaca dan menyetujui',
    terms_and_conditions: 'syarat dan ketentuan',
    terms_and_conditions_agreed: 'Saya telah membaca dan menyetujui syarat dan ketentuan',
    verification_status_info_map: {
      [PBI_PARTNERSHIP_STATUSES.NOT_REGISTERED]: 'Daftar untuk mendapatkan akses ke Muatan Rutin Kargo',
      [PBI_PARTNERSHIP_STATUSES.PENDING]: 'Kami akan kabari status verifikasi akun Anda selambatnya dalam 2 jam kerja',
      [PBI_PARTNERSHIP_STATUSES.VERIFIED]: 'Anda telah mendapatkan akses ke Muatan Rutin Kargo',
      [PBI_PARTNERSHIP_STATUSES.TRUSTED]: 'Sebagai pengguna terpercaya Anda telah mendapatkan akses ke Muatan Rutin Kargo dan tidak perlu konfirmasi ulang order',
      [PBI_PARTNERSHIP_STATUSES.SUSPENDED]: 'Anda tidak dapat menerima DO dari PBI selama 1x24 jam karena akun mitra Panthera Biru Indonesia Anda telah terkena suspend',
      [PBI_PARTNERSHIP_STATUSES.REJECTED]: 'Verifikasi Anda ditolak. Harap ulangi proses pendaftaran Anda untuk mendapatkan akses ke Muatan Rutin Kargo',
      [PBI_PARTNERSHIP_STATUSES.BLOCKED]: 'Anda tidak dapat menerima DO dari PBI hingga waktu yang tidak ditentukan karena akun mitra Panthera Biru Indonesia Anda telah diblokir',
    },
  },
  pbi_invoice: {
    check_performance: 'Cek Performa Anda Disini',
    download_invoice_detail: 'Unduh Rincian',
    get_performance_information: 'Dapatkan informasi performa untuk tiap pengiriman yang anda lakukan',
    invoice_filter_message: 'Lengkapi filter untuk memunculkan daftar DO yang siap dibuatkan invoice',
    revise_created: 'Kirim Revisi Invoice',
    revise_invoice: 'Revisi Invoice',
    send_digital_invoice: 'Kirim Invoice Digital',
    send_physical_document: 'Kirim Invoice Fisik',
  },
  pbi_invoice_disabled_tooltip: {
    [CUSTOMIZABLE_INVOICE_STATUSES.WAITING_FOR_CLAIM_APPROVAL]: 'Claim sedang diproses oleh PT Panthera Biru Indonesia',
    [CUSTOMIZABLE_INVOICE_STATUSES.WAITING_FOR_POD]: 'Bukti pengiriman belum diterima oleh PT Panthera Biru Indonesia',
    [CUSTOMIZABLE_INVOICE_STATUSES.EXPIRED_STNK]: 'STNK kadaluarsa, harap perbarui data Armada PBI.',
    [CUSTOMIZABLE_INVOICE_STATUSES.ONGOING_SHIPMENT]: 'Belum bisa ditagih karena masih berjalan',
    [CUSTOMIZABLE_INVOICE_STATUSES.WAITING_FOR_INVOICE_PAID]: 'Belum bisa ditagih karena menunggu pembayaran Shipper',
  },
  phone_number_placeholder: 'Ketik Nomor Handphone',
  please_reconfirm_below: 'Mohon lakukan konfirmasi ulang untuk Pengiriman di bawah ini.',
  please_resubmit_rejected_documents: 'Silahkan buat pengajuan baru dan perbaiki dokumen yang ditolak',
  please_resubmit_rejected_requests: 'Silahkan buat pengajuan baru dan perbaiki dokumen untuk pengiriman yang ditolak',
  propose_verification_process_for_main_documents: 'Ajukan Proses Verifikasi untuk Dokumen Utama?',
  ready_to_use: 'Siap Digunakan',
  reset_password_success: 'Password Anda berhasil diubah.',
  save_as_draft_and_exit: 'Simpan sebagai draft dan keluar dari proses pengajuan?',
  save_shipment_as_new_template: 'Simpan Pengiriman sebagai Template Baru',
  save_shipment_as_type_template: 'Pilih Jenis Penyimpanan Template Pengiriman',
  search_by_do_number: 'Cari no. DO',
  search_by_invoice_number: 'Cari No. Invoice',
  search_by_license_plate: 'Cari No. Polisi',
  search_by_license_plate_or_kode_lambung: 'Cari No. Polisi atau Kode Lambung',
  search_by_name: 'Cari nama',
  search_customer: 'Cari Customer',
  search_customer_v2: 'Cari Pelanggan',
  search_shipment_placeholder: 'Cari No. Pengiriman, No. Polisi',
  search_shipment_number: 'Cari No. Pengiriman',
  search_shipper: 'Cari Shipper',
  search_transporter: 'Cari Transporter',
  search_warehouse: 'Cari Gudang',
  select_customer: 'Pilih Pelanggan ',
  select_truck_first_to_change_status: 'Pilih Truk terlebih dahulu untuk mengganti status',
  send_verification_code_failed: 'send_verification_code_failed',
  send_verification_code_success: 'Kode verifikasi terkirim.',
  shipment_allocation_choose_shipper_helper: 'Supir akan menerima notifikasi untuk pengarahan pengiriman ini.',
  shipment_already_requested_for_financing: 'Pengiriman sudah pernah diajukan untuk pembayaran cepat',
  shipment_fee_successfully_added: 'Biaya trucking berhasil ditambahkan.',
  shipment_invalid: 'Pengiriman sudah tidak berlaku',
  shipment_points_not_sorted: 'Semua waktu muat dan bongkar harus berurut sesuai dengan urutan lokasi muat dan bongkar',
  shipment_successfully_cancelled: 'Pengiriman berhasil dibatalkan',
  shipment_successfully_saved: 'Pengiriman berhasil disimpan',
  shipment_template_successfully_saved: 'Template Pengiriman Berhasil Disimpan',
  shipment_trace_messages: {
    cancelled: 'Tidak ada riwayat perjalanan untuk pengiriman ini',
    no_data: 'Gagal mengambil data untuk pengiriman ini',
    ongoing: 'Pengiriman sedang berlangsung. Lihat rute pengiriman dan <Link>pantau armada</Link> saat ini',
    planned: 'Rute pengiriman akan terlihat ketika pengiriman sedang berlangsung atau telah selesai',
  },
  shipper_already_included_on_selected_dof_request: 'Hanya bisa mengajukan untuk satu customer dalam satu pengajuan',
  shipper_failed_to_add: 'Gagal menambahkan Pengirim Perusahaan',
  shipper_failed_to_edit: 'Gagal mengedit Pengirim Perusahaan',
  shipper_successfully_edit: 'Pengirim Perusahaan diedit',
  shipper_successfully_saved: 'Pengirim Perusahaan ditambahkan',
  signature_required_above_the_seal: {
    description: 'Untuk nominal invoice lebih dari atau sama dengan Rp5.000.000, wajib membubuhkan tanda tangan basah dan meterai',
    title: 'Wajib ditanda tangani di atas meterai',
  },
  skip_truck_requirement_question: 'Skip Truck Requirement?',
  some_file_download_failed: 'Beberapa file tidak dapat diunduh.',
  some_shipments_are_not_eligible_for_dof2: 'Pengiriman tidak akan dihitung pada pencairan ke-2. Untuk mencairkan sisa pengiriman, silahkan buat pengajuan baru ketika pengiriman sudah memenuhi syarat',
  status_successfully_changed: 'Status berhasil diubah',
  swift_payment_price_tnc: 'Nilai pencairan akan dihitung berdasarkan evaluasi dan perjanjian kerjasama Anda',
  tick_signature: 'Centang Tanda Tangan',
  transporter_incentive_page: {
    tooltip_label: 'Baca syarat dan ketentuan insentif',
    dialog_header: 'Syarat dan Ketentuan insentif',
    dialog_body: {
      1: '1. Vendor mendapat insentif dari PT Panthera Biru Indonesia (<strong>Panthera</strong>) sesuai ketentuan berikut',
      2: '2. Vendor mendapat insentif hanya dari pekerjaan yang memiliki tanda <strong>“Insentif”</strong> dan telah menyelesaikannya dengan baik',
      3: '3. Jumlah insentif dihitung dari harga yang tertera berdasarkan persentase yang disebutkan',
      4: '4. Proses pembayaran insentif dimulai setelah Panthera menerima bukti bongkar (Proof of Delivery/POD)',
      5: '5. Waktu pembayaran insentif disesuaikan dengan keputusan Panthera',
      6: '6. Ketentuan di atas hanya berlaku jika Vendor belum atau tidak menandatangani kesepakatan dengan Panthera',
      7: '7. Jika sudah ada kesepakatan antara Vendor dan Panthera, maka yang berlaku adalah ketentuan dalam kesepakatan tersebut',
    },
  },
  top_performance_indicator: {
    bad_performance: 'Performa Kurang Baik',
    good_performance: 'Performa Baik',
  },
  total_price_including_ppn: 'Harga total sudah termasuk PPN',
  total_price_including_tax: 'Biaya Pengiriman Termasuk PPN',
  unable_to_change_delivery_details: 'Tidak dapat mengubah detail pengiriman karena terdaftar pada pengajuan Pembayaran Cepat',
  unable_to_change_disbursement_details: 'Tidak dapat mengubah Info Pencairan yang otomatis',
  update_shipment_status_warning: 'Urutan status yang Anda pilih adalah sebelum status pengiriman sekarang. Apakah anda yakin ingin menambahkan status?',
  user_access_type_description_map: {
    [FIRST_MILE_USER_ACCESS_TYPE.CONSIGNEE]: '-',
    [FIRST_MILE_USER_ACCESS_TYPE.FREIGHT_FORWARDER]: '-',
    [USER_ACCESS_TYPE.FINANCE]: 'Akses untuk melihat, merubah, dan melakukan apapun tanpa akses untuk mengelola karyawan lain.',
    [USER_ACCESS_TYPE.FULL_ACCESS]: 'Akses untuk melihat, merubah, melakukan apapun, dan mengelola karyawan lain.',
    [USER_ACCESS_TYPE.RESTRICTED_ACCESS]: 'Akses untuk hanya melihat informasi pengiriman dan pembayaran.',
    [USER_ACCESS_TYPE.PRICE_RESTRICTED_ACCESS]: 'Akses untuk melihat informasi pengiriman tanpa disertai keterangan harga atau biaya.',
  },
  utilization_tooltip: {
    current_utilization_info: 'Utilisasi saat ini dihitung dari rata-rata lead time aktual dibandingkan dengan hari kerja perusahaan.',
    lead_time_info: 'Lead time aktual dimulai ketika supir berangkat ke lokasi muat yang pertama hingga pengiriman selesai. Apabila terdapat waktu tunggu di antara setiap lokasi, maka waktu tunggu tersebut ikut dihitung.',
    company_working_days_info: 'Hari kerja perusahaan diatur di dalam pengaturan perusahaan.',
  },
  utilization_upload_csv_steps: {
    1: '1. Unduh contoh dokumen CSV.',
    2: '2. Isi data sesuai laporan Target Utilisasi per Rute.',
    3: '3. Setelah itu, unggah dokumen CSV dengan template yang sudah diisi.',
  },
  utilization_upload_csv_confirm_message: 'Cek kembali dokumen CSV yang Anda unggah di bawah ini.',
  vehicle_is_broken: 'Armada Sedang Bermasalah',
  vehicle_is_in_good_condition: 'Kondisi armada baik',
  we_will_send_temporary_password: 'Kami akan mengirimkan password sementara ke nomor handphone {{name}}. {{name}} dapat masuk ke {{systemName}} dan mengganti passwordnya.',
  welcome_to_nexus_for_transporters: 'Selamat datang di {{name}}!',
  welcome_to_nexus_for_transporters_description: 'Isi detail perusahaan Anda untuk mulai mengelola bisnis Anda dengan {{name}}',
  you_can_proceed_to_this_stage_when_the_verification_complete: 'Anda dapat melanjutkan ke tahap ini saat verifikasi Dokumen Utama selesai',
  panthera_max_20: '<strong>PT Panthera Biru Indonesia</strong> Tidak bisa mengolah lebih dari 20 Pengiriman dalam satu Invoice.',
  choose_shipment_with_same_plate_color: 'Pilih DO yang memiliki warna plat yang sama.',
  choose_shipment_with_same_disbursement_scheme: 'Pilih DO yang memiliki skema pembayaran yang sama.',
  price_are_zero: 'Tidak ada biaya yang bisa di invoice',
  invoice_min_amount_schema: 'Sejak tanggal {{startDate}} Kargo hanya menerima Invoice dari {{transporterName}} dengan jumlah tagihan minimum {{minAmount}} Untuk informasi lebih lanjut anda bisa membaca',
  cannot_combine_vat: 'Harga DO yang termasuk PPN, tidak bisa ditagihkan bersama DO yang tidak masuk PPN.',
  redirecting_to_expected_page: 'Mengarahkan ke halaman yang sesuai...',
  redirecting_to_specific_page: 'Mengarahkan ke {{page}}...',
  error_title: 'Terjadi kesalahan',
  error_hint: 'Silakan laporkan hal ini pada tim kami untuk segera ditinjau',
};
