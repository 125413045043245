import React, { createContext, useState } from 'react';

export const NavigationContext = createContext();

const NavigationProvider = ({ children }) => {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [tabNavigation, setTabNavigation] = useState([]);
  const [showLogo, setShowLogo] = useState(false);
  const [isCenteredLogo, setIsCenteredLogo] = useState(false);
  const [toolbarSection, setToolbarSection] = useState(null);
  const [headHeight, setHeadHeight] = useState(0);

  return (
    <NavigationContext.Provider
      value={{
        title,
        setTitle,
        subtitle,
        setSubtitle,
        tabNavigation,
        setTabNavigation,
        showLogo,
        setShowLogo,
        isCenteredLogo,
        setIsCenteredLogo,
        toolbarSection,
        setToolbarSection,
        headHeight,
        setHeadHeight,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
