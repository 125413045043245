/* eslint-disable max-len */

import {
  AR_AGING_LABELS,
  CUSTOM_INVOICE_FEE_UNITS,
  DOF2_ELIGIBILITY_STATUS,
  REIMBURSEMENT_TYPE,
  SHIPMENT_STATUSES,
  SHIPMENT_POINT_TYPES,
  SHIPMENT_TEMPLATE_SAVE_TYPE,
  DOCUMENT_TYPES,
  BTMS_DRIVER_REASSIGN_STATUS,
  INVOICE_FORMAT_DEFAULT,
  MONTH_INVOICE_FORMAT,
  YEAR_INVOICE_FORMAT,
  PROJECT_DOCUMENT_STATUSES,
  WHT_SELECTION_TYPE,
} from '~/Configurations/constants';
import { DOCUMENT_REQUEST_TYPE, SHIPMENT_DOCUMENT_STATUSES } from '~/Modules/Shipment/Utilities/constants';

export default {
  account_deactivation_warning_message: 'You will not be able to use this bank account to create invoices',
  account_successfully_updated_as_default: 'Account {{account_number}} has been successfully set as the default account',
  activity_log_time: 'Activity: {{time}} {{timezone}}',
  additional_notes: 'Notes: {{notes}}',
  add_additional_detail: 'Detail {{payment_type}}',
  add_additional_fee: 'Add {{payment_type}}',
  add_additional_fee_for_shipment: 'Add Additional Fee for {{shipment_number}}',
  additional_fee_successfully_added: '{{payment_type}} successfully added.',
  additional_fee_successfully_updated: '{{payment_type}} successfully updated.',
  against_the_invoice_value: '{{percentage}} against the invoice value',
  amount_per_unit_type: {
    [CUSTOM_INVOICE_FEE_UNITS.PER_CBM]: '{{amount}} CBM',
    [CUSTOM_INVOICE_FEE_UNITS.PER_KG]: '{{amount}} KG',
  },
  ar_aging_label_map: {
    [AR_AGING_LABELS.AGING_DUE]: 'Due in {{days}} days',
    [AR_AGING_LABELS.AGING_DUE_TODAY]: 'Due today',
    [AR_AGING_LABELS.AGING_OVERDUE]: 'Overdue by {{days}} days',
  },
  average_load_time: 'Average Load Time - {{vehicleLabel}}: {{time}} hours',
  average_unload_time: 'Average Unload Time - {{vehicleLabel}}: {{time}} hours',
  average_vehicle_load_time: 'Average Load Time - All Vehicle Types: {{time}} hours',
  average_vehicle_unload_time: 'Average Unload Time - All Vehicle Types: {{time}} hours',
  bidding_delivery_details: 'Bidding Delivery {{biddingNumber}}',
  bidding_point_multi: 'Point {{pointName}}',
  bidding_point_multi_map: {
    [SHIPMENT_POINT_TYPES.PICKUP]: 'Pickup',
    [SHIPMENT_POINT_TYPES.DROPOFF]: 'Dropoff',
    [SHIPMENT_POINT_TYPES.PICKUP_DROPOFF]: 'Dropoff + Pickup',
  },
  bidding_point_schedule: 'Schedule {{pointType}}: {{date}} WIB',
  bidding_point_slot: 'Bidding Delivery {{slot}} Slot Unit',
  bill_need_to_be_more_than_amount: 'Collective Bill must be more than {{amount}}',
  btms_driver_assignment_status: {
    [BTMS_DRIVER_REASSIGN_STATUS.REASSIGNMENT_PENDING]: 'Change {{updatedItems}} proposed by {{actor}}',
    [BTMS_DRIVER_REASSIGN_STATUS.REASSIGNMENT_CANCELLED]: 'Change {{updatedItems}} cancelled by {{actor}}',
    [BTMS_DRIVER_REASSIGN_STATUS.REASSIGNMENT_REJECTED]: 'Change {{updatedItems}} rejected by {{actor}}',
    [BTMS_DRIVER_REASSIGN_STATUS.REASSIGNMENT_APPROVED_BY_BROKERAGE]: 'Change {{updatedItems}} verified by {{actor}}',
    [BTMS_DRIVER_REASSIGN_STATUS.REASSIGNMENT_APPROVED_BY_SHIPPER]: 'Change {{updatedItems}} verified by {{actor}}',
    [BTMS_DRIVER_REASSIGN_STATUS.REASSIGNMENT_APPROVED]: 'Change {{updatedItems}} verified by {{actor}}',
    toast_update_assignment: 'Submission for changing license plate and driver was successful',
    toast_cancel_assignment: 'Submission for changing license plate and driver was successfully cancelled',
  },
  btms_do_slot_calculation: '({{capacity}}{{unit}}{{price}}{{slotUnits}})',
  btms_document_status_tag: {
    [SHIPMENT_DOCUMENT_STATUSES.PENDING]: '{{docType}} Under Verification',
    [SHIPMENT_DOCUMENT_STATUSES.APPROVED]: '{{docType}} Verified',
    [SHIPMENT_DOCUMENT_STATUSES.REJECTED]: 'Re-upload {{docType}}',
    [SHIPMENT_DOCUMENT_STATUSES.INVALID]: 'Invalid',
  },
  btms_upload_cta: {
    [DOCUMENT_REQUEST_TYPE.REUPLOAD]: {
      [DOCUMENT_TYPES.POD]: 'Re-upload POD {{sequence}}',
      [DOCUMENT_TYPES.POL]: 'Re-upload POL {{sequence}}',
    },
    [DOCUMENT_REQUEST_TYPE.UPLOAD]: {
      [DOCUMENT_TYPES.POD]: 'Upload POD {{sequence}}',
      [DOCUMENT_TYPES.POL]: 'Upload POL {{sequence}}',
    },
  },
  bulk_upload_csv: {
    column_code: 'Column {{column_code}}',
    column_error: 'Error {{ column_name }}',
    column_line: 'Line {{ line }}',
    column_error_count: 'There are {{count}} errors',
  },
  cancel_adding_fee: 'Cancel adding this fee?',
  cancel_do: 'Cancel {{do_number}}?',
  change_invoice_status: 'Change Invoice Status {{invoice_number}} {{status}}?',
  change_invoice_status_old: 'Change Invoice Status {{invoice_number}}?',
  change_invoice_status_message: 'Change status to {{status}}?',
  change_shipment_status: 'Change Status {{do_number}}?',
  change_status_time: 'Change Status Time {{do_number}}?',
  changed_by: 'by <strong>{{actor}}</strong>. ',
  chip_document_status_tag: {
    [PROJECT_DOCUMENT_STATUSES.REJECTED]: 'Not Approved',
    [PROJECT_DOCUMENT_STATUSES.APPROVED]: 'Approved',
    [PROJECT_DOCUMENT_STATUSES.UNDER_VERIFICATION]: 'Under Verification',
  },
  choose_shipment_from_shipper: 'Select shipment from {{shipper}}',
  confirm_revise_invoice: 'Confirm Invoice Revision {{sequence}}',
  contact_kargo_to_change_status_of_fast_payment: 'Contact Kargo to change the status of Fast Payment',
  create_customer_data_for_funding: 'Register and add customer data for your fleet operational funding needs.',
  created_at: 'Created on {{date}}',
  custom_invoice_delete_fee_disabled: 'To delete this fee, please change and remove it from the invoice {{invoice}} details first',
  custom_invoice_edit_fee_warning: 'Changing this fee detail will automatically change the invoice {{invoice}} details',
  custom_invoice_total_fee_message: 'Total Fee is calculated from Fee Amount x {{unit}}',
  custom_shipment_total_fee_message: 'Total Fee is calculated from Shipment Price x {{unit}}',
  customer_division: 'Division {{division}}',
  customer_restrict_create_do: 'Cannot create shipment for {{name}}',
  customer_successfully_created: '{{name}} has been successfully added.',
  customer_successfully_updated: '{{name}} has been successfully updated.',
  customer_successfully_uploaded: '{{name}} has been successfully uploaded.',
  date: 'Date {{date}}',
  date_changed_on: 'Changed on {{date}}',
  date_range_invalid: 'Date range {{field_start}} and {{field_end}} is not valid',
  delete_entity_warning: 'Do you want to delete {{entity}}?',
  delete_payment_from_invoice: 'Remove {{payment_type}} from {{shipment_number}}?',
  delete_payment_warning: 'This fee will be removed from the shipment {{shipment_number}}',
  delete_shipment_from_invoice: 'Remove {{shipment_number}} from this Invoice?',
  delete_this_bank_account: 'Delete this bank account?',
  delivery_approved: '{{percentage}}% Delivery Approved',
  detail_of: 'Detail {{object}}',
  detail_of_shipment: 'Detail {{shipment_number}}',
  do_approved_success: 'DO Successfully Approved',
  do_assigned_success: 'DO Successfully Assigned',
  do_location_change_driver_instruction: 'Immediately call your driver to inform the PIC contact {{location}} and this unloading location change.',
  document_deleted_successfully: 'Document successfully deleted',
  document_uploaded_successfully: 'Document successfully uploaded',
  dof2_eligibility_message_map: {
    [DOF2_ELIGIBILITY_STATUS.SHIPMENT_NOT_COMPLETED]: 'Shipment not completed',
    [DOF2_ELIGIBILITY_STATUS.DO_IS_ALREADY_IN_ANOTHER_DOF2_REQUEST]: 'Shipment already listed in {{dof_2_number}}',
    [DOF2_ELIGIBILITY_STATUS.DO_IS_ALREADY_IN_ANOTHER_INVOICE]: 'Shipment already listed in {{do_invoice_number}}',
    [DOF2_ELIGIBILITY_STATUS.DOF_NOT_DISBURSED_YET]: 'First disbursement of DOF not yet completed',
    [DOF2_ELIGIBILITY_STATUS.UNDEFINED_BACKEND_CODE]: 'UNDEFINED_BACKEND_CODE',
    [DOF2_ELIGIBILITY_STATUS.DOF_REQUEST_NOT_FOUND]: 'Shipment not registered in DOF',
  },
  dof2_invoice_keyword_not_found: 'Invoice {{keyword}} not found in Nexus for Transporters',
  dof2_request_review_successfully_submitted: 'Review result for {{dof2_number}} has been successfully sent to {{applicant}}',
  dof2_request_successfully_disbursed: 'Disbursement details for {{dof2_number}} have been successfully sent to {{applicant}}',
  dof2_request_successfully_submitted: 'Request for {{dof2_number}} has been successfully submitted.',
  dof2_third_disbursement_proceed: 'Third disbursement for {{dof2Number}} is being processed',
  dof2_total_first_disbursement_formula: '{{firstDisbursementPercentage}}% Shipment - Kargo Fee - VAT',
  dof2_total_second_disbursement_formula: '{{secondDisbursementPercentage}}% Shipment + {{additionalFeePercentage}}% Additional Fee',
  dof2_total_third_disbursement_formula: '{{thirdInovicePercentage}}% Invoice + VAT {{pphSign}} WHT {{adjustmentSign}} Adjustment {{kargoFeeAdjustmentSign}} Kargo Fee Adjustment - Late Fee',
  dof_first_disbursement_summary: '{{percentage}} of {{total}} Shipment',
  dof_request_review_successfully_submitted: 'Review result for {{dof_number}} has been successfully sent to {{applicant}}',
  dof_request_successfully_disbursed: 'Disbursement details for {{dof_number}} have been successfully sent to {{applicant}}',
  dof_request_successfully_submitted: 'Request for {{dof_number}} has been successfully submitted.',
  dof_third_disbursement_formula: '{{invoicePercentage}}% invoice + VAT - WHT + Adjustment {{adjustmentFeeSign}} Kargo Fee Adjustment - Late Fee',
  done_review_with_progress: '{{progress}}/{{total}} Review Completed',
  driver_deactivation_confirmation: 'Deactivate {{name}}?',
  driver_deactivation_warning_message: 'Are you sure?',
  driver_successfully_created: '{{name}} has been successfully added.',
  driver_successfully_updated: '{{name}} has been successfully updated.',
  driver_successfully_uploaded: '{{name}} has been successfully uploaded',
  email_revised_invoice: 'Email Revised Invoice {{sequence}}',
  file_error: {
    1: 'Company not found',
    9: 'Warehouse not found',
    13: 'Address location not valid',
    45: 'You already have a Location Fee with the same Fee Type, Shipment Type, Truck Type, and Truck Subtype',
    46: 'You already have a Location Fee with the same Fee Type, Shipment Type, Truck Type, and Truck Subtype',
    55: 'Invalid vehicle type ID',
    60: 'Address ({{column_name}}) not found',
    61: 'Address ({{column_name}}) located outside Indonesia',
    62: 'Invalid loading date',
    63: 'Invalid unloading date',
    64: 'Fleet not found',
    65: 'Invalid item type',
    66: 'Customer not found',
    67: 'Driver not found',
    96: 'BL not found',
    97: 'Invalid container status',
    98: 'Invalid container type',
    99: 'Warehouse not found',
    474: 'Invalid CSV file header',
    475: 'Column {{column_name}} not valid',
    476: 'Invalid CSV file',
    477: 'CSV file header is empty',
    478: 'Unknown error',
    479: 'Invalid exemption sequence found in one line',
    480: 'Invalid column length for one line',
    481: 'Line has deviated quotes',
    483: 'Vehicle License Number is already registered with the company',
    2501: 'Location ID not found',
    2502: 'Invalid fee type',
    2503: 'Invalid shipment type',
    2504: 'Invalid max amount',
    2505: 'Invalid reimbursement percentage',
    2506: 'Invalid reimbursement requirement',
    2507: 'Truck type/subtype not found',
    2508: 'Invalid reimbursement type',
  },
  fill_disbursement_details_dof2_request: 'Fill Third Disbursement Details {{request_name}}',
  fill_disbursement_details_dof_request: 'Fill Disbursement Details {{request_name}}',
  fill_disbursement_details_podf_request: 'Fill Disbursement Details {{request_name}}',
  fill_first_disbursement_details_podf_request: 'Fill First Disbursement Details {{request_name}}',
  fill_in_the_truck_details: 'Fill in the Truck Details',
  first_disbursement_calculation: '{{shipment_fee_percentage}}% from Shipment - Kargo Fee - VAT',
  form_dialog: {
    fill_in_this_CSV_file_to_upload_a_entity: 'Fill in this CSV file to upload {{entity}}.',
    upload_entity_from_csv_file: 'Upload {{entity}} from CSV file',
  },
  form_error_message: {
    choose_category: 'Select {{field}}',
    document_is_required: 'This document has to be uploaded.',
    invalid: '{{field}} is not valid.',
    invalid_format: 'Please input valid {{field}}',
    mandatory: 'You must fill in {{field}}',
    max_length: 'Maximum length of {{field}} is {{max}}',
    max_value: '{{field}} must be less than {{max}}',
    min_length: 'Minimum length of {{field}} is {{min}}',
    min_value: 'Minimum {{field}} is {{min}}',
    need_to_be_filled: '{{field}} must be filled.',
    percentage_length: 'Total Percentage must be 100%',
    required: 'Please input {{field}}',
    strong_password_rule: '{{field}} must contain at least one uppercase letter, lowercase letter, number, and special character',
  },
  general_activation_success_message: '{{name}} successfully activated',
  general_add: 'Add {{name}}',
  general_againts: 'Against {{entity}}',
  general_change_details: 'Change {{name}} Details',
  general_choose: 'Choose {{name}}',
  general_confirmation: 'Confirm {{name}}',
  general_create: 'Create {{name}}',
  general_days: '{{days}} days',
  general_deactivation_confirmation: 'Deactivate {{name}}?',
  general_deactivation_success_message: '{{name}} successfully deactivated',
  general_deactivation_warning_message: 'Are you sure you want to deactivate {{name}}',
  general_edit: 'Edit {{name}}',
  general_edit_confirmation: 'Edit {{name}}\'s Shipper Status',
  general_edit_detail: 'Edit {{name}} Details',
  general_new_item: 'New {{name}}',
  general_removal_confirmation: 'Delete {{name}}?',
  general_removal_success_message: '{{name}} successfully deleted',
  general_removal_warning_message: 'Are you sure you want to delete {{name}}?',
  general_remove: 'Remove {{name}}',
  general_request: '{{name}} Request',
  general_save: 'Save {{name}}',
  general_search: 'Search {{name}}',
  general_search_placeholder: 'Search {{fields}}',
  general_see: 'See {{object}}',
  general_shipper: '{{name}} Enterprise Shipper',
  general_submit_success_message: '{{name}} successfully submitted.',
  general_summary: '{{name}} Summary',
  general_upload_success_message: '{{name}} successfully uploaded',
  generic_failed_uploaded: '{{name}} failed to upload',
  generic_successfully_uploaded: '{{name}} successfully uploaded',
  generic_text_input_placeholder: 'Type {{field}}',
  generic_date: 'Date {{name}}',
  generic_date_column: 'Date {{name}}',
  hours: '{{field}} Hours',
  initial_kargo_fee: 'Initial Kargo Fee ({{percentage}}%)',
  initial_vat_kargo_fee: 'Initial VAT ({{percentage}}%)',
  invoice_customer_code_delete_question: 'Delete customer code {{customerName}}?',
  invoice_date_is: 'Invoice Date {{date}}',
  invoice_format_display_translation: {
    [INVOICE_FORMAT_DEFAULT['/']]: '/',
    [INVOICE_FORMAT_DEFAULT['-']]: '-',
    [INVOICE_FORMAT_DEFAULT[':']]: ':',
    [INVOICE_FORMAT_DEFAULT.MONTH_CODE]: 'Month ({{format}})',
    [INVOICE_FORMAT_DEFAULT.YEAR_CODE]: 'Year ({{format}})',
    [INVOICE_FORMAT_DEFAULT.CUSTOMER_CODE]: 'Customer Code',
    [INVOICE_FORMAT_DEFAULT.INVOICE_CODE]: 'Invoice Code ({{format}} Number)',
    [INVOICE_FORMAT_DEFAULT.COMPANY_CODE]: 'Company Code',
    [MONTH_INVOICE_FORMAT.NUMBER]: 'Number',
    [MONTH_INVOICE_FORMAT.ROMAN]: 'Roman',
    [MONTH_INVOICE_FORMAT.LETTER]: 'Letter',
    [YEAR_INVOICE_FORMAT.COMPLETE]: 'Complete',
    [YEAR_INVOICE_FORMAT.LAST_TWO_NUMBER]: 'Last two digits',
  },
  invoice_percentage: '{{percentage}}% Invoice',
  invoice_sent_successfully: 'Invoice {{invoice_number}} successfully sent.',
  invoice_subject_template: 'Proforma Invoice No. {{invoice_number}} from {{publisher}}',
  invoice_successfully_added: '{{invoice_number}} successfully added',
  invoice_value: '{{value}} Against Invoice Value',
  invoicing_without_tax_applied: 'Continue to invoice this customer without tax details?',
  invoicing_without_tax_applied_desc: 'This invoice does not include tax deductions because you have not added payment and tax details for {{name}}. Do you want to proceed?',
  km: '{{km}} Km',
  KTP_uploaded_successfully: 'KTP successfully uploaded',
  last_update_at: 'Last update {{datetime}}',
  leave_page_warning_msg: 'Any fees and details in this proforma invoice will be lost.',
  leave_this_page_title: 'Leave this page?',
  load_at: 'Load {{date}}',
  load_time: 'Load {{datetime}}',
  location_count: '{{count}} Locations',
  lost_fee_warning_msg: 'This fee will be lost.',
  mark_as_payment_received: 'Mark Payment Received {{title}}',
  mileage_filename: 'Total Mileage Details - {{plateNumber}} - {{from}} - {{to}}',
  multi_shipment_activity_map: {
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Arrive at drop-off location {{shipmentPoint}}',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'Arrive at pick-up location {{shipmentPoint}}',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Finished loading process {{shipmentPoint}}',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Finished unloading process {{shipmentPoint}}',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'On the way to drop-off location {{shipmentPoint}}',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'On the way to pick-up location {{shipmentPoint}}',
    [SHIPMENT_STATUSES.START_LOADING]: 'Start loading process {{shipmentPoint}}',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Start unloading process {{shipmentPoint}}',
  },
  not_listed: 'Not Listed',
  not_registered: 'Not Registered',
  NPWP_uploaded_successfully: 'NPWP successfully uploaded',
  number_of_seconds: '{{seconds}} seconds',
  pagination_indicator: 'Page {{page}} of {{total}}',
  payment_type_ppn: 'VAT {{ppn_percentage}}%',
  percentage_additional_fee: '{{percentage}}% Additional Fee',
  percentage_additional_fees: '{{percentage}}% Additional Fee',
  percentage_approved_shipment: '{{percentage}}% Approved Shipment',
  percentage_from_kargo_fee: '{{percentage}}% from Kargo Fee',
  percentage_kargo_fee: '{{percentage}}% Kargo Fee',
  percentage_of_x: '{{percentage}}% Against {{x}}',
  percentage_vat_kargo_fee: '{{percentage}}% VAT',
  pic_sequence: 'PIC {{sequence}}',
  pod_from_source: 'POD from {{source}}',
  podf_invoice_keyword_not_found: 'Invoice {{keyword}} not found in Nexus for Transporters',
  podf_request_review_successfully_submitted: 'Review result for {{podf_number}} has been successfully sent to {{applicant}}',
  podf_request_successfully_disbursed: 'Disbursement details for {{podf_number}} have been successfully sent to {{applicant}}',
  podf_request_successfully_submitted: 'Request for {{podf_number}} has been successfully submitted.',
  podf_second_disbursement_formula: '{{inovicePercentage}}% Invoice {{adjustmentSign}} Adjustment {{kargoFeeAdjustmentSign}} Kargo Fee Adjustment - Late Fee',
  podf_second_disbursement_proceed: 'Second disbursement for {{podfNumber}} is being processed',
  pph15_percentage: 'WHT 15 {{percentage}}%',
  pph21_percentage: 'WHT 21 {{percentage}}%',
  pph23_percentage: 'WHT 23 {{percentage}}%',
  pph_percentage: {
    [WHT_SELECTION_TYPE.WHT15]: 'WHT 15 {{percentage}}%',
    [WHT_SELECTION_TYPE.WHT21]: 'WHT 21 {{percentage}}%',
    [WHT_SELECTION_TYPE.WHT23]: 'WHT 23 {{percentage}}%',
  },
  preview_invoice: 'Preview Invoice {{invoiceNumber}}',
  pricing_sceheme: {
    cycle_month: 'Month {{currentCycle}} of {{totalCycle}}',
    do_details: '{{createdDo}} DO created from {{totalDo}} Total DO Quota',
    remaining_quota: `{{remainingQuota}} DO Remaining`,
  },
  rejection_confirmation: 'Rejection Confirmation {{doNumber}}?',
  reimbursement_type_map: {
    [REIMBURSEMENT_TYPE.KG]: '{{shipmentType}}-Kg',
    [REIMBURSEMENT_TYPE.OTHER]: 'Others',
    [REIMBURSEMENT_TYPE.ROUTE]: '{{shipmentType}}',
    [REIMBURSEMENT_TYPE.UNIT]: '{{shipmentType}}-Unit',
  },
  resend_otp_countdown_message: 'Resend verification code in {{countdown}}',
  review_entity: 'Review {{entity}}',
  review_request_submission: 'Review Submission {{request_name}}',
  revise_invoice: 'Invoice Revision {{sequence}}',
  shipment_point_multi: 'Point {{pointName}}',
  shipment_point_multi_map: {
    [SHIPMENT_POINT_TYPES.PICKUP]: 'Pickup {{pointName}}',
    [SHIPMENT_POINT_TYPES.DROPOFF]: 'Dropoff {{pointName}}',
    [SHIPMENT_POINT_TYPES.PICKUP_DROPOFF]: 'Dropoff + Pickup {{pointName}}',
  },
  shipment_point_schedule: 'Schedule {{pointType}}: {{date}} ',
  shipment_status_location_changed: 'Location {{point}} changed from <strong>{{fromLocation}}</strong> to <strong>{{toLocation}}</strong> by {{actor}}. ',
  shipment_status_driver_reassignment: '{{updatedItems}} changed by {{actor}}',
  shipment_status_inactive_reason: 'Reason: {{inactiveReason}}',
  shipment_status_location_reason: 'Reason: {{changeReason}} {{changeNotes}}',
  shipment_status_manual_update_log: 'Status changed to <strong>{{updated_status}}</strong> by {{actor}} {{viaQrCode}}',
  shipment_status_point_changed: '{{point}} changed from <strong>{{fromLocation}}</strong> to <strong>{{toLocation}}</strong>',
  shipment_status_revert_note: 'Status automatically reverted to Heading to {{point}} Location.',
  shipment_status_tracker_map: {
    [SHIPMENT_STATUSES.ACTIVATE_VEHICLE_ALERT]: 'Fleet condition {{licensePlate}} changed to <strong>Problematic</strong> by {{actor}}',
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Arrive at drop-off location',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'Arrive at pick-up location',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Truck & driver selected',
    [SHIPMENT_STATUSES.BUMP_DOWN_ACTIVE_SHIPMENT]: 'Status changed to "Queued" by system'
      + ' due to another shipment ({{doNumber}}) assigned to driver with Loading Schedule {{loadingDate}}',
    [SHIPMENT_STATUSES.BUMP_UP_QUEUED_SHIPMENT]: 'Status changed back to "Active" by system'
      + ' because another shipment ({{doNumber}}) has completed and no more earlier queued shipments',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Cancelled by Customer, reason: {{inactive_reason}}',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Cancelled by Vendor',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Confirmation Time Expired',
    [SHIPMENT_STATUSES.DEACTIVATE_VEHICLE_ALERT]: 'Fleet condition {{licensePlate}} changed back to <strong>Normal</strong> by {{actor}}',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Finished loading',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Finished unloading',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'On the way to pick-up location',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'On the way to drop-off location',
    [SHIPMENT_STATUSES.PLANNED]: 'Truck Not Selected',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Customer Reassigned',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Not Fulfilled',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment completed',
    [SHIPMENT_STATUSES.START_LOADING]: 'Start loading',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Start unloading',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Not Fulfilled',
    [SHIPMENT_STATUSES.UPCOMING]: 'Shipment created by {{actor}}',
    change_driver: 'License Plate and Driver changed by Yoghi',
  },
  shipment_template_save_as_type_map: {
    [SHIPMENT_TEMPLATE_SAVE_TYPE.CREATE]: 'Save as New Template',
    [SHIPMENT_TEMPLATE_SAVE_TYPE.UPDATE]: 'Update Current Template',
  },
  shipping_offer: 'Shipping Offer {{doNumber}}',
  SPPKP_uploaded_successfully: 'SPPKP successfully uploaded',
  slot_number: 'Slot {{value}}',
  submit_truck_details: 'Submit Truck Details',
  submitted_at: 'Submitted on {{timestamp}}',
  submitted_on: 'Submitted {{date}}',
  template: 'Template {{name}}',
  template_name: 'Template: {{templateName}}',
  truck_availability_file_name: 'Fleet Availability - {{truckType}} - {{fromDate}} - {{toDate}}',
  total_cost_is: 'Total Cost {{cost}}',
  total_first_disbursement_formula_with_percentage: '{{percentage}} of First Disbursement - Kargo Fee - VAT',
  total_second_disbursement_formula_with_percentage: '{{percentage}} Shipment - First Disbursement - Additional Fee',
  truck_and_driver_details: 'Truck And Driver Details',
  truck_deactivation_warning_message: 'Are you sure you want to deactivate {{name}}',
  update_additional_fee: 'Update {{payment_type}}',
  update_additional_fee_for_shipment: 'Update Additional Fee for {{shipment_number}}',
  updated_at: 'Updated on {{updatedAt}}',
  upload_pod_for_shipment: 'Upload POD for {{shipment_number}}',
  upload_receipt_with_maximum_size: 'Upload receipt with a maximum size of {{max_size}}.',
  upload_the_KTP_with_a_maximum_size: 'Upload the KTP with a maximum size of {{size}}.',
  upload_the_NPWP_with_a_maximum_size: 'Upload the NPWP with a maximum size of {{size}}.',
  upload_the_SPPKP_with_a_maximum_size: 'Upload the SPPKP with a maximum size of {{size}}.',
  uploaded_file_medium_subtitle: 'Uploaded on {{datetime}} from {{source}}',
  uploaded_file_short_subtitle: 'Uploaded on {{datetime}}',
  user_deactivation_confirmation: 'Deactivate {{name}}?',
  user_deactivation_success_message: '{{name}} successfully deactivated',
  user_deactivation_warning_message: '{{name}} will not be able to access Nexus for Transporters, but their information will still be saved. You cannot reactivate them.',
  user_successfully_created: '{{name}} has been successfully added.',
  user_successfully_updated: '{{name}} has been successfully updated.',
  utilization_tab: {
    warehouse_label: 'Location {{number}}',
    utilization_details_filename: 'Utilization Details - {{licensePlate}} - {{date}}',
    utilization_search_result_filename: 'Utilization - {{date}}',
  },
  vehicle_alert_log_reason: 'Description: {{reason}}',
  vehicle_alert_successfully_activated: '{{name}} vehicle alert successfully activated.',
  vehicle_alert_successfully_deactivated: '{{name}} vehicle alert successfully deactivated.',
  vehicle_successfully_created: '{{license_plate}} has been successfully added.',
  vehicle_count: '{{count}} Fleet',
  vehicle_successfully_updated: '{{license_plate}} has been successfully updated.',
  vehicle_successfully_uploaded: '{{license_plate}} has been successfully uploaded',
  warehouse_successfully_created: '{{name}} has been successfully added.',
  warehouse_successfully_updated: '{{name}} has been successfully updated.',
  warehouse_successfully_uploaded: '{{name}} has been successfully uploaded',
  x_confirmation_warning: 'Do you want to confirm {{x}} Shipment?',
  x_container_selected: '{{total_selected}} containers selected',
  x_DOF: '{{x}} DOF',
  x_invoice: '{{x}} Invoice',
  x_invoices: '{{x}} Invoices',
  x_out_of_y_shipments: '{{x}} out of {{y}} Shipments',
  x_shipment_from_y_dof_selected: '{{x}} Shipment from {{y}} DOF Selected',
  x_shipment_selected: '{{total_selected}} shipment from {{shipper}} selected',
  x_shipment_selected_short: '{{x}} Shipments Selected',
  x_shipments_selected_mobile: 'Continue ({{x}} DO)',
  x_shipments: '{{x}} Shipments',
  x_shipments_cant_be_disbursed_yet: '{{x}} shipments cannot be disbursed yet',
  x_thing_for_y: '{{x}} for {{y}}',
  input_x: 'Enter {{x}}',
  blocked_button_min_limit_amount: 'Minimum Bill Amount {{amount}}',
};
