import React, { lazy } from 'react';
import {
  Redirect,
  Switch,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PrivateRoute } from '~/Components/Route';

// New File System
const PerformanceReport = lazy(() => import(/* webpackChunkName: "PerformanceReportPage" */
  '~/Modules/Analytics/Pages/PerformanceReport/PerformanceReport'
));
const ShipmentAcceptanceRateReport = lazy(() => import(/* webpackChunkName: "PerformanceReportPage" */
  '~/Modules/Analytics/Pages/ShipmentAcceptanceRateReport/ShipmentAcceptanceRateReport'
));
const ShipmentTimeAccuracyReport = lazy(() => import(/* webpackChunkName: "ShipmentTimeAccuracyReport" */
  '~/Modules/Analytics/Pages/ShipmentTimeAccuracy/ShipmentTimeAccuracy'
));
const VehicleMileageReport = lazy(() => import(/* webpackChunkName: "MileageReport" */
  '~/Modules/Analytics/Pages/MileageReport/MileageReport'
));
const UtilizationReport = lazy(() => import(/* webpackChunkName: "UtilizationReport" */
  '~/Modules/Analytics/Pages/UtilizationReport/UtilizationReport'
));

const AnalyticsRoutes = () => {
  const { t } = useTranslation();

  const reportPageNavigation = [
    {
      path: '/report/performance-report',
      title: t('common:performance_report'),
    },
    {
      path: '/report/shipment-acceptance-report',
      title: t('common:penerimaan_do'),
    },
    {
      path: '/report/shipment-time-accuracy-report',
      title: t('common:ketepatan_waktu'),
    },
    {
      path: '/report/mileage-report',
      title: t('common:mileage'),
    },
    {
      path: '/report/utilization-report',
      title: 'Utilisasi',
    },
  ].filter(Boolean);

  return (
    <Switch>
      <PrivateRoute
        exact={true}
        path="/report/performance-report"
        tabNavigation={reportPageNavigation}
        title={t('common:report')}
      >
        <PerformanceReport />
      </PrivateRoute>
      <PrivateRoute
        exact={true}
        path="/report/shipment-acceptance-report"
        tabNavigation={reportPageNavigation}
        title={t('common:report')}
      >
        <ShipmentAcceptanceRateReport />
      </PrivateRoute>
      <PrivateRoute
        exact={true}
        path="/report/shipment-time-accuracy-report"
        tabNavigation={reportPageNavigation}
        title={t('common:report')}
      >
        <ShipmentTimeAccuracyReport />
      </PrivateRoute>
      <PrivateRoute
        exact={true}
        path="/report/mileage-report"
        tabNavigation={reportPageNavigation}
        title={t('common:report')}
      >
        <VehicleMileageReport />
      </PrivateRoute>
      <PrivateRoute
        exact={true}
        path="/report/utilization-report"
        tabNavigation={reportPageNavigation}
        title={t('common:report')}
      >
        <UtilizationReport />
      </PrivateRoute>
      <Redirect
        exact={true}
        from="/report"
        to="/report/performance-report"
      />
    </Switch>
  );
};

export default AnalyticsRoutes;
