import './local-env';

import React from 'react';
import ReactDOM from 'react-dom';
import { APP_CONTEXT_STORAGE_KEY } from '~/Configurations/constants';
import App from './App';

import '@kargotech/tms-common/Assets/Fonts/Roboto/roboto.css';
import '@kargotech/tms-ui/_settings/app.css';
import './Services/i18n';
import './Services/sentry';
import checkTheLorryTransporter from './Utilities/checkTheLorryTransporter';

const isTheLorryTransporter = checkTheLorryTransporter();
if (isTheLorryTransporter) {
  document.title = 'TheLorry Partner B2B';
  document.querySelector('#favicon').href = '/thelorry-favicon.png';
}

localStorage.setItem(APP_CONTEXT_STORAGE_KEY, 'TRANSPORTER');

ReactDOM.render(
  /**
   * Currently there is a problem with antd Button that it produces warning on console's browser.
   * Track github issue here: https://github.com/ant-design/ant-design/issues/22493.
   * We will remove React.StrictMode temporaryly until it got resolved.
   */
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <App />,
  document.getElementById('root'),
);
