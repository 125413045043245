/* eslint-disable max-len */
export default {
  bid_faq: {
    dialog_title: 'How Does Bidding Work?',
    content_list: {
      1: {
        question: 'How does Kargo choose bids?',
        answer: 'The Kargo team will select a bid within 3 hours from the vendor with the cheapest bid and the best delivery history.',
      },
      2: {
        question: 'What does the bid include?',
        answer: 'The bid should include packaging costs, operational fees, driver fees, multidrop, and parking fees.',
      },
      3: {
        question: 'Why can’t I bid above a certain amount?',
        answer: 'The price limit is set to speed up the bid selection process so that Kargo only chooses based on the best bid.',
      },
      4: {
        question: 'How can I win?',
        answer: 'Offer a fair price and ensure timely deliveries to build a good history.',
      },
      5: {
        question: 'Why was my bid rejected?',
        answer: 'There may be another vendor with a better bid or delivery history.',
      },
      6: {
        question: 'How many bids can I submit?',
        answer: 'You can submit up to 10 bids per truck unit. Trusted Vendors get 2 additional load attempts now.',
      },
    },
  },
  bid_success: 'You have successfully placed a bid for this load',
  bid_tnc: {
    dialog_title: 'Bidding Terms and Conditions',
    content_list: {
      1: {
        title: 'Multidrop / Multipickup Charges',
        description: [
          'If there are additional loading or unloading points outside the shipping details listed in the transporter app, an additional transporter fee will apply:',
          'Tronton: Rp 150,000 per point',
          'Fuso: Rp 150,000 per point',
          'CDD: Rp 100,000 per point',
          'CDE: Rp 100,000 per point',
          'Pickup: Rp 50,000 per point',
          'Blind Van: Rp 50,000 per point',
        ],
      },
      2: {
        title: 'Overnight Procedure',
        description: [
          'Overnight charges can only be paid if the Driver Partner uses the Kargo Partner app during the trip. The app must be used correctly to accurately record the overnight time.',
          'Also ensure that the Driver Partner receives all the necessary overnight documents provided by the warehouse, such as: Overnight Report, Overnight Form, Entry/Exit Stamp on the Waybill.',
          'Overnight calculation procedures can be viewed in the Delivery Order in the Kargo Nexus for Transporters app. The calculation of Overnight (Weekend and National Holidays) will refer to the app.',
          'If the Transporter’s Note states “overnight will be paid directly at the Location”, then the Overnight charge will be received in cash directly by the Driver if it has been more than 24 hours.',
        ],
      },
      3: {
        title: 'Additional Charges',
        description: [
          'TKBM, Overnight, and Cancellation fees follow the job details that appear in the App.',
          'Ensure that the bid price submitted by the Transporter Partner includes the amount that can and cannot be reimbursed (as per the notes on each DO).',
          'Cancellation fees will not apply if the process does not proceed due to an error. Transporter Partner (truck does not meet requirements, etc.).',
          'If the Transporter’s Note states “TKBM will be paid directly at the Location”, then the TKBM fee will be paid directly by the Driver in cash to the Shipper (if any).',
          'If the Shipper requires an assistant/helper, the cost is already included in the Travel Fee.',
        ],
      },
      4: {
        title: 'Goods Rejection',
        description: [
          'Document (photo/video) the unit situation after loading & unloading (loading/unloading process, product arrangement, unit seal, etc.) so that if a claim occurs, these documents can be submitted for reconsideration by the Shipper.',
          'It must include a Report displaying the correct quantity and description and be fully signed by the Driver Partner. Transporters who are charged insurance fees must provide a Report to avoid full claims against the Transporter.',
          'If the Transporter’s Note states “the Driver will confirm directly with the Shipper”, then the Claim will be handled directly between the Driver/Transporter and the Shipper. Any additional costs will be settled directly with the Shipper.',
        ],
      },
      5: {
        title: 'Incentive Terms',
        description: [
          'Vendors receive incentives from PT Panthera Biru Indonesia <strong>(Panthera)</strong> according to the following terms.',
          'Vendors receive incentives only from jobs marked <strong>“incentive”</strong> and have completed them successfully.',
          'The incentive amount is calculated based on the price displayed according to the specified percentage.',
          'The incentive payment process begins after Panthera receives proof of loading (<i>Proof of Loading</i>)/POL.',
          'The incentive payment time is adjusted according to Panthera’s decision.',
          'The above terms only apply if the Vendor has not or does not sign an agreement with Panthera.',
          'If there is already an agreement between the Vendor and Panthera, then the terms in that agreement apply.',
        ],
      },
    },
  },
  contract_price: 'Contract Price',
  take_load: 'Take Load',
  shipment_checklists: 'Shipment Checklists',
  shipment_checklists_desc: 'Check the conditions you can meet for this shipment. Don’t worry if there are conditions you can’t meet, you still have a chance to get this load.',
};
