import React, { lazy, useContext } from 'react';
import {
  Redirect,
  Switch,
} from 'react-router-dom';
import { get } from 'lodash-es';
import { Grid } from '@kargotech/tms-ui/components';
import { useFlag } from '@unleash/proxy-client-react';
import { useTranslation } from 'react-i18next';
import { PrivateRoute } from '~/Components/Route';
import { ProfileContext } from '~/Contexts/ProfileProvider';
import { NftModulesContext } from '~/Contexts/NftModulesProvider';
import { AVAILABLE_NFT_MODULES } from '../constants';
import useTheLorryIntegration from '~/Hooks/useTheLorryIntegration';

const { useBreakpoint } = Grid;

// Invoice Submodule
const PaymentShipmentListPage = lazy(() => import(/* webpackChunkName: "PaymentShipment" */
  '~/Modules/Payment/Invoice/Pages/PaymentShipmentListPage/PaymentShipmentListPage'
));
const PaymentShipmentListPageMobile = lazy(() => import(/* webpackChunkName: "PaymentShipment" */
  '~/Modules/Payment/Invoice/Pages/PaymentShipmentListPage/PaymentShipmentListPage.mobile'
));
const PaymentShipmentCreate = lazy(() => import(/* webpackChunkName: "PaymentShipment" */
  '~/Modules/Payment/Invoice/Pages/InvoiceCreatePage/InvoiceCreatePage'
));
const InvoiceCreate = lazy(() => import(/* webpackChunkName: "InvoiceCreate" */
  '~/Modules/Payment/Invoice/Pages/InvoiceGenericCreationPage/InvoiceGenericCreationPage'
));
const InvoiceTemplatePreview = lazy(() => import(/* webpackChunkName: "InvoiceTemplatePreview" */
  '~/Modules/Payment/Invoice/Pages/TemplatePreviewPage/TemplatePreviewPage'
));
const InvoiceListPage = lazy(() => import(/* webpackChunkName: "PaymentInvoiceList" */
  '~/Modules/Payment/Invoice/Pages/InvoiceListPage/InvoiceListPage'
));
const InvoiceListPageMobile = lazy(() => import(/* webpackChunkName: "PaymentInvoiceListMobile" */
  '~/Modules/Payment/Invoice/Pages/InvoiceListPage/InvoiceListPage.mobile'
));
const InvoiceSummary = lazy(() => import(/* webpackChunkName: "InvoiceSummary" */
  '~/Modules/Payment/Invoice/Pages/InvoiceSummaryPage/InvoiceSummaryPage'
));
const InvoiceCreateTemplateProvider = lazy(() => import(/* webpackChunkName: "InvoiceCreateTemplateProvider" */
  '~/Modules/Payment/Invoice/Context/InvoiceTemplateFormProvider'
));
const InvoiceFormProvider = lazy(() => import(/* webpackChunkName: "InvoiceFormProvider" */
  '~/Modules/Payment/Invoice/Context/InvoiceFormProvider'
));
const InvoiceTemplateListPage = lazy(() => import(/* webpackChunkname: "InvoiceTemplateListPage" */
  '~/Modules/Payment/Invoice/Pages/InvoiceTemplateListPage/InvoiceTemplateListPage'
));
const InvoiceTemplateCreationPage = lazy(() => import(/* webpackChunkName: "InvoiceTemplateCreationPage" */
  '~/Modules/Payment/Invoice/Pages/InvoiceTemplateCreationPage/InvoiceTemplateCreationPage'
));

// Swift Payment Submodule
const AgingListPage = lazy(() => import(/* webpackChunkName: "AgingListPage" */
  '~/Modules/Payment/SwiftPayment/Pages/AgingListPage'
));
const SwiftPaymentDetailPage = lazy(() => import(/* webpackChunkName: "SwiftPaymentDetailPage" */
  '~/Modules/Payment/SwiftPayment/Pages/SwiftPaymentDetailPage/SwiftPaymentDetailPage'
));
const SwiftPaymentProjectForm = lazy(() => import(/* webpackChunkName: "SwiftPaymentProjectForm" */
  '~/Modules/Payment/SwiftPayment/Forms/SwiftPaymentProjectForm/SwiftPaymentProjectForm'
));
const SwiftPaymentProjectsList = lazy(() => import(/* webpackChunkName: "SwiftPaymentProjectsList" */
  '~/Modules/Payment/SwiftPayment/Pages/SwiftPaymentProjectsList/SwiftPaymentProjectsList'
));
const SwiftPaymentListPage = lazy(() => import(/* webpackChunkName: "SwiftPaymentListPage" */
  '~/Pages/Payment/SwiftPaymentListPage'
));
const SwiftPaymentLandingPage = lazy(() => import(/* webpackChunkName: "SwiftPaymentLandingPage" */
  '~/Pages/Payment/SwiftPaymentListPage/SwiftPaymentLandingPage'
));
const DofRequestItemPage = lazy(() => import(/* webpackChunkName: "DofRequestItemPage" */
  '~/Pages/Payment/DofRequestItemPage'
));
const DofRequestDetailsPage = lazy(() => import(/* webpackChunkName: "DofRequestDetailsPage" */
  '~/Pages/Payment/DofRequestItemPage/DofRequestDetailsPage'
));
const DofSummaryDetailsPage = lazy(() => import(/* webpackChunkName: "DofSummaryDetailsPage" */
  '~/Pages/Payment/DofRequestItemPage/DofSummaryDetailsPage'
));
const Dof2RequestItemPage = lazy(() => import(/* webpackChunkName: "Dof2RequestItemPage" */
  '~/Pages/Payment/Dof2RequestItemPage'
));
const Dof2RequestDetailsPage = lazy(() => import(/* webpackChunkName: "Dof2RequestDetailsPage" */
  '~/Pages/Payment/Dof2RequestItemPage/Dof2RequestDetailsPage'
));
const Dof2SummaryDetailsPage = lazy(() => import(/* webpackChunkName: "Dof2SummaryDetailsPage" */
  '~/Pages/Payment/Dof2RequestItemPage/Dof2SummaryDetailsPage'
));
const PodfSummaryDetailsPage = lazy(() => import(/* webpackChunkName: "PodfSummaryDetailsPage" */
  '~/Pages/Payment/PodfRequestItemPage/PodfSummaryDetailsPage'
));
const PodfRequestItemPage = lazy(() => import(/* webpackChunkName: "PodfRequestItemPage" */
  '~/Pages/Payment/PodfRequestItemPage'
));
const PodfRequestDetailsPage = lazy(() => import(/* webpackChunkName: "PodfRequestDetailsPage" */
  '~/Pages/Payment/PodfRequestItemPage/PodfRequestDetailsPage'
));
const TransporterDocumentListPage = lazy(() => import(/* webpackChunkName: "TransporterDocumentListPage" */
  '~/Modules/Payment/SwiftPayment/Pages/TransporterDocumentListPage/TransporterDocumentListPage'
));

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function PaymentRoutes() {
  const screens = useBreakpoint();
  const { t } = useTranslation();
  const INVOICE_TEMPLATE_CREATION = useFlag('TC-5985_INVOICE-TEMPLATE-CREATION');
  const TRANSPORTER_DOCUMENT_DATA = useFlag('PAYS-853_TRANSPORTER_DOCUMENT_DATA');
  const KC_REVAMP_NFT_INVOICE = useFlag('KC_REVAMP_NFT_INVOICE');
  const {
    profile,
  } = useContext(ProfileContext);
  const {
    [AVAILABLE_NFT_MODULES.INVOICING]: showInvoicing,
  } = useContext(NftModulesContext);
  const { isTheLorryTransporter } = useTheLorryIntegration();

  const showEmptySwiftPayment = !get(profile, 'company.isPodfEligible') && !get(profile, 'company.isDofEligible');

  const invoicingTabs = showInvoicing
    ? [
      {
        path: '/payment/shipment-list',
        title: t('common:shipment'),
      },
      {
        path: '/payment/invoice-list',
        title: t('common:invoice'),
      },
    ] : [];

  const paymentTabNavigationKargo = [
    ...invoicingTabs,
    {
      path: '/payment/swift-payment-list',
      title: t('common:swift_payment'),
      'data-testid': 'tabPembayaranCepat',
    },
    (profile.company.isDofEligible || profile.company.isPodfEligible)
    && {
      path: '/payment/aging',
      title: t('common:aging'),
    },
  ].filter(Boolean);

  const paymentTabNavigation = isTheLorryTransporter
    ? invoicingTabs
    : paymentTabNavigationKargo;

  const paymentProjectTabNavigation = [
    {
      path: '/payment/payment-projects/list',
      title: t('document_collection:project_request'),
    },
    {
      path: '/payment/payment-projects/transporter-documents',
      title: t('document_collection:my_documents'),
    },
  ].filter(Boolean);

  const InvoiceRoutes = [
    <PrivateRoute
      exact={true}
      path="/payment/shipment-list"
      tabNavigation={paymentTabNavigation}
      title={t('common:payment')}
    >
      {
      screens.md ? (<PaymentShipmentListPage />) : (
        <PaymentShipmentListPageMobile />
      )
    }
    </PrivateRoute>,
    <PrivateRoute
      exact={true}
      path="/payment/invoice-create"
    >
      <InvoiceFormProvider>
        {KC_REVAMP_NFT_INVOICE ? (
          <InvoiceCreate />
        ) : (
          <PaymentShipmentCreate />
        )}
      </InvoiceFormProvider>
    </PrivateRoute>,
    <PrivateRoute
      exact={true}
      path="/payment/invoice-template/preview/:templateKsuid"
    >
      <InvoiceFormProvider>
        <InvoiceTemplatePreview />
      </InvoiceFormProvider>
    </PrivateRoute>,
    <PrivateRoute
      exact={true}
      path="/payment/edit-invoice/:doInvoiceKsuid"
    >
      <InvoiceFormProvider>
        {KC_REVAMP_NFT_INVOICE ? (
          <InvoiceCreate />
        ) : (
          <PaymentShipmentCreate />
        )}
      </InvoiceFormProvider>
    </PrivateRoute>,
    <PrivateRoute
      exact={true}
      path="/payment/invoice-list"
      tabNavigation={paymentTabNavigation}
      title={t('common:payment')}
    >
      <InvoiceFormProvider>
        {screens.md ? <InvoiceListPage /> : <InvoiceListPageMobile />}
      </InvoiceFormProvider>
    </PrivateRoute>,
    ...(INVOICE_TEMPLATE_CREATION
      ? [
        <PrivateRoute
          exact={true}
          path="/payment/invoice-template-list"
          title=""
        >
          <InvoiceTemplateListPage />
        </PrivateRoute>,
        <PrivateRoute
          exact={true}
          path="/payment/invoice-template-list/create-invoice-template"
        >
          <InvoiceCreateTemplateProvider>
            <InvoiceTemplateCreationPage />
          </InvoiceCreateTemplateProvider>
        </PrivateRoute>,
      ] : []),
    <PrivateRoute
      exact={true}
      path="/payment/invoice-details/:invoiceKsuid"
    >
      <InvoiceFormProvider>
        <InvoiceSummary />
      </InvoiceFormProvider>
    </PrivateRoute>,
    <Redirect
      exact={true}
      from="/payment"
      to="/payment/shipment-list"
    />,
  ];

  const SwiftPaymentRoutes = [
    <PrivateRoute
      exact={true}
      path="/payment/aging"
      tabNavigation={paymentTabNavigation}
      title={t('common:payment')}
    >
      <AgingListPage />
    </PrivateRoute>,
    <PrivateRoute
      exact={true}
      path="/payment/dof-summary/:dofRequestKsuid"
      skipTabNavigationSetup={true}
    >
      <DofRequestItemPage>
        <DofSummaryDetailsPage />
      </DofRequestItemPage>
    </PrivateRoute>,
    <PrivateRoute
      exact={true}
      path="/payment/dof-details/:dofRequestKsuid"
      skipTabNavigationSetup={true}
    >
      <DofRequestItemPage>
        <DofRequestDetailsPage />
      </DofRequestItemPage>
    </PrivateRoute>,
    <PrivateRoute
      exact={true}
      path="/payment/podf-details/:podfRequestKsuid"
      skipTabNavigationSetup={true}
    >
      <PodfRequestItemPage>
        <PodfRequestDetailsPage />
      </PodfRequestItemPage>
    </PrivateRoute>,
    <PrivateRoute
      exact={true}
      path="/payment/podf-summary/:podfRequestKsuid"
      skipTabNavigationSetup={true}
    >
      <PodfRequestItemPage>
        <PodfSummaryDetailsPage />
      </PodfRequestItemPage>
    </PrivateRoute>,
    <PrivateRoute
      exact={true}
      path="/payment/dof2-details/:dof2RequestKsuid"
      skipTabNavigationSetup={true}
    >
      <Dof2RequestItemPage>
        <Dof2RequestDetailsPage />
      </Dof2RequestItemPage>
    </PrivateRoute>,
    <PrivateRoute
      exact={true}
      path="/payment/dof2-summary/:dof2RequestKsuid"
      skipTabNavigationSetup={true}
    >
      <Dof2RequestItemPage>
        <Dof2SummaryDetailsPage />
      </Dof2RequestItemPage>
    </PrivateRoute>,
  ];

  const PaymentProjectRoutes = [
    <PrivateRoute
      exact={true}
      path="/payment/payment-projects/list"
      subtitle={profile?.company?.name}
      tabNavigation={TRANSPORTER_DOCUMENT_DATA ? paymentProjectTabNavigation : []}
      title={t('document_collection:payment_project_list')}
    >
      <SwiftPaymentProjectsList />
    </PrivateRoute>,
    ...(TRANSPORTER_DOCUMENT_DATA
      ? [
        <PrivateRoute
          exact={true}
          path="/payment/payment-projects/transporter-documents"
          subtitle={profile?.company?.name}
          tabNavigation={TRANSPORTER_DOCUMENT_DATA ? paymentProjectTabNavigation : []}
          title={t('document_collection:my_documents')}
        >
          <TransporterDocumentListPage />
        </PrivateRoute>,
      ] : []),
    <PrivateRoute
      exact={true}
      path="/payment/payment-projects/new"
      tabNavigation={[]}
      title={t('common:fast_payment_request')}
    >
      <SwiftPaymentProjectForm />
    </PrivateRoute>,
    <PrivateRoute
      exact={true}
      path="/payment/payment-projects/details/:projectKsuid"
      subtitle=""
      tabNavigation={[]}
    >
      <SwiftPaymentDetailPage />
    </PrivateRoute>,
    <PrivateRoute
      exact={true}
      path="/payment/swift-payment-list"
      tabNavigation={paymentTabNavigation}
      title={t('common:payment')}
    >
      {showEmptySwiftPayment
        ? <SwiftPaymentLandingPage />
        : <SwiftPaymentListPage />}
    </PrivateRoute>,
  ];

  return (
    <Switch>
      {[
        ...PaymentProjectRoutes,
        ...(showInvoicing
          ? [
            ...InvoiceRoutes,
            ...SwiftPaymentRoutes,
          ]
          : [
            <Redirect
              exact={true}
              from="/payment"
              to="/payment/swift-payment-list"
            />,
          ]),
      ]}
    </Switch>
  );
}
