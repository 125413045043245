/* eslint-disable max-len */
export default {
  bid_faq: {
    dialog_title: 'Bagaimana Cara Kerja Penawaran?',
    content_list: {
      1: {
        question: 'Bagaimana Kargo memilih tawaran?',
        answer: 'Tim Kargo akan memilih tawaran dalam 3 jam dari vendor dengan tawaran termurah dan memiliki riwayat pengiriman terbaik.',
      },
      2: {
        question: 'Penawaran termasuk apa saja?',
        answer: 'Tawaran harus termasuk biaya pengemasan, operasional, uang supir, multidrop, dan parkir.',
      },
      3: {
        question: 'Kenapa saya tidak bisa menawar lebih dari jumlah tertentu?',
        answer: 'Pembatasan harga dilakukan untuk mempercepat proses pemilihan tawaran sehingga Kargo hanya memilih berdasarkan tawaran terbaik.',
      },
      4: {
        question: 'Bagaimana agar saya menang?',
        answer: 'Berikan harga yang adil dan pastikan pengiriman berjalan tepat waktu untuk mendapatkan riwayat yang baik.',
      },
      5: {
        question: 'Kok tawaran saya ditolak?',
        answer: 'Kemungkinan ada vendor lain dengan tawaran atau riwayat pengiriman yang lebih baik.',
      },
      6: {
        question: 'Berapa tawaran yang bisa saya berikan?',
        answer: 'Anda bisa memberikan 10 kali tawaran per satu unit truk. Vendor Terpercaya memiliki tambahan 2 kali ambil muatan sekarang.',
      },
    },
  },
  bid_success: 'Anda berhasil mengajukan tawaran untuk muatan ini',
  bid_tnc: {
    dialog_title: 'Syarat dan Ketentuan Tawaran',
    content_list: {
      1: {
        title: 'Biaya Multidrop / Multipickup',
        description: [
          'Apabila ada tambahan titik muat atau bongkar di luar detail pengiriman yang tercantum di aplikasi transporter, maka terdapat penambahan biaya transporter sebesar:',
          'Tronton: Rp 150.000,- per titik',
          'Fuso: Rp 150.000,- per titik',
          'CDD: Rp 100.000,- per titik',
          'CDE: Rp 100.000,- per titik',
          'Pickup: Rp 50.000,- per titik',
          'Blind Van: Rp 50.000,- per titik',
        ],
      },
      2: {
        title: 'Prosedur Inap',
        description: [
          'Biaya Inap hanya dapat dibayarkan apabila Mitra Pengemudi menggunakan aplikasi Mitra Kargo saat dalam perjalanan. Aplikasi harus dipakai dengan baik dan benar agar waktu inap tercatat dengan baik.',
          'Pastikan juga Mitra Pengemudi mendapatkan seluruh dokumen yang diperlukan inap yang disediakan gudang, seperti: Berita Acara Inap, Form Inap, Stempel jam masuk/keluar di Surat Jalan.',
          'Prosedur perhitungan Inap dapat dilihat di Delivery Order di aplikasi Kargo Nexus for Transporters. Ketentuan perhitungan Inap (Akhir Pekan dan Libur Nasional) akan mengacu ke aplikasi.',
          'Apabila dalam Catatan Transporter terdapat pesan “inap akan dibayarkan langsung di Lokasi”, maka Inap akan diterima secara tunai langsung oleh Pengemudi selama sudah lebih dari 24 jam.',
        ],
      },
      3: {
        title: 'Biaya Tambahan',
        description: [
          'Biaya TKBM, Inap, dan Pembatalan mengikuti detail per pekerjaan yang muncul di Aplikasi.',
          'Pastikan harga yang di-bidding Mitra Transporter termasuk nominal tersebut yang dapat di-reimburse dan yang tidak dapat di-reimburse (sesuai dengan notes per DO).',
          'Biaya pembatalan tidak akan berlaku jika proses tidak jadi muat karena kesalahan. Mitra Transporter (truk tidak sesuai requirement, dsb).',
          'Apabila dalam Catatan Transporter terdapat pesan “TKBM akan dibayarkan langsung di Lokasi”, maka biaya TKBM akan dibayarkan langsung oleh Pengemudi secara tunai ke Shipper (jika ada).',
          'Jika Shipper membutuhkan kenek/helper, biaya sudah termasuk dalam Biaya Perjalanan.',
        ],
      },
      4: {
        title: 'Penolakan Barang',
        description: [
          'Dokumentasikan (foto/video) situasi unit setelah muat & bongkar (proses muat/bongkar, susunan produk, segel unit, dll) agar apabila terjadi klaim, dokumen tersebut dapat diajukan untuk dipertimbangkan kembali kepada pihak Shipper.',
          'Harus dengan Berita Acara yang menampilkan jumlah dan keterangan yang sesuai dan ditandatangani secara lengkap oleh Mitra Pengemudi. Transporter yang dikenakan biaya asuransi wajib menyediakan Berita Acara untuk menghindari klaim penuh ke Transporter.',
          'Apabila Catatan Transporter tertulis “pihak Pengemudi akan mengkonfirmasi langsung dengan Shipper”, maka Klaim akan diurus langsung antara Pengemudi/Transporter dengan Shipper. Segala bentuk biaya tambahan akan diselesaikan langsung dengan Shipper.',
        ],
      },
      5: {
        title: 'Ketentuan Insentif',
        description: [
          'Vendor mendapat insentif dari PT Panthera Biru Indonesia <strong>(Panthera)</strong> sesuai ketentuan berikut.',
          'Vendor mendapat insentif hanya dari pekerjaan yang memiliki tanda <strong>“insentif”</strong> dan telah menyelesaikannya dengan baik',
          'Jumlah insentif dihitung dari harga yang tertera berdasarkan persentase yang disebutkan',
          'Proses pembayaran insentif dimulai setelah Panthera menerima bukti muat (<i>Proof of Loading</i>)/POL',
          'Waktu pembayaran insentif disesuaikan dengan keputusan Panthera',
          'Ketentuan di atas hanya berlaku jika Vendor belum atau tidak menandatangani kesepakatan dengan Panthera',
          'Jika sudah ada kesepakatan antara Vendor dan Panthera, maka yang berlaku adalah ketentuan dalam kesepakatan tersebut',
        ],
      },
    },
  },
  contract_price: 'Harga Kontrak',
  take_load: 'Ambil Muatan',
  shipment_checklists: 'Kelengkapan Pengiriman',
  shipment_checklists_desc: 'Berikan tanda centang pada ketentuan yang dapat Anda penuhi untuk pengiriman ini. Jangan khawatir jika ada ketentuan yang tidak dapat Anda penuhi, Anda masih berkesempatan untuk mendapatkan muatan ini.',
};
