import React, { useContext, useMemo } from 'react';
import {
  Alert,
  Typography,
} from '@kargotech/tms-ui/components';
import { get } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, WarningFilled } from '@ant-design/icons';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { useFlag } from '@unleash/proxy-client-react';
import { BANNER_STORAGE_KEY, PAYMENT_SCHEME_STATUS } from '~/Configurations/constants';
import ONGOING_SUBSCRIPTION_DETAIL from '~/GraphQL/ApolloService/Queries/ongoingSubscriptionDetail';
import { APOLLO_CLIENTS } from '~/Services/apollo';
import { ProfileContext } from '~/Contexts/ProfileProvider';

const ContractDOExpireReminderBanner = ({ profile }) => {
  const { t } = useTranslation();
  const { getSelectedCompany } = useContext(ProfileContext);
  const QUOTA_LIMIT_AND_DEACTIVATION = useFlag('TC-6721_QUOTA-LIMIT-AND-DEACTIVATION');

  const {
    data: ongoingSubscriptionDetail,
  } = useQuery(ONGOING_SUBSCRIPTION_DETAIL({ QUOTA_LIMIT_AND_DEACTIVATION }), {
    client: APOLLO_CLIENTS.NEW_APOLLO_BACKEND,
    skip: !QUOTA_LIMIT_AND_DEACTIVATION || true,
    fetchPolicy: 'network-only',
    variables: {
      companyKsuid: getSelectedCompany().ksuid,
    },
  });

  const companySubscriptionDetail = useMemo(
    () => get(ongoingSubscriptionDetail, 'ongoingSubscriptionDetail'),
    [ongoingSubscriptionDetail],
  );
  const BannerFlags = JSON.parse(localStorage.getItem(BANNER_STORAGE_KEY)) || {};
  const endDate = get(companySubscriptionDetail, 'endDatetime');

  const isGracePeriodActive = (get(companySubscriptionDetail, 'status') === PAYMENT_SCHEME_STATUS.GRACE_PERIOD);
  const endGracePeriodAt = get(companySubscriptionDetail, 'endGracePeriodAt');
  const isToday = moment(endDate).isSame(moment(), 'day')
  && (get(companySubscriptionDetail, 'status') === PAYMENT_SCHEME_STATUS.ACTIVE);
  const renderBanner = (isToday || (moment().isSameOrBefore(endGracePeriodAt) && isGracePeriodActive));

  const renderDescriptionText = () => (
    <Typography.Text style={{ fontSize: '13px' }}>
      {isToday
        ? t('message:do_expired_today_reminder_banner_description')
        : t('message:do_expired_grace_period_reminder_banner_description', {
          date: String(moment(endGracePeriodAt).format('DD MMMM YYYY')),
        })}
    </Typography.Text>
  );

  const renderMessage = () => (
    <Typography.Text
      style={{ fontSize: '16px', fontWeight: 'bold' }}
    >
      {
        isToday
          ? t('message:do_expired_today_reminder_banner_title')
          : t('message:do_expired_grace_period_reminder_banner_title', {
            date: String(moment(endGracePeriodAt).subtract(7, 'day').format('DD MMMM YYYY')),
          })
      }
    </Typography.Text>
  );

  const handleCloseBanner = () => {
    BannerFlags['do-expired'] = {
      [profile.ksuid]: (new Date()).getTime(),
    };
    localStorage.setItem(BANNER_STORAGE_KEY, JSON.stringify(BannerFlags));
  };

  return renderBanner && (
    <Alert
      closable={true}
      closeText={<CloseOutlined style={{ fontSize: '18px' }} />}
      description={renderDescriptionText()}
      icon={<WarningFilled style={{ fontSize: '18px' }} />}
      message={renderMessage()}
      onClose={handleCloseBanner}
      showIcon={true}
      style={{
        height: '80px',
        padding: '14px 32px',
        borderWidth: '3px 0 0 0',
        borderColor: '#D13042',
        borderRadius: '0',
      }}
      type="error"
    />
  );
};

export default ContractDOExpireReminderBanner;
