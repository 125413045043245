import logo from '@kargotech/tms-common/Assets/Images/Logo/VendorCentral/transporter.svg';

import defaultConfig from './default.json';

export default {
  ...defaultConfig,
  logo,
  logoTheLorry: '/assets/companyLogo/the-lorry.png',
  projectUrl: (global.location && global.location.origin) || 'https://vendor.kargo.tech',
};
