import gql from 'graphql-tag';

export default gql`
  mutation ADD_RECIPIENT_DEVICE_KEY(
    $deviceToken: String!
    $deviceType: String!
  ) {
    addRecipientDeviceKey(
      input: {
        deviceToken: $deviceToken
        deviceType: $deviceType
      }
    ){
      active
    }
  }
`;
