import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash-es';

import { useTranslation } from 'react-i18next';
import { ProfileContext } from '~/Contexts/ProfileProvider';
import COMPANY_NFT_MODULES from '~/GraphQL/ProfileService/Queries/companyNftModules';
import { APOLLO_CLIENTS } from '~/Services/apollo';
import { COUNTRIES } from '~/Configurations/constants';

export const NftModulesContext = createContext();

const NftModulesProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const { getSelectedCompany, profile } = useContext(ProfileContext);
  const {
    data: nftModulesData,
  } = useQuery(COMPANY_NFT_MODULES, {
    client: APOLLO_CLIENTS.PROFILE,
    fetchPolicy: 'network-only',
    variables: {
      companyKsuid: getSelectedCompany().ksuid,
    },
  });
  const extractedModules = useMemo(
    () => Object.fromEntries(get(
      nftModulesData,
      'companyNftModules',
      [],
    ).map(nftModule => [nftModule.id, nftModule.isActive])),
    [nftModulesData],
  );

  const modulesArray = useMemo(
    () => get(
      nftModulesData,
      'companyNftModules',
      [],
    ).filter(nftModule => nftModule.isActive)
      .map(nftModule => nftModule.id),
    [nftModulesData],
  );

  useEffect(() => {
    const code = get(profile, 'company.countryCode', COUNTRIES.ID);

    if (code === COUNTRIES.ID) i18n.changeLanguage('id');
    if (code === COUNTRIES.MY) i18n.changeLanguage('en');

    return () => i18n.changeLanguage(i18n.options.lng);
  }, [profile, i18n]);

  return (
    <NftModulesContext.Provider
      value={{
        modulesArray,
        ...extractedModules,
      }}
    >
      {children}
    </NftModulesContext.Provider>
  );
};

export default NftModulesProvider;
