export const TRACKER_SEARCH_DEBOUNCE_TIME = 2000; // in milliseconds

export const PATH_TO_COMPONENT_MAPPING = [
  {
    path: '/auth/login',
    title: 'auth_login',
  },
  {
    path: '/auth/register',
    title: 'auth_register',
  },
  {
    path: '/auth/register/details',
    title: 'auth_register_accountDetails',
  },
  {
    path: '/auth/verification',
    title: 'auth_otpVerification_register',
  },
  {
    path: '/auth/forgot-password',
    title: 'auth_otpVerification_forgotPassword',
  },
  {
    path: '/auth/choose-company',
    title: 'auth_chooseCompany',
  },
  {
    path: '/welcome',
    title: 'welcomePage',
  },
  {
    path: '/personal-account',
    title: 'personal_account',
  },
  {
    path: '/company/details',
    title: 'company_details',
  },
  {
    path: '/company/employees',
    title: 'company_employeeList',
  },
  {
    path: '/company/vehicles',
    title: 'company_vehicleList',
  },
  {
    path: '/company/vehicle-details',
    title: 'company_vehicleDetails',
  },
  {
    path: '/company/drivers',
    title: 'company_driverList',
  },
  {
    path: '/company/transporter/employees',
    title: 'company_transporter_employeeList',
  },
  {
    path: '/company/transporter/vehicles',
    title: 'company_transporter_vehicleList',
  },
  {
    path: '/company/transporter/drivers',
    title: 'company_transporter_driverList',
  },
  {
    path: '/company/transporter-list',
    title: 'company_transporterList',
  },
  {
    path: '/company/driver-details',
    title: 'company_driverDetails',
  },
  {
    path: '/company/utilization',
    title: 'company_utilization',
  },
  {
    path: '/404',
    title: 'errorPage',
  },
  {
    path: '/customer/customer-list',
    title: 'customer_customerList',
  },
  {
    path: '/customer/customer-details',
    title: 'customer_customerDetails',
  },
  {
    path: '/customer/warehouse-list',
    title: 'customer_warehouseList',
  },
  {
    path: '/customer/warehouse-details',
    title: 'customer_warehouseList',
  },
  {
    path: '/shipment/list/upcoming',
    title: 'shipment_upcomingShipmentList',
  },
  {
    path: '/shipment/list/planned',
    title: 'shipment_plannedShipmentList',
  },
  {
    path: '/shipment/list/ongoing',
    title: 'shipment_ongoingShipmentList',
  },
  {
    path: '/shipment/list/completed',
    title: 'shipment_completedShipmentList',
  },
  {
    path: '/shipment/details',
    title: 'shipment_details',
  },
  {
    path: '/shipment/fees',
    title: 'shipment_fees',
  },
  {
    path: '/shipment/income',
    title: 'shipment_income',
  },
  {
    path: '/shipment/expenses',
    title: 'shipment_expenses',
  },
  {
    path: '/container/details',
    title: 'container_details',
  },
  {
    path: '/vehicle/tracking',
    title: 'vehicle_tracking',
  },
  {
    path: '/vehicle/available',
    title: 'vehicle_availablity',
  },
  {
    path: '/payment/shipment-list',
    title: 'payment_shipmentList',
  },
  {
    path: '/payment/invoice-create',
    title: 'payment_invoiceCreate',
  },
  {
    path: '/payment/edit-invoice',
    title: 'payment_editInvoice',
  },
  {
    path: '/payment/invoice-list',
    title: 'payment_invoiceList',
  },
  {
    path: '/payment/swift-payment-list',
    title: 'payment_swiftPaymentList',
  },
  {
    path: '/payment/aging',
    title: 'payment_aging',
  },
  {
    path: '/payment/dof-summary',
    title: 'payment_dofSummaryPage',
  },
  {
    path: '/payment/dof-details',
    title: 'payment_dofDetailsPage',
  },
  {
    path: '/payment/podf-details',
    title: 'payment_podfDetailsPage',
  },
  {
    path: '/payment/podf-summary',
    title: 'payment_podfSummaryPage',
  },
  {
    path: '/payment/dof2-details',
    title: 'payment_dof2DetailsPage',
  },
  {
    path: '/payment/dof2-summary',
    title: 'payment_dof2Summary',
  },
  {
    path: '/payment/invoice-details',
    title: 'payment_invoiceDetails',
  },
  {
    path: '/payment/invoice-history',
    title: 'payment_invoiceHistory',
  },
  {
    path: '/bidding/available',
    title: 'bidding_available',
  },
  {
    path: '/bidding/active',
    title: 'bidding_active',
  },
  {
    path: '/bidding/history',
    title: 'bidding_history',
  },
  {
    path: '/bidding/details',
    title: 'bidding_details',
  },
  {
    path: '/report/performance-report',
    title: 'report_performanceReport',
  },
  {
    path: '/report/shipment-acceptance-report',
    title: 'report_shipmentAcceptanceReport',
  },
  {
    path: '/report/shipment-time-accuracy-report',
    title: 'report_shipmentTimeAccuracyReport',
  },
  {
    path: '/report/mileage-report',
    title: 'report_milageReport',
  },
  {
    path: '/report/utilization-report',
    title: 'report_utilizationReport',
  },
  {
    path: '/finance/reports',
    title: 'finance_earningsReports',
  },
  {
    path: '/finance/incentives',
    title: 'finance_incentives',
  },
  {
    path: '/performance',
    title: 'performance',
  },
];

// NOT SORTED ON PURPOSE
export const TRACKER_NAMES = {
  NAVIGATION: {
    PAGE: {
      VIEWED: 'navigation_page_viewed',
    },
  },
  LIVE_TRACKER: {
    STATUS_FILTER: {
      RESET: 'liveTracker_statusFilter_reset',
      SUBMITTED: 'liveTracker_statusFilter_submitted',
    },
    SEARCH: {
      SUBMITTED: 'liveTracker_search_submitted',
    },
    TRUCK_LIST: {
      OPENED: 'liveTracker_truckList_opened',
      READY_TAB_VISITED: 'liveTracker_truckList_readyTabVisited',
      SEGERA_READY_TAB_VISITED: 'liveTracker_truckList_segeraReadyTabVisited',
      CLOSED: 'liveTracker_truckList_closed',
    },
    TRUCK: {
      CLICKED: 'liveTracker_truck_clicked',
      SEE_DETAILS_CLICKED: 'liveTracker_truck_seeDetailsClicked',
    },
  },
  DELIVERY_ORDERS: {
    CUSTOMER_FILTER: {
      SUBMITTED: 'deliveryOrders_customerFilter_submitted',
      RESET: 'deliveryOrders_customerFilter_reset',
    },
    LOADING_DATE_FILTER: {
      SUBMITTED: 'deliveryOrders_loadingDateFilter_submitted',
      RESET: 'deliveryOrders_loadingDateFilter_reset',
    },
    STATUS_FILTER: {
      SUBMITTED: 'deliveryOrders_statusFilter_submitted',
      RESET: 'deliveryOrders_statusFilter_reset',
    },
    SEARCH: {
      SUBMITTED: 'deliveryOrders_search_submitted',
    },
    DELIVERY_ORDER: {
      CREATED: 'deliveryOrders_deliveryOrder_created',
      CANCELLED: 'deliveryOrders_deliveryOrder_cancelled',
      STATUS_UPDATED: 'deliveryOrders_deliveryOrder_statusUpdated',
      ASSIGNED: 'deliveryOrders_deliveryOrder_assigned',
      REASSIGNED: 'deliveryOrders_deliveryOrder_reassigned',
      EDITED: 'deliveryOrders_deliveryOrder_edited',
      // Completed status updates are to be submitted along with status_updated
      COMPLETED: 'deliveryOrders_deliveryOrder_completed',
      ACCEPTED: 'deliveryOrders_deliveryOrder_accepted',
    },
    DOCUMENT: {
      UPLOADED: 'deliveryOrders_document_uploaded',
      VIEWED: 'deliveryOrders_document_viewed',
      DOWNLOADED: 'deliveryOrders_document_downloaded',
    },
    LOADING_LOCATION_FILTER: {
      SUBMITTED: 'deliveryOrders_loadingLocation_submitted',
      RESET: 'deliveryOrders_loadingLocation_reset',
    },
    UNLOADING_LOCATION_FILTER: {
      SUBMITTED: 'deliveryOrders_unloadingLocation_submitted',
      RESET: 'deliveryOrders_unloadingLocation_reset',
    },
    VEHICLE_TYPE_FILTER: {
      SUBMITTED: 'deliveryOrders_vehicleType_submitted',
      RESET: 'deliveryOrders_vehicleType_reset',
    },
  },
  EARNINGS: {
    INCOME: {
      CREATED: 'earnings_income_created',
      EDITED: 'earnings_income_edited',
      DELETED: 'earnings_income_deleted',
    },
    EXPENSES: {
      CREATED: 'earnings_income_created',
      EDITED: 'earnings_income_edited',
      DELETED: 'earnings_income_deleted',
    },
    SEARCH: {
      SUBMITTED: 'earnings_search_submitted',
      // Unused for now (May implement reset in all search box - future improvement)
      // RESET: 'earnings_search_reset',
    },
    DATE_FILTER: {
      SUBMITTED: 'earnings_dateFilter_submitted',
      RESET: 'earnings_dateFilter_reset',
    },
    TRUCK_FILTER: {
      SUBMITTED: 'earnings_truckFilter_submitted',
      RESET: 'earnings_truckFilter_reset',
    },
    CUSTOMER_FILTER: {
      SUBMITTED: 'earnings_customerFilter_submitted',
      RESET: 'earnings_customerFilter_reset',
    },
    REPORT: {
      DOWNLOADED: 'earnings_report_downloaded',
    },
  },
  INVOICING: {
    INVOICE: {
      CREATED: 'invoicing_invoice_created',
      EDITED: 'invoicing_invoice_edited',
      MARKED_AS_PAID: 'invoicing_invoice_markedAsPaid',
      DOWNLOADED: 'invoicing_invoice_downloaded',
      SENT: 'invoicing_invoice_sent',
      PRINTED: 'invoicing_invoice_printed',
    },
    DELIVERY_ORDERS: {
      DOWNLOADED: 'invoicing_deliveryOrders_downloaded',
    },
    DELIVERY_ORDER_STATUS_FILTER: {
      SUBMITTED: 'invoicing_deliveryOrderStatusFilter_submitted',
      RESET: 'invoicing_deliveryOrderStatusFilter_reset',
    },
    DELIVERY_ORDER_DATE_FILTER: {
      SUBMITTED: 'invoicing_deliveryOrderDateFilter_submitted',
      RESET: 'invoicing_deliveryOrderDateFilter_reset',
    },
    DELIVERY_ORDER_COMPLETED_DATE_FILTER: {
      SUBMITTED: 'invoicing_deliveryOrderCompletedDateFilter_submitted',
      RESET: 'invoicing_deliveryOrderCompetedDateFilter_reset',
    },
    DELIVERY_ORDER_CUSTOMER_FILTER: {
      SUBMITTED: 'invoicing_deliveryOrderCustomerFilter_submitted',
      RESET: 'invoicing_deliveryOrderCustomerFilter_reset',
    },
    DELIVERY_ORDER_SEARCH: {
      SUBMITTED: 'invoicing_deliveryOrderSearch_submitted',
    },
    INVOICE_LIST: {
      DOWNLOADED: 'invoicing_invoiceList_downloaded',
    },
    INVOICE_STATUS_FILTER: {
      SUBMITTED: 'invoicing_invoiceStatusFilter_submitted',
      RESET: 'invoicing_invoiceStatusFilter_reset',
    },
    INVOICE_DATE_FILTER: {
      SUBMITTED: 'invoicing_invoiceDateFilter_submitted',
      RESET: 'invoicing_invoiceDateFilter_reset',
    },
    INVOICE_CUSTOMER_FILTER: {
      SUBMITTED: 'invoicing_invoiceCustomerFilter_submitted',
      RESET: 'invoicing_invoiceCustomerFilter_reset',
    },
    INVOICE_SEARCH: {
      SUBMITTED: 'invoicing_invoiceSearch_submitted',
    },
    ADDITIONAL_DETAILS: {
      ADDED: 'invoicing_additionalDetails_added',
      REMOVED: 'invoicing_additionalDetails_removed',
    },
    CUSTOMER_DETAILS: {
      EDITED: 'invoicing_customerDetails_edited',
    },
  },
  BIDS: {
    LOADING_DATE_FILTER: {
      SUBMITTED: 'bids_loadingDateFilter_submitted',
      RESET: 'bids_loadingDateFilter_reset',
    },
    SEARCH: {
      SUBMITTED: 'bids_search_submitted',
    },
    BID: {
      SUBMITTED: 'bids_bid_submitted',
    },
  },
  FINANCING: {
    DOF_1: {
      CREATED: 'financing_dof1_created',
    },
    DOF_2: {
      CREATED: 'financing_dof2_created',
    },
    PODF: {
      CREATED: 'financing_podf_created',
    },
  },
  NOTIFICATIONS: {
    BELL_ICON: {
      CLICKED: 'notifications_bellIcon_clicked',
    },
    NOTIFICATION: {
      CLICKED: 'notifications_notification_clicked',
    },
  },
  COMPANY_SETTINGS: {
    COMPANY_DETAILS: {
      EDITED: 'companySettings_companyDetails_edited',
    },
    BANK_ACCOUNT: {
      ADDED: 'companySettings_bankAccount_added',
      DELETED: 'companySettings_bankAccount_deleted',
      SELECTED_AS_MAIN: 'companySettings_bankAccount_selectedAsMain',
    },
    DOCUMENTS: {
      UPLOADED: 'companySettings_documents_uploaded',
    },
  },
  MASTER_DATA: {
    EMPLOYEE: {
      CREATED: 'masterData_employee_created',
      EDITED: 'masterData_employee_edited',
      DEACTIVATED: 'masterData_employee_deactivated',
    },
    EMPLOYEE_ACCESS_TYPE_FILTER: {
      SUBMITTED: 'masterData_employeeAccessTypeFilter_submitted',
      RESET: 'masterData_employeeAccessTypeFilter_reset',
    },
    EMPLOYEE_SEARCH: {
      SUBMITTED: 'masterData_employeeSearch_submitted',
    },
    TRUCK: {
      CREATED: 'masterData_truck_created',
      EDITED: 'masterData_truck_edited',
      DEACTIVATED: 'masterData_truck_deactivated',
      UPLOADED_THROUGH_CSV: 'masterData_truck_uploadedThroughCSV',
    },
    TRUCK_SEARCH: {
      SUBMITTED: 'masterData_truckSearch_submitted',
    },
    DRIVER: {
      CREATED: 'masterData_driver_created',
      EDITED: 'masterData_driver_edited',
      DEACTIVATED: 'masterData_driver_deactivated',
      UPLOADED_THROUGH_CSV: 'masterData_driver_uploadedThroughCSV',
    },
    DRIVER_SEARCH: {
      SUBMITTED: 'masterData_driverSearch_submitted',
    },
    CUSTOMER: {
      CREATED: 'masterData_customer_created',
      EDITED: 'masterData_customer_edited',
      DEACTIVATED: 'masterData_customer_deactivated',
      UPLOADED_THROUGH_CSV: 'masterData_customer_uploadedThroughCSV',
    },
    WAREHOUSE: {
      CREATED: 'masterData_warehouse_created',
      EDITED: 'masterData_warehouse_edited',
      DEACTIVATED: 'masterData_warehouse_deactivated',
      UPLOADED_THROUGH_CSV: 'masterData_warehouse_uploadedThroughCSV',
    },
  },
  ACCOUNT: {
    PASSWORD: {
      CHANGED: 'account_password_changed',
    },
  },
  AUTHENTICATION: {
    USER: {
      LOGGED_IN: 'authentication_user_loggedIn',
      LOGGED_OUT: 'authentication_user_loggedOut',
    },
  },
};

/**
 * General properties for TTMS Clevertap Trackers
 * @param {Object} profile - profile object from ProfileContext (empty object if no profile)
 * @returns {Object} - general tracker properties
 * * Object props: current_active_user_status <String>: user access type
 * * Object props: user_agent <String>: navigator userAgent
 * * Object props: user_ksuid <String>: user profile ksuid
 * * Object props: viewport_height <Number>: user browser viewportHeight
 * * Object props: viewport_width <Number>: user browser viewportWidth
 */
export const getTrackerGeneralProps = profile => {
  const viewportWidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth;
  const viewportHeight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight;
  const searchParams = new URLSearchParams(window.location.search);
  const IS_LITE_MODE = searchParams?.get('litemode') === 'true';
  return {
    current_active_user_status: profile?.company?.verifications?.[0]?.currentActiveCompanyStatus || '',
    user_agent: window.navigator.userAgent,
    user_ksuid: profile?.ksuid || '',
    user_name: profile?.name || '',
    user_phone_number: profile?.phoneNumber,
    company_ksuid: profile?.company?.ksuid,
    company_name: profile?.company?.name,
    viewport_height: viewportHeight,
    viewport_width: viewportWidth,
    isTApp: IS_LITE_MODE,
  };
};
