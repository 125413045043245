import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ErrorContainer = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 8px;
  height: 100svh;
  padding: 40px;
  text-align: center;
`;
const ErrorTitle = styled.h1`
  font-size: 30px;
  color: #FF4433;
`;
const ErrorMessage = styled.p`
  font-size: 20px;
  background-color: #efefef;
  padding: 8px 16px;
  border-radius: 8px;
`;
const ErrorHint = styled.p`
  color: gray;
  font-size: 16px;
  text-align: center;
`;

const ErrorFallback = ({ error }) => {
  const { t } = useTranslation();

  return (
    <ErrorContainer>
      <ErrorTitle>{t('message:error_title')}</ErrorTitle>
      <ErrorMessage>{error.message}</ErrorMessage>
      <ErrorHint>{t('message:error_hint')}</ErrorHint>
    </ErrorContainer>
  );
};

export default ErrorFallback;
