import React, { Fragment, useContext, useEffect, useCallback } from 'react';
import { Route as ReactRoute } from 'react-router-dom';
import { NavigationContext } from '../../Contexts/NavigationProvider';

/**
 * Basic Route: react-router's Route wrapped with Layout's configurations
 * @param {Object} props
 * @param {import('react').ReactElement} props.children
 * @param {import('react').ReactNode} props.Layout
 * @param {String} props.title
 * @param {String} props.subtitle
 * @param {String} props.tabNavigation.path
 * @param {String} props.tabNavigation.title
 * @param {Boolean} props.showLogo
 */
export default function BasicRoute({
  children,
  Layout = Fragment,
  title = '',
  subtitle = '',
  showLogo = false,
  isCenteredLogo = false,
  ...routeProps
}) {
  const {
    setIsCenteredLogo,
    setShowLogo,
    setSubtitle,
    setTabNavigation,
    setTitle,
  } = useContext(NavigationContext);

  useEffect(() => {
    setIsCenteredLogo(isCenteredLogo);
    setShowLogo(showLogo);
    setSubtitle(subtitle);
    setTabNavigation([]);
    setTitle(title);
  }, [
    isCenteredLogo,
    setIsCenteredLogo,
    setShowLogo,
    setSubtitle,
    setTabNavigation,
    setTitle,
    showLogo,
    subtitle,
    title,
  ]);

  const wrapRender = useCallback(renderedEl => (
    <ReactRoute {...routeProps}>
      {renderedEl}
    </ReactRoute>
  ), [routeProps]);

  return wrapRender(
    <Layout>
      {children}
    </Layout>,
  );
}
