export const INVOICE_CREATION_STEP = {
  DO_SELECTION: 'DO_SELECTION',
  INVOICE_FORM: 'INVOICE_FORM',
  REVIEW_INVOICE: 'REVIEW_INVOICE',
};

export const INVOICE_FORM_TYPE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  REVISE: 'REVISE',
};

export const AVAILABLE_STEP_ORDER = {
  0: INVOICE_CREATION_STEP.DO_SELECTION,
  1: INVOICE_CREATION_STEP.INVOICE_FORM,
  2: INVOICE_CREATION_STEP.REVIEW_INVOICE,
};

export const AVAILABLE_STEP_INDEX = {
  [INVOICE_CREATION_STEP.DO_SELECTION]: 0,
  [INVOICE_CREATION_STEP.INVOICE_FORM]: 1,
  [INVOICE_CREATION_STEP.REVIEW_INVOICE]: 2,
};

export const INVOICE_CREATION_HEADER_ACTION = {
  SELECT_CUSTOMER: 'SELECT_CUSTOMER',
  ADD_ATTACHMENTS: 'ADD_ATTACHMENTS',
};

export const SHIPMENT_MILE_TYPE = {
  MID_MILE: 'MID_MILE',
  FIRST_MILE: 'FIRST_MILE',
};

export const SHIPMENT_SEARCH_TYPES = {
  DO_NUMBER: 'DO_NUMBER',
  LICENSE_PLATE: 'LICENSE_PLATE',
  CONTAINER_NUMBER: 'CONTAINER_NUMBER',
};

export default {};
