/* eslint-disable max-len */

import {
  FINANCING_DOCUMENT_GROUP,
  QUICK_PAY_FINANCING_DOCUMENT_TYPE,
  ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE,
  PAYMENT_PROJECT_STATUSES,
  PAYMENT_PROJECT_SCHEME,
  PROJECT_DOCUMENT_STATUSES,
} from '~/Configurations/constants';

export default {
  access_private_link: 'Access Private Link',
  access_private_link_expired_info: 'Your private link has expired. Please request a new private link from the Kargo admin.',
  access_private_link_info: 'Confidential document. Please keep the provided password confidential',
  additional_document: 'Additional Document',
  action: 'Action',
  available_until: 'Document link available until {{ dateTime }}, at 23:59',
  averages_project_value: 'Average Project Value',
  back: 'Back',
  cancel: 'Cancel',
  cancel_text: 'Save data only',
  change: 'Change',
  check_document_suitability_again: 'Check Document Suitability Again',
  check_document_suitable_info: 'The completeness of the document will be checked by Kargo, and you may be asked to upload additional documents. Please check all documents again to facilitate the submission process.',
  continue: 'Continue',
  customer_data: 'Customer Data',
  customer_detail: 'Customer Detail',
  customer_documents: 'Customer Documents',
  customer_name: 'Customer Name',
  data_that_has_been_filled_in_will_still_be_stored: 'Data that has been filled in will only be stored if you have previously made a submission',
  dialog_title: 'Do you want to register the customer for the Fast Payment Program?',
  dialog_body: 'Your customer data will remain stored, and you will need to attach several documents for the fast payment program registration.',
  description: 'Description',
  draft_project_deleted_successfully: 'Draft Payment Project successfully deleted',
  document_deleted_successfully: 'Document file successfully deleted',
  document_delete_failed: 'Failed to delete document file, {{error}}',
  document_example: 'Document Example',
  document_saved_successfully: 'Document successfully saved',
  document_status: 'Document Status',
  document_verification_successfully_submitted: 'Verification for the Main Document successfully submitted!',
  download: 'Download',
  download_all: 'Download All',
  enter_access_password: 'Enter access password',
  page_change_warning_description: 'Continue the process to complete.',
  page_change_warning_title: 'You are in the process of uploading documents',
  enjoy_fast_payment_service_now: 'Enjoy Fast Payment Service now!',
  enterprise_referral: 'Based on Delivery Note',
  enterprise_referral_scheme: 'Scheme Based on Delivery Note',
  fast_payment_project_approved_status_info: 'Approved limit {{approvedLimit}} by Kargo with TOP (Terms of Payment) within {{approvedTermsOfPayment}} days',
  fast_payment_project_rejected_status_info: 'No reason provided. Please contact the sales team',
  fast_payment_project_status: {
    [PAYMENT_PROJECT_STATUSES.REJECTED]: 'Fast Payment Rejected',
    [PAYMENT_PROJECT_STATUSES.ACTIVE]: 'Registered Fast Payment {{scheme}} - {{approvedPrograms}}',
  },
  file_delete_confirmation_message: 'Are you sure you want to delete this file?',
  file_size_mb: '{{fileSize}}MB',
  file_upload_description: 'Drag & drop files into this box, or',
  financial_documents: 'Financial Documents',
  financing_document_group_map: {
    [FINANCING_DOCUMENT_GROUP.CUSTOMER]: 'Customer Documents',
    [FINANCING_DOCUMENT_GROUP.TRANSPORTER_FINANCIAL]: '{{companyName}} Financial Documents',
    [FINANCING_DOCUMENT_GROUP.TRANSPORTER_LEGAL]: '{{companyName}} Legal Documents',
    [FINANCING_DOCUMENT_GROUP.MAIN]: '{{companyName}} Main Documents',
    [FINANCING_DOCUMENT_GROUP.LEGAL]: '{{companyName}} Legal Documents',
    [FINANCING_DOCUMENT_GROUP.FINANCIAL]: '{{companyName}} Financial Documents',
  },
  finance_project_status_info_map: {
    [PAYMENT_PROJECT_STATUSES.ACTIVE]: 'Active',
    [PAYMENT_PROJECT_STATUSES.INACTIVE]: 'Inactive',
    [PAYMENT_PROJECT_STATUSES.REJECTED]: 'Rejected',
    [PAYMENT_PROJECT_STATUSES.SUBMITTED]: 'Under assessment process',
  },
  go_out: 'Exit',
  if_any: 'If Any',
  if_any_bracket: '(If any)',
  input_averages_project_value: 'Enter the average nominal value of the invoice for the work billed',
  input_customer_company_name: 'Enter your customer\'s company name',
  input_customer_company_address: 'Enter your customer\'s full address',
  input_invoice_amount: 'Enter the invoice amount billed',
  invoice_amount: 'Invoice Value',
  keep_continue: 'Keep Continue',
  landing_another_transporter_exp: 'Other Transporter Experiences',
  landing_contact_us: 'Contact Us',
  landing_for_more_info: 'for more information',
  landing_for_quick_payment_services_kargo_cooperates_with: 'For Fast Payment services, Kargo collaborates with licensed financial institutions supervised by OJK',
  landing_four_easy_submission_steps: '4 Easy Submission Steps',
  landing_increase_cash_flow: 'Increase your company\'s cash flow by getting payments without waiting for customer settlement',
  landing_quick_pay_benefits: 'Fast Payment Benefits',
  landing_quick_pay_signup: 'Sign Up for Fast Payment',
  landing_quick_pay_your_financial_solution: 'Kargo Fast Payment Service, Your Financial Solution',
  learn_scheme: 'Learn the Scheme',
  payment_project_saved_as_draft: 'Fast Payment Project saved as draft',
  payment_scheme_tnc: 'The Transporter hereby agrees and authorizes Panthera to perform checks on themselves and/or their company for all payment performance that Panthera carries out through recognized official institutions in Indonesia.',
  quick_pay_financing_document_map: {
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.NPWP_COMPANY]: {
      bannerTitle: 'Company NPWP',
      title: 'Transporter Company NPWP',
      note: 'Ensure the document is in color, clearly visible (not blurry), and easy to read ',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.AKTA_PENDIRIAN]: {
      bannerTitle: 'Company Establishment Deed with Approval from the Ministry of Law and Human Rights',
      title: 'Company Establishment Deed',
      note: 'Ensure the document is in color, clearly visible (not blurry), and easy to read',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.SK_KEMENKUMHAM]: {
      bannerTitle: 'Ministry of Law and Human Rights Decree according to the Company Establishment Deed',
      title: 'Ministry of Law and Human Rights Decree',
      description: 'Must be original & latest according to the Company Establishment Deed',
      note: 'Must match the Company Establishment Deed',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.AKTA_PERUBAHAN_TERAKHIR]: {
      bannerTitle: 'Latest Company Amendment Deed with Approval from the Ministry of Law and Human Rights',
      title: 'Latest Company Amendment Deed',
      note: 'Only needed if there is a Company Amendment Deed due to a change of directors or matters related to the company\'s internal affairs',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.AKTA_PERUBAHAN_PERUSAHAAN]: {
      bannerTitle: 'Company Amendment Deed (All) with Approval from the Ministry of Law and Human Rights',
      title: 'Company Amendment Deed (All)',
      note: 'If the establishment is more than 5 years, a change of directors occurs, or matters related to the company\'s internal affairs, it is mandatory to include the Latest Company Amendment Deed',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.SK_KEMENKUMHAM_AKTA_PERUBAHAN]: {
      bannerTitle: 'Ministry of Law and Human Rights Decree according to the Company Establishment Deed',
      title: 'Ministry of Law and Human Rights Decree from the Amendment Deed',
      description: 'Must be original & latest according to the Company Establishment Deed',
      note: 'Must match the latest Company Amendment Deed',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.NPWP_CUSTOMER]: {
      bannerTitle: 'Company NPWP',
      title: 'Customer Company NPWP',
      description: 'Must be original and in color',
      note: 'Ensure the uploaded document is in color, clearly visible, and easy to read ',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.BANK_STATEMENT]: {
      bannerTitle: 'Last 6 Months Bank Statement',
      title: 'Bank Statement',
      note: 'Ensure all transactions are clearly visible in the bank statement. If transactions are made through a personal bank account, it is mandatory to include a personal bank statement',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.INVOICE_EXAMPLE]: {
      bannerTitle: 'Customer Invoice History Paid in the Last 6 Months',
      title: 'Customer Invoice History',
      note: 'Attach a minimum of 5 paid invoices. Ensure all invoices are stamped or signed, and correspond to the transaction list between the Transporter and Customer',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.SALES_REPORT]: {
      bannerTitle: 'Sales Report (Sales Report) required for the last 6 months',
      title: 'Sales Report',
      description: 'Required for the last 6 months',
      note: 'Ensure the report is saved in PDF format',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.INCOME_STATEMENT]: {
      bannerTitle: 'Income Statement required for the last 6 months',
      title: 'Income Statement',
      description: 'Required for the last 6 months',
      note: 'See the template example available on the right',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.BALANCE_SHEET]: {
      bannerTitle: 'Balance Sheet required for the last 6 months',
      title: 'Balance Sheet',
      description: 'Required for the last 6 months',
      note: 'See the template example available on the right',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.CASH_FLOW_STATEMENT]: {
      bannerTitle: 'Cash Flow Statement required for the last 6 months',
      title: 'Cash Flow Statement',
      description: 'Required for the last 6 months',
      note: 'Ensure the report is saved in PDF format',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.DEBT_REPORT]: {
      bannerTitle: 'Debt & Receivables Report',
      title: 'Debt & Receivables Report',
      note: 'Required for the last 6 months and according to the debt age',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.CREDIT_REPORT]: {
      bannerTitle: 'Receivables Report',
      title: 'Receivables Report',
      description: 'Required for the last 6 months',
      note: 'Required for the last 6 months and according to the age of receivables',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.NIB_OR_TDP]: {
      bannerTitle: 'NIB (Business Registration Number) and Attachments',
      title: 'NIB (Business Registration Number)',
      note: 'NIB document and its attachments issued by the OSS System from the Ministry of Investment/BKPM. Attachments like KLBI code, type of trade, or similar',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.KTP_NPWP_DIRECTOR]: {
      bannerTitle: 'KTP & NPWP of Company Officials (Listed in the Latest Amendment Deed)',
      title: 'KTP & NPWP of Company Officials',
      note: 'The listed name must match the Establishment Deed & Amendment Deed. Ensure that the KTP and NPWP photos of the officials are in color and clearly visible (not blurry)',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.KTP_NPWP_COMMISSIONER]: {
      bannerTitle: 'KTP & NPWP of Commissioner',
      title: 'KTP & NPWP of Commissioner',
      description: 'Must be in color, match the Establishment Deed and Amendment Deed',
      note: 'The listed name must match the Establishment Deed & Amendment Deed. Ensure that the photo is in color and clearly visible (not blurry)',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.AGREEMENT_COOPERATION]: {
      bannerTitle: 'Cooperation Agreement Letter',
      title: 'Cooperation Agreement Letter',
      description: 'Between the Transporter and Customer',
      note: 'Original agreement between the Transporter and the Shipper. Ensure that the scanned or photographed uploaded document is clearly visible and easy to read',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.AGREEMENT_CREDIT_CHECK]: {
      bannerTitle: 'Credit Check Approval Letter',
      title: 'Credit Check Approval Letter',
      description: 'Must include an original letter with a Rp10,000 stamp & signature',
      note: 'Must include an original letter with a Rp10,000 stamp & signature',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.SIUP]: {
      bannerTitle: 'SIUP (Trade Business License) or TDP',
      title: 'SIUP (Trade Business License) or TDP',
      note: 'Valid document for the last 5 years and issued by the OSS System of the Ministry of Investment/BKPM or a valid Company Registration Certificate (TDP)',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.SKDU]: {
      bannerTitle: 'SKDU (Business Domicile Certificate)',
      title: 'SKDU (Business Domicile Certificate)',
      note: 'Original document that is still valid and issued by the local sub-district',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.BUKTI_PELUNASAN_HUTANG]: {
      bannerTitle: 'Debt Repayment Evidence',
      title: 'Debt Repayment Evidence',
      note: 'Must be an original document accompanied by a Debt Repayment Proof Letter provided by the lending institution',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.FINANCIAL_REPORT]: {
      bannerTitle: 'Financial Report for the Last 2 Years and Last 6 Months',
      title: 'Financial Report',
      note: 'Includes Income Statement, Balance Sheet, and Cash Flow Statement. Attach reports for two different periods, namely the last 2 years and the last 6 months',
    },
  },
  enterprise_referral_financing_document_map: {
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.NPWP_CUSTOMER]: {
      bannerTitle: 'Company NPWP',
      title: 'Customer Company NPWP',
      description: 'Must be original and in color',
      note: 'Ensure the uploaded document is in color, clearly visible, and easy to read ',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.NPWP_COMPANY]: {
      bannerTitle: 'Company NPWP',
      title: 'Company NPWP',
      description: 'Must be in color according to the Company Establishment Deed and Amendment Deed',
      note: 'Ensure the uploaded document is in color, clearly visible, and easy to read ',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.AKTA_PENDIRIAN]: {
      bannerTitle: 'Company Establishment Deed',
      title: 'Company Establishment Deed',
      description: 'Must be updated in the last 5 years',
      note: 'If the establishment is more than 5 years ago or there is a change of directors, organization, or matters related to the company\'s internal affairs, the Company Amendment Deed must be included',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.SK_KEMENKUMHAM]: {
      bannerTitle: 'Ministry of Law and Human Rights Decree according to the Company Establishment Deed',
      title: 'Ministry of Law and Human Rights Decree',
      description: 'Must be original & latest according to the Company Establishment Deed',
      note: 'Must match the Company Establishment Deed',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.AKTA_PERUBAHAN_PERUSAHAAN]: {
      bannerTitle: 'Company Amendment Deed',
      title: 'Company Amendment Deed',
      description: 'Must be updated & if there is a change of company management',
      note: 'If the establishment is more than 5 years ago or there is a change of directors, organization, or matters related to the company\'s internal affairs, the latest Company Amendment Deed must be included',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.SK_KEMENKUMHAM_AKTA_PERUBAHAN]: {
      bannerTitle: 'Ministry of Law and Human Rights Decree according to the Company Amendment Deed',
      title: 'Ministry of Law and Human Rights Decree from the Amendment Deed',
      description: 'Must be original & latest according to the Company Amendment Deed',
      note: 'Must match the latest Company Amendment Deed',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.BANK_STATEMENT]: {
      bannerTitle: 'Bank Statement required for the last 6 months',
      title: 'Bank Statement',
      description: 'Required for the last 6 months',
      note: 'Ensure all transactions are clearly visible in the bank statement. If transactions are made through a personal bank account, it is mandatory to include a personal bank statement',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.INVOICE_EXAMPLE]: {
      bannerTitle: 'Invoice Example or Payment Proof required for the last 6 months',
      title: 'Invoice Example or Payment Proof',
      description: 'Latest in the last 6 months with Shipper A1-A2',
      note: 'Attach a minimum of 5 invoices for several DOs. Ensure all payment proofs are stamped or signed & match the transaction list between the Transporter and Shipper',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.FINANCIAL_STATEMENT]: {
      bannerTitle: 'Financial Statement for the last 6 months',
      title: 'Financial Statement',
      description: 'Required for the last 6 months',
      note: 'The audited report includes Sales Report, Income Statement, Balance Sheet, and Cash Flow Statement',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.AGREEMENT_CUSTOMER_COOPERATION]: {
      bannerTitle: 'Financial Statement for the last 6 months',
      title: 'Cooperation Agreement Letter with Customer',
      description: 'Between Shipper A1 and A2',
      note: 'The audited report includes Sales Report, Income Statement, Balance Sheet, and Cash Flow Statement',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.NIB_OR_TDP]: {
      bannerTitle: 'NIB or TDP',
      title: 'NIB or TDP',
      description: 'Must still be valid',
      note: 'Business Registration Number (NIB) document issued by the OSS System of the Ministry of Investment/BKPM or a valid Company Registration Certificate (TDP)',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.SIUP]: {
      bannerTitle: 'SIUP (Trade Business License)',
      title: 'SIUP (Trade Business License)',
      description: 'Or a valid Business License',
      note: 'Valid document for the last 5 years and issued by the OSS System of the Ministry of Investment/BKPM',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.SKDU]: {
      bannerTitle: 'SKDU (Business Domicile Certificate)',
      title: 'SKDU (Business Domicile Certificate)',
      description: 'Or a valid Location Permit',
      note: 'Original document that is still valid and issued by the local sub-district',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.KTP_NPWP_DIRECTOR]: {
      bannerTitle: 'KTP & NPWP of Director',
      title: 'KTP & NPWP of Director',
      description: 'Must be in color, match the Establishment Deed and Amendment Deed',
      note: 'The listed name must match the Establishment Deed & Amendment Deed. Ensure that the photo is in color and clearly visible (not blurry)',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.KTP_NPWP_COMMISSIONER]: {
      bannerTitle: 'KTP & NPWP of Commissioner',
      title: 'KTP & NPWP of Commissioner',
      description: 'Must be in color according to the Establishment Deed and Amendment Deed',
      note: 'The listed name must match the Establishment Deed & Amendment Deed. Ensure that the photo is in color and clearly visible (not blurry)',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.AGREEMENT_COOPERATION]: {
      bannerTitle: 'Cooperation Agreement Letter',
      title: 'Cooperation Agreement Letter',
      description: 'Between the Shipper and another Shipper',
      note: 'Original agreement between Shipper A1 and Shipper A2. Ensure the scanned or photographed uploaded document is clearly visible and easy to read',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.KTP_CUSTOMER]: {
      bannerTitle: 'Customer KTP',
      title: 'Customer KTP',
      description: 'KTP of company officials, board of directors, and commissioners according to the Company Establishment Deed and Amendment Deed',
      note: 'The listed name must match the Establishment Deed & Amendment Deed. Ensure that the photo is in color and clearly visible (not blurry)',
    },
  },
  last_update: 'Last Update Date',
  legal_documents: 'Legal Documents',
  number_document_rejected: '{{documentCount}} doc. not suitable',
  number_document_resubmitted: '{{documentCount}} doc. re-uploaded',
  main_documents: 'Main Documents',
  mandatory: 'Mandatory',
  max_upload_count_size_extension: 'Max. {{maxFileCount}} files with each max size {{maxFileSize}}. Format {{fileExtensions}}',
  maximum_file_upload_count: 'Maximum upload {{maxFileCount}} files',
  maximum_file_size_with_extensions: 'Format {{fileExtensions}} with max size {{maxFileSize}}',
  my_documents: 'My Documents',
  next_tab: 'Next Tab',
  no: 'No',
  not_uploaded: 'Not Uploaded',
  open: 'Open',
  payment_project_submitted_successfully: 'Fast Payment Project successfully submitted',
  project_onboarding_steps: {
    title: 'Fast Payment Program',
    next: 'Next',
    previous: 'Previous',
    1: {
      title: 'Benefits of Kargo Fast Payment',
      subSteps: {
        1: {
          title: 'Quick Invoice Cash from Top Customers',
          description: 'Kargo can cash invoices within 1-3 working days after the invoice is approved.',
        },
        2: {
          title: 'Affordable Interest Rates',
          description: 'Competitive and not burdensome interest rates. Discuss the terms directly with the Kargo team.',
        },
        3: {
          title: 'Easy and Fast Registration',
          description: 'You can access and fill out the online submission form anytime with guidance from the Kargo team',
        },
      },
    },
    2: {
      title: '3 Easy Steps to Register for Fast Payment',
      subSteps: {
        1: {
          title: 'Fill in Company Details with Your Project',
          description: 'Enter your customer data, then attach legal and financial documents.',
        },
        2: {
          title: 'Submission Verification',
          description: 'Follow the document verification steps and wait for verification results from the Kargo team.',
        },
        3: {
          title: 'Receive Payment',
          description: 'Receive 80% of your invoice value within 1-3 working days.',
        },
      },
    },
    3: {
      title: '2 Disbursement Methods to Choose from',
      subSteps: {
        1: {
          title: 'DOR (Delivery Order Referral)',
          description: 'Disbursement method based on DO/Shipment currently running with the customer and with a disbursement division of 50%, 30%, 20%',
        },
        2: {
          title: 'PODR (Proof of Delivery Referral)',
          description: 'Disbursement method based on the invoice you create for the customer and with a disbursement division of 80%, 20%',
        },
      },
    },
    4: {
      title: '2 Fast Payment Schemes to Choose from',
      subSteps: {
        1: {
          title: 'Based on Delivery Note',
          description: 'Fast payment supported by Kargo. For new delivery projects that need funding and have never applied for the fast payment program. Where Kargo is directly related to your Customer and the Transporter acts as a vendor of Kargo',
        },
        2: {
          title: 'Based on Invoice',
          description: 'Fast payment with P2P Lending support. For delivery projects already collaborating between the Transporter and Shipper through Kargo. Where Kargo is not directly related to your Customer.',
        },
      },
    },
  },
  ok_text: 'Yes, Register',
  other_documents: 'Other Documents',
  payment_project_list: 'Payment Project List',
  payment_scheme: 'Payment Scheme',
  payment_scheme_map: {
    [PAYMENT_PROJECT_SCHEME.QUICK_PAY]: 'Quick Pay',
    [PAYMENT_PROJECT_SCHEME.ENTERPRISE_REFERRAL]: 'Enterprise Referral',
  },
  payment_scheme_preference: 'Payment Scheme Preference',
  payment_status_map: {
    [PAYMENT_PROJECT_STATUSES.ACTIVE]: 'Active',
    [PAYMENT_PROJECT_STATUSES.INACTIVE]: 'Inactive',
    [PAYMENT_PROJECT_STATUSES.REJECTED]: 'Rejected',
    [PAYMENT_PROJECT_STATUSES.SUBMITTED]: 'Submitted',
    [PAYMENT_PROJECT_STATUSES.DRAFT]: 'Draft Submission',
    [PAYMENT_PROJECT_STATUSES.UNDER_VERIFICATION]: 'Under Verification',
    [PAYMENT_PROJECT_STATUSES.NEED_REVISION]: 'Needs Revision',
    [PAYMENT_PROJECT_STATUSES.VERIFIED]: 'Verification Successful',
  },
  project: 'Project',
  project_document_status_map: {
    [PROJECT_DOCUMENT_STATUSES.APPROVED]: 'Approved',
    [PROJECT_DOCUMENT_STATUSES.DRAFT]: 'In Process',
    [PROJECT_DOCUMENT_STATUSES.NOT_UPLOADED]: 'Not Uploaded',
    [PROJECT_DOCUMENT_STATUSES.PENDING]: 'Under Assessment',
    [PROJECT_DOCUMENT_STATUSES.REJECTED]: 'Not Suitable',
    [PROJECT_DOCUMENT_STATUSES.REUPLOADED]: 'Re-uploaded Document',
    [PROJECT_DOCUMENT_STATUSES.REQUESTED]: 'Additional Document Request',
    [PROJECT_DOCUMENT_STATUSES.UNDER_VERIFICATION]: 'Under Verification',
  },
  project_request: 'Project Submission',
  proposed_funding: 'Proposed Funding',
  payment_project_details_status_map: {
    [PAYMENT_PROJECT_STATUSES.ACTIVE]: 'Active',
    [PAYMENT_PROJECT_STATUSES.INACTIVE]: 'Inactive',
    [PAYMENT_PROJECT_STATUSES.REJECTED]: 'Rejected',
    [PAYMENT_PROJECT_STATUSES.SUBMITTED]: 'Under Assessment',
    [PAYMENT_PROJECT_STATUSES.UNDER_VERIFICATION]: 'Under Verification',
    [PAYMENT_PROJECT_STATUSES.NEED_REVISION]: 'Needs Revision',
    [PAYMENT_PROJECT_STATUSES.VERIFIED]: 'Verification Successful',
  },
  project_detail: 'Project Detail',
  quick_pay: 'Based on Invoice',
  quick_pay_scheme: 'Scheme Based on Invoice',
  register_project: 'Register Project',
  report_template: 'Report Template',
  required_documents: 'Required Documents',
  resubmit_verification: 'Resubmit',
  reupload: 'Re-upload',
  review: 'Review',
  review_page_info: 'Next, the submission documents will be verified by the Kargo team and you may be asked to upload additional documents if needed. Check all documents again to facilitate the submission process.',
  scheme: 'Scheme {{scheme}}',
  search_shipper_name: 'Search Shipper Name',
  sign_up_now: 'Sign up now',
  size: 'Size',
  some_documents_have_not_been_uploaded: 'Some documents have not been uploaded',
  some_has_not_uploaded: 'Some have not been uploaded',
  skip: 'Skip',
  shared_on: 'Shared on {{date}}',
  shipper_documents: 'Shipper Documents',
  submission_review: 'Submission Review',
  submit: 'Submit',
  submit_verification: 'Submit Verification',
  submitted_limit: 'Proposed Funding',
  time_frame: 'Time Frame',
  transporter_financial_documents: 'Transporter Financial Documents',
  transporter_legal_documents: 'Transporter Legal Documents',
  transporter_main_documents: 'Transporter Main Documents',
  try_quick_payment_service_now: 'Enjoy Fast Payment Service now!',
  update_financing_scheme_title: 'Are you sure you want to change the Payment Scheme Preference?',
  update_financing_scheme_info: 'Some documents you have uploaded will be deleted and you will be required to re-upload new documents',
  upload: 'Upload',
  upload_financial_legal_documents: 'Upload Financial & Legal Documents',
  upload_verify_main_documents: 'Upload & Verify Main Documents',
  upload_customer_documents: 'Upload Customer Documents',
  yes_delete: 'Yes, Delete',
  yes_its_appropriate: 'Yes, It\'s Appropriate',
  yes_update_scheme: 'Yes, Update Scheme',
  your_customer_detail: 'Your Customer Detail',
  your_customer_company_name: 'Your Customer Company Name',
};
