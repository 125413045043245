import { DELIVERY_ORDER_INVOICING_STATUS } from '~/Modules/Payment/constants';

/* eslint-disable max-len */
export default {
  e_materai_fee_info: 'Biaya {{fee}} dipotong otomatis dari total tagihan setelah pajak ketika invoice disetujui',
  e_materai_recommended: 'e-Materai (direkomendasikan)',
  e_materai_waiting_approval: 'e-materai menunggu persetujuan',
  max_selected: 'Hanya boleh memilih maksimal {{max}} item',
  refresh_pdf: 'Muat Ulang PDF',
  signature_missing_info: 'Invoice tanpa e-materai dan tanda tangan digital harus dicetak untuk tanda tangan basah kemudian diunggah kembali setelah ditinjau.',
  signature_only_missing_info: 'Invoice tanpa tanda tangan digital harus dicetak untuk tanda tangan basah kemudian diunggah kembali setelah ditinjau.',
  signature_only_uploaded_info: 'Invoice dengan tanda tangan digital akan langsung ditinjau tanpa harus dicetak dan unggah ulang.',
  signature_recommended: 'Tanda Tangan Digital (direkomendasikan)',
  signature_uploaded_info: 'Invoice dengan e-materai dan tanda tangan digital akan langsung ditinjau tanpa harus dicetak dan unggah ulang.',
  delivery_order_invoicing_status: {
    [DELIVERY_ORDER_INVOICING_STATUS.NOT_INVOICED]: 'Bisa Diinvoice',
    [DELIVERY_ORDER_INVOICING_STATUS.PARTIALLY_INVOICED]: 'Ditagih Sebagian',
    [DELIVERY_ORDER_INVOICING_STATUS.WAITING_FOR_CLAIM_APPROVAL]: 'Claim Sedang Diproses',
    [DELIVERY_ORDER_INVOICING_STATUS.WAITING_FOR_POD]: 'POD Belum Diterima',
    [DELIVERY_ORDER_INVOICING_STATUS.ONGOING_SHIPMENT]: 'Pengiriman Masih Berjalan',
    [DELIVERY_ORDER_INVOICING_STATUS.WAITING_FOR_INVOICE_PAID]: 'Menunggu Pembayaran Shipper',
    [DELIVERY_ORDER_INVOICING_STATUS.EXPIRED_STNK]: 'STNK Kadaluarsa',
  },
};
