import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * A reusable hooks to handle state on URL search parameter.
 * The interface will look similiar to React's `useState`.
 * ***
 * NOTE: currently this hooks will only support string values for the
 * `queryParamObj` value
 * @returns {[Object, (newSearchParams, { isReplace = false } = {}) => void]}
 */
const useSearchParamsState = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const cleanObject = obj => (typeof obj === 'object' && obj !== null
    ? Object.keys(obj).reduce(
      (accumulator, key) => (obj[key] !== undefined
        ? {
          ...accumulator,
          [key]: obj[key],
        }
        : accumulator),
      {},
    )
    : {});

  const queryParamObj = useMemo(() => {
    const params = new URLSearchParams(search);
    const paramObj = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const value of params.keys()) {
      paramObj[value] = params.get(value);
    }
    return cleanObject(paramObj);
  }, [search]);

  const setParams = useCallback(
    (newSearchParams, {
      isReplace = false,
    } = {}) => {
      const cleanSearchParams = cleanObject(newSearchParams);
      const searchParamsString = new URLSearchParams(cleanSearchParams)
        .toString();
      const historyArgs = {
        pathname,
        search: searchParamsString,
      };
      if (isReplace || Object.keys(newSearchParams).length < 1) {
        history.replace(historyArgs);
      } else {
        history.push(historyArgs);
      }
    },
    [history, pathname],
  );

  return [
    queryParamObj,
    setParams,
  ];
};

export default useSearchParamsState;
