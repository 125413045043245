export const DATETIME_FORMAT = 'DD MMM YYYY HH:mm';

export const DELIVERY_MILESTONE_TYPES = {
  MID_MILE: 'MID_MILE',
  FIRST_MILE: 'FIRST_MILE',
};

export const DELIVERY_ORDER_SEARCH_TYPES = {
  DO_NUMBER: 'DO_NUMBER',
  LICENSE_PLATE: 'LICENSE_PLATE',
  CONTAINER_NUMBER: 'CONTAINER_NUMBER',
};

export const E_MATERAI_FEE = 10000;

export const DELIVERY_ORDER_INVOICING_STATUS = {
  NO_FEES: 'NO_FEES',
  EXPIRED_STNK: 'EXPIRED_STNK',
  FULLY_INVOICED: 'FULLY_INVOICED',
  NOT_INVOICED: 'NOT_INVOICED',
  ONGOING_SHIPMENT: 'ONGOING_SHIPMENT',
  PARTIALLY_INVOICED: 'PARTIALLY_INVOICED',
  WAITING_FOR_CLAIM_APPROVAL: 'WAITING_FOR_CLAIM_APPROVAL',
  WAITING_FOR_INVOICE_PAID: 'WAITING_FOR_INVOICE_PAID',
  WAITING_FOR_POD: 'WAITING_FOR_POD',
};

// expected not to be sorted to follow sorting from design
export const INVOICE_SHIPMENT_SELECTION_STATUS_FILTERS = [
  DELIVERY_ORDER_INVOICING_STATUS.NOT_INVOICED,
  DELIVERY_ORDER_INVOICING_STATUS.PARTIALLY_INVOICED,
  DELIVERY_ORDER_INVOICING_STATUS.WAITING_FOR_CLAIM_APPROVAL,
  DELIVERY_ORDER_INVOICING_STATUS.WAITING_FOR_POD,
  DELIVERY_ORDER_INVOICING_STATUS.ONGOING_SHIPMENT,
  DELIVERY_ORDER_INVOICING_STATUS.WAITING_FOR_INVOICE_PAID,
  DELIVERY_ORDER_INVOICING_STATUS.EXPIRED_STNK,
];

export const MAX_SELECTED_INVOICEABLE_SHIPMENTS_COUNT = 200;
