import React, { lazy, useContext } from 'react';
import {
  Redirect,
  Switch,
} from 'react-router-dom';
import { useFlag } from '@unleash/proxy-client-react';
import { useTranslation } from 'react-i18next';
import { PrivateRoute } from '~/Components/Route';
import { ProfileContext } from '~/Contexts/ProfileProvider';
import { NftModulesContext } from '~/Contexts/NftModulesProvider';
import { ACCESS_PRIVILEGE } from '~/Configurations/accessPrevillegeMap';
import { AVAILABLE_NFT_MODULES } from '../constants';

const CompanyDetails = lazy(() => import(/* webpackChunkName: "CompanyDetails" */
  '~/Modules/Company/Pages/CompanyDetails/CompanyDetails'
));
const PbiPartnershipForm = lazy(() => import(/* webpackChunkName: "PbiPartnershipForm" */
  '~/Modules/Company/Pages/PbiPartnershipForm/PbiPartnershipForm'
));
const Employees = lazy(() => import(/* webpackChunkName: "Employees" */
  '~/Modules/Company/Pages/Employees/Employees'
));
const Invoice = lazy(() => import(/* webpackChunkName: "Invoice" */
  '~/Modules/Company/Pages/Invoice/Invoice'
));
const Drivers = lazy(() => import(/* webpackChunkName: "Drivers" */
  '~/Modules/Company/Pages/Drivers/Drivers'
));
const DriverDetail = lazy(() => import(/* webpackChunkName: "DriverDetail" */
  '~/Modules/Company/Pages/DriverDetail/DriverDetail'
));
const Utilization = lazy(() => import(/* webpackChunkName: "Utilization" */
  '~/Modules/Company/Pages/Utilization/Utilization'
));
const Vehicles = lazy(() => import(/* webpackChunkName: "Vehicles" */
  '~/Modules/Company/Pages/Vehicles/Vehicles'
));
const VehicleDetails = lazy(() => import(/* webpackChunkName: "VehicleDetails" */
  '~/Modules/Company/Pages/VehicleDetails/VehicleDetails'
));

const IS_DANTMS = window.DOMAIN === 'dantms.com';

export default function CompanyRoutes() {
  const { t } = useTranslation();
  const PBI_REGISTRATION = useFlag('TC-5507_PBI_REGISTRATION');
  const INVOICE_GENERATION = useFlag('TC-5379_INVOICE-GENERATION');
  const {
    isAuthorizedToAccess,
  } = useContext(ProfileContext);
  const {
    [AVAILABLE_NFT_MODULES.ANALYTICS]: showAnalytics,
  } = useContext(NftModulesContext);

  const companyPageTabNavigation = [
    isAuthorizedToAccess(ACCESS_PRIVILEGE.COMPANY_DETAILS_READ) && {
      path: '/company/details',
      title: t('common:company_details'),
    },
    isAuthorizedToAccess(ACCESS_PRIVILEGE.TRANSPORTER_LIST_READ) && {
      path: '/company/transporter-list',
      title: t('common:transporter'),
    },
    {
      path: '/company/employees',
      title: t('common:employees'),
    },
    {
      path: '/company/vehicles',
      title: t('common:vehicles'),
    },
    {
      path: '/company/drivers',
      title: t('common:drivers'),
    },
    (INVOICE_GENERATION && {
      path: '/company/invoice',
      title: t('common:invoice'),
    }),
    (showAnalytics && ({
      path: '/company/utilization',
      title: t('common:utilization'),
    })),
  ].filter(Boolean);

  return (
    <Switch>
      <PrivateRoute
        exact={true}
        path="/company/details"
        tabNavigation={companyPageTabNavigation}
        title={t('common:company_settings')}
      >
        <CompanyDetails />
      </PrivateRoute>
      {PBI_REGISTRATION && !IS_DANTMS && (
        <PrivateRoute
          exact={true}
          path="/company/pbi-partnership"
        >
          <PbiPartnershipForm />
        </PrivateRoute>
      )}
      <PrivateRoute
        exact={true}
        path="/company/employees"
        tabNavigation={companyPageTabNavigation}
        title={
          isAuthorizedToAccess(
            ACCESS_PRIVILEGE.TRANSPORTER_LIST_READ,
          )
            ? t('common:transporter_list')
            : t('common:company_settings')
        }
      >
        <Employees />
      </PrivateRoute>
      {INVOICE_GENERATION && (
      <PrivateRoute
        exact={true}
        path="/company/invoice"
        tabNavigation={companyPageTabNavigation}
        title={t('common:company_settings')}
      >
        <Invoice />
      </PrivateRoute>
      )}
      <PrivateRoute
        exact={true}
        path="/company/vehicles"
        tabNavigation={companyPageTabNavigation}
        title={
          isAuthorizedToAccess(
            ACCESS_PRIVILEGE.TRANSPORTER_LIST_READ,
          )
            ? t('common:transporter_list')
            : t('common:company_settings')
        }
      >
        <Vehicles />
      </PrivateRoute>
      <PrivateRoute
        exact={true}
        path="/company/vehicle-details/:vehicleKsuid"
      >
        <VehicleDetails />
      </PrivateRoute>
      <PrivateRoute
        exact={true}
        path="/company/drivers"
        tabNavigation={companyPageTabNavigation}
        title={
          isAuthorizedToAccess(
            ACCESS_PRIVILEGE.TRANSPORTER_LIST_READ,
          )
            ? t('common:transporter_list')
            : t('common:company_settings')
        }
      >
        <Drivers />
      </PrivateRoute>
      {showAnalytics && (
        <PrivateRoute
          exact={true}
          path="/company/utilization"
          tabNavigation={companyPageTabNavigation}
          title={
            isAuthorizedToAccess(
              ACCESS_PRIVILEGE.TRANSPORTER_LIST_READ,
            )
              ? t('common:transporter_list')
              : t('common:company_settings')
          }
        >
          <Utilization />
        </PrivateRoute>
      )}
      <PrivateRoute
        exact={true}
        path="/company/transporter/employees/:companyKsuid"
        skipTabNavigationSetup={true}
      >
        <Employees />
      </PrivateRoute>
      <PrivateRoute
        exact={true}
        path="/company/transporter/vehicles/:companyKsuid"
        skipTabNavigationSetup={true}
      >
        <Vehicles />
      </PrivateRoute>
      <PrivateRoute
        exact={true}
        path="/company/transporter/drivers/:companyKsuid"
        skipTabNavigationSetup={true}
      >
        <Drivers />
      </PrivateRoute>
      <Redirect
        exact={true}
        from="/company"
        to={
          isAuthorizedToAccess(
            ACCESS_PRIVILEGE.TRANSPORTER_LIST_READ,
          )
            ? '/company/transporter-list'
            : '/company/details'
        }
      />
      <PrivateRoute
        exact={true}
        path="/company/driver-details/:driverKsuid"
      >
        <DriverDetail />
      </PrivateRoute>
    </Switch>
  );
}
