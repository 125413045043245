import moment from 'moment';

/**
 * Returns formatted file size from KB to higher unit size like MB,
 * Set default decimal 2 so it will still have 2 digits behind the file size
 * IsSizeByte to determine whether the formatter needs to format size from bytes or not
 * @param {number} bytes
 * @param {number} decimals
 * @param {boolean} isSizeByte
 */
export function formatFileSize(bytes, decimals = 2, isSizeByte = false) {
  if (bytes === 0) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    .filter(el => (!isSizeByte ? el !== 'Bytes' : el));

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

/**
 * Returns difference result from start date to end date
 * as milliseconds, seconds, minutes, hours, days, months, years depends on the format unit need
 * @param {string} start
 * @param {string} end
 * @param {string} formatTimeUnit
 */

export function formatCountDaysFromEndToStartDate(start, end, formatTimeUnit = 'days') {
  const formatStartDate = moment(start);
  const formatEndDate = moment(end);
  return formatEndDate.diff(formatStartDate, formatTimeUnit);
}

/**
 * Returns whether the value is contain Number or not
 * @param {string} value
 */

export const hasNumberOnly = value => {
  const numValidation = /^-?\d+$/;
  return numValidation.test(value);
};

/**
 * Returns date to format YYYY-MM-DD (ex: 2022-09-21)
 * @param {string} date
 */

export const convertDateToYearMonthDayDecimal = date => moment(date).format('YYYY-MM-DD');

export function convertTimeToDate(time) {
  return moment(time).format('DD MMM YYYY');
}

/* eslint-disable no-useless-escape */
export const getTimeZone = () => new Date().toString().match(/([-\+][0-9]+)\s/)[1];

export const sequenceToChar = num => {
  if (num < 1 || num > 26 || typeof num !== 'number') {
    return -1;
  }
  const leveller = 64;
  // since actually A is represented by 65 and we want to represent it with one
  return String.fromCharCode(num + leveller);
};

export default {};
