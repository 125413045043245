/* eslint-disable max-len */

import {
  FINANCING_DOCUMENT_GROUP,
  QUICK_PAY_FINANCING_DOCUMENT_TYPE,
  ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE,
  PAYMENT_PROJECT_STATUSES,
  PAYMENT_PROJECT_SCHEME,
  PROJECT_DOCUMENT_STATUSES,
} from '~/Configurations/constants';

export default {
  access_private_link: 'Akses Private Link',
  access_private_link_expired_info: 'Private Link Anda kedaluwarsa. Silahkan request ke admin Kargo untuk mendapatkan private link baru.',
  access_private_link_info: 'Dokumen rahasia. Tolong rahasiakan password yang diberikan',
  additional_document: 'Dokumen Tambahan',
  action: 'Action',
  available_until: 'Link Dokumen tersedia hingga {{ dateTime }}, jam 23:59',
  averages_project_value: 'Rata-rata Nilai Pekerjaan',
  back: 'Kembali',
  cancel: 'Batalkan',
  cancel_text: 'Simpan data saja',
  change: 'Ubah',
  check_document_suitablity_again: 'Cek Kembali Kesesuaian Dokumen',
  check_document_suitable_info: 'Kelengkapan dokumen akan diperiksa oleh pihak Kargo dan Anda mungkin akan diminta untuk mengunggah dokumen tambahan. Cek kembali semua dokumen untuk memperlancar proses pengajuan.',
  continue: 'Lanjutkan',
  customer_data: 'Data Customer',
  customer_detail: 'Detail Customer',
  customer_documents: 'Dokumen Customer',
  customer_name: 'Name Customer',
  data_that_has_been_filled_in_will_still_be_stored: 'Data yang sudah diisi hanya akan tersimpan jika Anda sudah pernah melakukan pengajuan sebelumnya',
  dialog_title: 'Apakah ingin mendaftarkan customer sebagai Program Pembayaran Cepat?',
  dialog_body: 'Data customer Anda tetap tersimpan dan Anda perlu melampirkan beberapa dokumen untuk pendaftaran program pembayaran cepat.',
  description: 'Keterangan',
  draft_project_deleted_successfully: 'Draft Proyek Pembayaran berhasil dihapus',
  document_deleted_successfully: 'File dokumen berhasil dihapus',
  document_delete_failed: 'Gagal menghapus file dokumen, {{error}}',
  document_example: 'Contoh Dokumen',
  document_saved_successfully: 'Dokumen berhasil disimpan',
  document_status: 'Dokumen Status',
  document_verification_successfully_submitted: 'Verifikasi untuk Dokumen Utama berhasil diajukan!',
  download: 'Unduh',
  download_all: 'Unduh Semua',
  enter_access_password: 'Masukkan password akses',
  page_change_warning_description: 'Tetap lanjutkan proses untuk menyelesaikan.',
  page_change_warning_title: 'Anda dalam proses upload dokumen',
  enjoy_fast_payment_service_now: 'Nikmati layanan Pembayaran Cepat sekarang!',
  enterprise_referral: 'Berdasarkan Surat Jalan',
  enterprise_referral_scheme: 'Skema Berdasarkan Surat Jalan',
  fast_payment_project_approved_status_info: 'Limit yang di setujui {{approvedLimit}} oleh Kargo dengan TOP (Terms of Payment) dalam {{approvedTermsOfPayment}} hari',
  fast_payment_project_rejected_status_info: 'Alasan Tidak Ada. Mohon hubungi tim sales',
  fast_payment_project_status: {
    [PAYMENT_PROJECT_STATUSES.REJECTED]: 'Pembayaran Cepat Ditolak',
    [PAYMENT_PROJECT_STATUSES.ACTIVE]: 'Terdaftar Pembayaran Cepat {{scheme}} - {{approvedPrograms}}',
  },
  file_delete_confirmation_message: 'Yakin menghapus file ini?',
  file_size_mb: '{{fileSize}}MB',
  file_upload_description: 'Drag & drop file ke kotak ini, atau',
  financial_documents: 'Dokumen Finansial',
  financing_document_group_map: {
    [FINANCING_DOCUMENT_GROUP.CUSTOMER]: 'Dokumen Customer',
    [FINANCING_DOCUMENT_GROUP.TRANSPORTER_FINANCIAL]: 'Dokumen Finansial {{companyName}}',
    [FINANCING_DOCUMENT_GROUP.TRANSPORTER_LEGAL]: 'Dokumen Legal {{companyName}}',
    [FINANCING_DOCUMENT_GROUP.MAIN]: 'Dokumen Utama {{companyName}}',
    [FINANCING_DOCUMENT_GROUP.LEGAL]: 'Dokumen Legal {{companyName}}',
    [FINANCING_DOCUMENT_GROUP.FINANCIAL]: 'Dokumen Finansial {{companyName}}',
  },
  finance_project_status_info_map: {
    [PAYMENT_PROJECT_STATUSES.ACTIVE]: 'Aktif',
    [PAYMENT_PROJECT_STATUSES.INACTIVE]: 'Tidak Aktif',
    [PAYMENT_PROJECT_STATUSES.REJECTED]: 'Ditolak',
    [PAYMENT_PROJECT_STATUSES.SUBMITTED]: 'Dalam proses assessment',
  },
  go_out: 'Keluar',
  if_any: 'Jika Ada',
  if_any_bracket: '(Jika ada)',
  input_averages_project_value: 'Masukkan rata-rata nominal invoice pekerjaan yang ditagihkan',
  input_customer_company_name: 'Masukan nama perusahaan customer Anda',
  input_customer_company_address: 'Masukkan alamat lengkap customer Anda',
  input_invoice_amount: 'Masukkan nominal invoice yang ditagihkan',
  invoice_amount: 'Nilai Invoice',
  keep_continue: 'Tetap Lanjutkan',
  landing_another_transporter_exp: 'Pengalaman Transporter Lainnya',
  landing_contact_us: 'Hubungi Kami',
  landing_for_more_info: 'untuk info lebih lanjut',
  landing_for_quick_payment_services_kargo_cooperates_with: 'Untuk layanan Pembayaran Cepat, Kargo bekerjasama dengan lembaga-lembaga keuangan yang berlisensi dan diawasi oleh OJK',
  landing_four_easy_submission_steps: '4 Langkah Mudah Pengajuan',
  landing_increase_cash_flow: 'Tingkatkan <em>cash flow</em> perusahaan Anda dengan mendapatkan pembayaran tanpa tunggu pelunasan oleh Customer',
  landing_quick_pay_benefits: 'Manfaat Pembayaran Cepat',
  landing_quick_pay_signup: 'Daftar Pembayaran Cepat',
  landing_quick_pay_your_financial_solution: 'Layanan Pembayaran Cepat Kargo, Solusi Keuangan Anda',
  learn_scheme: 'Pelajari Skema',
  payment_project_saved_as_draft: 'Proyek Pembayaran Cepat disimpan sebagai draft',
  payment_scheme_tnc: 'Transporter dengan ini bersedia dan memberikan izin kepada Panthera untuk melakukan pengecekan terhadap dirinya dan/atau perusahaannya atas setiap dan seluruh performa kemampuan pembayaran yang Panthera lakukan melalui institusi resmi yang diakui di Indonesia.',
  quick_pay_financing_document_map: {
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.NPWP_COMPANY]: {
      bannerTitle: 'NPWP Perusahaan',
      title: 'NPWP Perusahaan Transporter',
      note: 'Pastikan dokumen berwarna, terlihat jelas (tidak buram), dan mudah dibaca ',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.AKTA_PENDIRIAN]: {
      bannerTitle: 'Akta Pendirian Perusahaan Beserta Pengesahan dari Kemenkumham',
      title: 'Akta Pendirian Perusahaan',
      note: 'Pastikan dokumen berwarna, terlihat jelas (tidak buram), dan mudah dibaca',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.SK_KEMENKUMHAM]: {
      bannerTitle: 'SK Kemenkumham sesuai Akta Pendirian Perusahaan',
      title: 'SK Kemenkumham',
      description: 'Wajib asli & terbaru sesuai Akta Pendirian Perusahaan',
      note: 'Harus sesuai dengan Akta Pendirian Perusahaan',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.AKTA_PERUBAHAN_TERAKHIR]: {
      bannerTitle: 'Akta Perubahan Perusahaan Terakhir Beserta Pengesahan dari Kemenkumham',
      title: 'Akta Perubahan (Terakhir)',
      note: 'Hanya perlu dilampirkan jika memiliki Akta Perubahan Perusahaan karena terjadi pergantian direksi atau hal yang berkaitan dengan internal perusahaan',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.AKTA_PERUBAHAN_PERUSAHAAN]: {
      bannerTitle: 'Akta Perubahan Perusahaan (Semua) Beserta Pengesahan dari Kemenkumham',
      title: 'Akta Perubahan (Semua)',
      note: 'Jika pendirian sudah lebih dari 5 tahun, terjadi pergantian direksi, atau hal yang berkaitan dengan internal perusahaan, wajib menyertakan Akta Perubahan Perusahaan Terakhir',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.SK_KEMENKUMHAM_AKTA_PERUBAHAN]: {
      bannerTitle: 'SK Kemenkumham sesuai Akta Pendirian Perusahaan',
      title: 'SK Kemenkumham dari Akta Perubahan',
      description: 'Wajib asli & terbaru sesuai Akta Pendirian Perusahaan',
      note: 'Harus sesuai dengan Akta Pendirian Perusahaan',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.NPWP_CUSTOMER]: {
      bannerTitle: 'NPWP Perusahaan',
      title: 'NPWP Perusahaan dari Customer',
      description: 'Wajib asli dan berwarna',
      note: 'Pastikan dokumen unggahan berwarna, dapat terlihat jelas, dan mudah dibaca ',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.BANK_STATEMENT]: {
      bannerTitle: 'Rekening Koran 6 Bulan Terakhir',
      title: 'Rekening Koran (Bank Statement)',
      note: 'Pastikan semua transaksi terlihat jelas dalam laporan bank. Jika ada transaksi yang dilakukan melalui rekening bank pribadi, wajib melampirkan dari rekening bank pribadi',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.INVOICE_EXAMPLE]: {
      bannerTitle: 'Riwayat Invoice Customer Lunas dalam 6 Bulan Terakhir',
      title: 'Riwayat Invoice Customer',
      note: 'Lampirkan min. 5 invoice yang sudah dibayarkan. Pastikan semua invoice sudah dicap atau ditandatangani, dan sesuai dengan daftar transaksi rekening antara Transporter dengan Customer',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.SALES_REPORT]: {
      bannerTitle: 'Laporan Penjualan (Sales Report) wajib terbaru 6 bulan terakhir',
      title: 'Laporan Penjualan (Sales Report)',
      description: 'Wajib terbaru 6 bulan terakhir',
      note: 'Pastikan laporan disimpan dengan format PDF',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.INCOME_STATEMENT]: {
      bannerTitle: 'Laporan Laba Rugi (Income Statement) wajib terbaru 6 bulan terakhir',
      title: 'Laporan Laba Rugi (Income Statement)',
      description: 'Wajib terbaru 6 bulan terakhir',
      note: 'Lihat contoh template laporan yang sudah tersedia di kanan',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.BALANCE_SHEET]: {
      bannerTitle: 'Neraca (Balance Sheet) wajib terbaru 6 bulan terakhir',
      title: 'Neraca (Balance Sheet)',
      description: 'Wajib terbaru 6 bulan terakhir',
      note: 'Lihat contoh template laporan yang sudah tersedia di kanan',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.CASH_FLOW_STATEMENT]: {
      bannerTitle: 'Laporan Arus Kas (Cash Flow Statement) wajib terbaru 6 bulan terkahir',
      title: 'Laporan Arus Kas (Cash Flow Statement)',
      description: 'Wajib terbaru 6 bulan terakhir',
      note: 'Pastikan laporan disimpan dengan format PDF',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.DEBT_REPORT]: {
      bannerTitle: 'Laporan Daftar Hutang & Piutang',
      title: 'Laporan Daftar Hutang & Piutang',
      note: 'Wajib dalam 6 bulan terakhir dan sesuai dengan usia hutang',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.CREDIT_REPORT]: {
      bannerTitle: 'Laporan Daftar Piutang',
      title: 'Laporan Daftar Piutang',
      description: 'Wajib terbaru 6 bulan terakhir',
      note: 'Wajib dalam 6 bulan terakhir dan sesuai dengan usia piutang',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.NIB_OR_TDP]: {
      bannerTitle: 'NIB (Nomor Induk Berusaha) Beserta Lampirannya',
      title: 'NIB (Nomor Induk Berusaha)',
      note: 'Dokumen NIB beserta lampirannya yang diterbitkan oleh Sistem OSS dari Kementerian Investasi/BKPM. Lampiran seperti kode KLBI, jenis perdangan atau yang serupa',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.KTP_NPWP_DIRECTOR]: {
      bannerTitle: 'KTP & NPWP Pengurus Perusahaan (Terdaftar dalam Akta Perubahan Terakhir)',
      title: 'KTP & NPWP Pengurus Perusahaan ',
      note: 'Nama yang tertera harus sesuai dengan Akta Pendirian & Akta Perubahan. Pastikan foto KTP dan NPWP pengurus berwarna dan terlihat jelas (tidak buram)',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.KTP_NPWP_COMMISSIONER]: {
      bannerTitle: 'KTP & NPWP Komisaris',
      title: 'KTP & NPWP Komisaris',
      description: 'Wajib berwarna, sesuai Akta Pendirian dan Akta Perubahan',
      note: 'Nama yang tertera sesuai dengan Akta Pendirian & Akta Perubahan. Pastikan foto berwarna dan terlihat jelas (tidak buram)',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.AGREEMENT_COOPERATION]: {
      bannerTitle: 'Surat Perjanjian Kerjasama',
      title: 'Surat Perjanjian Kerjasama',
      description: 'Antara pihak Transporter dengan pihak Customer',
      note: 'Perjanjian asli antara pihak Transporter dengan pihak Shipper. Pastikan dokumen unggahan yang discan atau difoto dapat terlihat jelas dan mudah dibaca',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.AGREEMENT_CREDIT_CHECK]: {
      bannerTitle: 'Surat Persetujuan Pemeriksaan Kredit',
      title: 'Surat Persetujuan Pemeriksaan Kredit',
      description: 'Wajib menyertakan surat asli dengan materai Rp10.000 & tanda tangan',
      note: 'Wajib menyertakan surat asli dengan materai Rp10.000 & tanda tangan',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.SIUP]: {
      bannerTitle: 'SIUP (Surat Izin Usaha Perdagangan) atau TDP',
      title: 'SIUP (Surat Izin Usaha Perdagangan) atau TDP',
      note: 'Dokumen yang masih berlaku dalam 5 tahun terakhir dan diterbitkan oleh Sistem OSS Kementerian Investasi/BKPM atau Tanda Daftar Perusahaan (TDP) yang masih berlaku ',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.SKDU]: {
      bannerTitle: 'SKDU (Surat Keterangan Domisili Usaha)',
      title: 'SKDU (Surat Keterangan Domisili Usaha)',
      note: 'Dokumen asli yang masih berlaku dan dikeluarkan oleh kecamatan setempat',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.BUKTI_PELUNASAN_HUTANG]: {
      bannerTitle: 'Bukti Pelunasan Hutang',
      title: 'Bukti Pelunasan Hutang',
      note: 'Wajib dokumen asli dan disertai dengan Surat Bukti Lunas yang diberikan oleh instansi peminjam',
    },
    [QUICK_PAY_FINANCING_DOCUMENT_TYPE.FINANCIAL_REPORT]: {
      bannerTitle: 'Laporan Keuangan 2 Tahun Terakhir dan 6 Bulan Terakhir',
      title: 'Laporan Keuangan (Financial Report)',
      note: 'Sudah termasuk Laporan Laba Rugi, Neraca, dan Laporan Arus Kas. Lampirkan laporan dalam 2 periode yang berbeda, yaitu 2 tahun terakhir dan 6 bulan terakhir',
    },
  },
  enterprise_referral_financing_document_map: {
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.NPWP_CUSTOMER]: {
      bannerTitle: 'NPWP Perusahaan',
      title: 'NPWP Perusahaan dari Customer',
      description: 'Wajib asli dan berwarna',
      note: 'Pastikan dokumen unggahan berwarna, dapat terlihat jelas, dan mudah dibaca ',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.NPWP_COMPANY]: {
      bannerTitle: 'NPWP Perusahaan',
      title: 'NPWP Perusahaan',
      description: 'Wajib berwarna sesuai Akta Pendirian Perusahaan dan Akta Perubahan',
      note: 'Pastikan dokumen unggahan berwarna, dapat terlihat jelas, dan mudah dibaca ',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.AKTA_PENDIRIAN]: {
      bannerTitle: 'Akta Pendirian Perusahaan',
      title: 'Akta Pendirian Perusahaan',
      description: 'Wajib terbaru 5 tahun terakhir',
      note: 'Jika pendirian sudah lebih dari 5 tahun atau terjadi pergantian direksi, organisasi atau hal yang berkaitan dengan internal perusahaan, diwajibkan menyertakan Akta Perubahan Perusahaan',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.SK_KEMENKUMHAM]: {
      bannerTitle: 'SK Kemenkumham sesuai Akta Pendirian Perusahaan',
      title: 'SK Kemenkumham',
      description: 'Wajib asli & terbaru sesuai Akta Pendirian Perusahaan',
      note: 'Harus sesuai dengan Akta Pendirian Perusahaan',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.AKTA_PERUBAHAN_PERUSAHAAN]: {
      bannerTitle: 'Akta Perubahan Perusahaan',
      title: 'Akta Perubahan Perusahaan',
      description: 'Wajib terbaru & apabila ada perubahan/ pergantian kepengurusan perusahaan',
      note: 'Jika pendirian sudah lebih dari 5 tahun atau terjadi pergantian direksi, organisasi atau hal yang berkaitan dengan internal perusahaan, diwajibkan menyertakan Akta Perubahan Perusahaan terakhir/terbaru',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.SK_KEMENKUMHAM_AKTA_PERUBAHAN]: {
      bannerTitle: 'SK Kemenkumham sesuai Akta Perubahan Perusahaan',
      title: 'SK Kemenkumham dari Akta Perubahan',
      description: 'Wajib asli & terbaru sesuai Akta Perubahan Perusahaan',
      note: 'Harus sesuai dengan Akta Perubahan Perusahaan yang terbaru',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.BANK_STATEMENT]: {
      bannerTitle: 'Rekening Koran wajib terbaru 6 bulan terakhir',
      title: 'Rekening Koran (Bank Statement)',
      description: 'Wajib terbaru dalam 6 bulan terakhir',
      note: 'Pastikan semua transaksi terlihat jelas dalam laporan bank. Jika ada transaksi yang dilakukan melalui rekening bank pribadi, wajib melampirkan dari rekening bank pribadi',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.INVOICE_EXAMPLE]: {
      bannerTitle: 'Contoh Invoice atau Bukti Bayar wajib terbaru 6 bulan terakhir',
      title: 'Contoh Invoice atau Bukti Bayar',
      description: 'Terbaru dalam 6 bulan terakhir dengan Shipper A1-A2',
      note: 'Lampirkan minimal 5 invoice untuk beberapa DO. Pastikan semua bukti bayar sudah dicap atau ditanda tangan & sesuai dengan daftar transaksi rekening antara Transporter dengan Shipper',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.FINANCIAL_STATEMENT]: {
      bannerTitle: 'Laporan Keuangan 6 bulan terakhir',
      title: 'Laporan Keuangan (Financial Statement)',
      description: 'Wajib terbaru dalam 6 bulan terakhir',
      note: 'Isi laporan yang sudah di-audit termasuk Laporan Penjualan (Sales Report), Laporan Laba Rugi (Income Statement), Neraca (Balance Sheet), dan Laporan Arus Kas (Cash Flow Statement)',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.AGREEMENT_CUSTOMER_COOPERATION]: {
      bannerTitle: 'Laporan Keuangan 6 bulan terakhir',
      title: 'Surat Perjanjian Kerjasama dengan Customer',
      description: 'Antara pihak Shipper A1 dengan A2',
      note: 'Isi laporan yang sudah di-audit termasuk Laporan Penjualan (Sales Report), Laporan Laba Rugi (Income Statement), Neraca (Balance Sheet), dan Laporan Arus Kas (Cash Flow Statement)',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.NIB_OR_TDP]: {
      bannerTitle: 'NIB atau TDP',
      title: 'NIB atau TDP',
      description: 'Wajib yang masih berlaku',
      note: 'Dokumen Nomor Induk Berusaha (NIB) yang diterbitkan oleh Sistem OSS dari Kementerian Investasi/BKPM atau Tanda Daftar Perusahaan (TDP) yang masih berlaku ',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.SIUP]: {
      bannerTitle: 'SIUP (Surat Izin Usaha Perdagangan)',
      title: 'SIUP (Surat Izin Usaha Perdagangan)',
      description: 'Atau Surat Izin Usaha yang masih berlaku',
      note: 'Dokumen yang masih berlaku dalam 5 tahun terakhir dan diterbitkan oleh Sistem OSS Kementerian Investasi/BKPM',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.SKDU]: {
      bannerTitle: 'SKDU (Surat Keterangan Domisili Usaha)',
      title: 'SKDU (Surat Keterangan Domisili Usaha)',
      description: 'Atau Surat Izin Lokasi yang masih berlaku',
      note: 'Dokumen asli yang masih berlaku dan dikeluarkan oleh kecamatan setempat',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.KTP_NPWP_DIRECTOR]: {
      bannerTitle: 'KTP & NPWP Direktur',
      title: 'KTP & NPWP Direktur',
      description: 'Wajib berwarna, sesuai Akta Pendirian dan Akta Perubahan',
      note: 'Nama yang tertera sesuai dengan Akta Pendirian & Akta Perubahan. Pastikan foto berwarna dan terlihat jelas (tidak buram)',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.KTP_NPWP_COMMISSIONER]: {
      bannerTitle: 'KTP & NPWP Komisaris',
      title: 'KTP & NPWP Komisaris',
      description: 'Wajib berwarna sesuai Akta Pendirian dan Akta Perubahan',
      note: 'Nama yang tertera sesuai dengan Akta Pendirian & Akta Perubahan. Pastikan foto berwarna dan terlihat jelas (tidak buram)',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.AGREEMENT_COOPERATION]: {
      bannerTitle: 'Surat Perjanjian Kerjasama',
      title: 'Surat Perjanjian Kerjasama',
      description: 'Antara pihak Shipper dengan pihak Shipper lain',
      note: 'Perjanjian asli antara pihak Shipper A1 dengan Shipper A2. Pastikan dokumen unggahan yang discan atau difoto dapat terlihat jelas dan mudah dibaca',
    },
    [ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE.KTP_CUSTOMER]: {
      bannerTitle: 'KTP Customer',
      title: 'KTP Customer',
      description: 'KTP pengurus perusahaan, dewan direksi, dan komisaris sesuai Akta Pendirian Perusahaan dan Akta Perubahan',
      note: 'Nama yang tertera sesuai dengan Akta Pendirian & Akta Perubahan. Pastikan foto berwarna dan terlihat jelas (tidak buram)',
    },
  },
  last_update: 'Tanggal Update',
  legal_documents: 'Dokumen Legal',
  number_document_rejected: '{{documentCount}} dok. tidak sesuai',
  number_document_resubmitted: '{{documentCount}} dok. diupload ulang',
  main_documents: 'Dokumen Utama',
  mandatory: 'Wajib',
  max_upload_count_size_extension: 'Maks. {{maxFileCount}} file dengan ukuran masing-masing maks. {{maxFileSize}}. Format {{fileExtensions}}',
  maximum_file_upload_count: 'Unggahan maksimal {{maxFileCount}} file',
  maximum_file_size_with_extensions: 'Format {{fileExtensions}} dengan ukuran maks. {{maxFileSize}}',
  my_documents: 'Dokumen Saya',
  next_tab: 'Tab Selanjutnya',
  no: 'No',
  not_uploaded: 'Tidak diunggah',
  open: 'Buka',
  payment_project_submitted_successfully: 'Proyek Pembayaran Cepat berhasil diajukan',
  project_onboarding_steps: {
    title: 'Program Pembayaran Cepat',
    next: 'Selanjutnya',
    previous: 'Sebelumnya',
    1: {
      title: 'Manfaat Pembayaran Cepat Kargo',
      subSteps: {
        1: {
          title: 'Invoice Cepat Cair dari ToP Customers',
          description: 'Kargo bisa cairkan invoice 1-3 hari kerja setelah invoice disetujui.',
        },
        2: {
          title: 'Bunga Terjangkau',
          description: 'Bunga kompetitif dan tidak memberatkan. Diskusikan langsung ketentuannya dengan tim Kargo.',
        },
        3: {
          title: 'Registrasi mudah dan cepat',
          description: 'Bisa akses dan isi formulir pengajuan online kapan saja dengan panduan dari tim Kargo',
        },
      },

    },
    2: {
      title: '3 Langkah Mudah Daftarkan Pembayaran Cepat',
      subSteps: {
        1: {
          title: 'Isi Detail Perusahaan dengan Proyek Anda',
          description: 'Masukkan data customer Anda, kemudian lampirkan dokumen legalitas dan dokumen finansial.',
        },
        2: {
          title: 'Verifikasi Pengajuan',
          description: 'Ikuti tahapan verifikasi dokumen dan tunggu hasil verifikasi dari tim Kargo.',
        },
        3: {
          title: 'Terima Pembayaran',
          description: 'Cairkan 80% dari nilai invoice Anda dalam 1-3 hari kerja.',
        },
      },
    },
    3: {
      title: '2 Metode Pencairan yang bisa dipilih',
      subSteps: {
        1: {
          title: 'DOR ( Delivery Order Referral )',
          description: 'Metode pencairan berdasarkan DO / Pengiriman yang sedang berjalan dengan customer dan dengan pembagian pencairan 50%, 30%, 20%',
        },
        2: {
          title: 'PODR ( Proof of Delivery Referral )',
          description: 'Metode pencairan berdasarkan Invoice yang Anda buat untuk customer dan dengan pembagian pencairan 80%, 20%',
        },
      },
    },
    4: {
      title: '2 Skema Pembayaran Cepat yang bisa dipilih',
      subSteps: {
        1: {
          title: 'Berdasarkan Surat Jalan',
          description: 'Pembayaran cepat yang didukung oleh pihak Kargo. Untuk proyek pengiriman baru yang butuh pendanaan dan belum pernah mengajukan program pembayaran cepat. Dimana Kargo berhubungan langsung dengan Customer Anda dan posisi Transporter sebagai vendor Kargo',
        },
        2: {
          title: 'Berdasarkan Invoice',
          description: 'Pembayaran cepat dengan dukungan P2P Lending. Untuk proyek pengiriman yang sudah bekerjasama antara Transporter dengan Shipper melalui Kargo. Dimana Kargo tidak berhubungan langsung dengan Customer Anda.',
        },
      },

    },
  },
  ok_text: 'Ya, Daftarkan',
  other_documents: 'Dokumen Lainnya',
  payment_project_list: 'Daftar Proyek Pembayaran',
  payment_scheme: 'Skema Pembayaran',
  payment_scheme_map: {
    [PAYMENT_PROJECT_SCHEME.QUICK_PAY]: 'Quick Pay',
    [PAYMENT_PROJECT_SCHEME.ENTERPRISE_REFERRAL]: 'Enterprise Referral',
  },
  payment_scheme_preference: 'Preferensi Skema Pembayaran',
  payment_status_map: {
    [PAYMENT_PROJECT_STATUSES.ACTIVE]: 'Aktif',
    [PAYMENT_PROJECT_STATUSES.INACTIVE]: 'Tidak Aktif',
    [PAYMENT_PROJECT_STATUSES.REJECTED]: 'Ditolak',
    [PAYMENT_PROJECT_STATUSES.SUBMITTED]: 'Diajukan',
    [PAYMENT_PROJECT_STATUSES.DRAFT]: 'Draft Pengajuan',
    [PAYMENT_PROJECT_STATUSES.UNDER_VERIFICATION]: 'Dalam Proses Verifikasi',
    [PAYMENT_PROJECT_STATUSES.NEED_REVISION]: 'Perlu Revisi',
    [PAYMENT_PROJECT_STATUSES.VERIFIED]: 'Verifikasi Berhasil',
  },
  project: 'Proyek',
  project_document_status_map: {
    [PROJECT_DOCUMENT_STATUSES.APPROVED]: 'Sesuai',
    [PROJECT_DOCUMENT_STATUSES.DRAFT]: 'Dalam Proses',
    [PROJECT_DOCUMENT_STATUSES.NOT_UPLOADED]: 'Belum Diunggah',
    [PROJECT_DOCUMENT_STATUSES.PENDING]: 'Dalam Penilaian',
    [PROJECT_DOCUMENT_STATUSES.REJECTED]: 'Tidak Sesuai',
    [PROJECT_DOCUMENT_STATUSES.REUPLOADED]: 'Dokumen Diupload Ulang',
    [PROJECT_DOCUMENT_STATUSES.REQUESTED]: 'Permintaan Dokumen Tambahan',
    [PROJECT_DOCUMENT_STATUSES.UNDER_VERIFICATION]: 'Dalam Proses Verifikasi',
  },
  project_request: 'Pengajuan Proyek',
  proposed_funding: 'Pendanaan yang Diajukan',
  payment_project_details_status_map: {
    [PAYMENT_PROJECT_STATUSES.ACTIVE]: 'Aktif',
    [PAYMENT_PROJECT_STATUSES.INACTIVE]: 'Tidak Aktif',
    [PAYMENT_PROJECT_STATUSES.REJECTED]: 'Ditolak',
    [PAYMENT_PROJECT_STATUSES.SUBMITTED]: 'Dalam Penilaian',
    [PAYMENT_PROJECT_STATUSES.UNDER_VERIFICATION]: 'Dalam Proses Verifikasi',
    [PAYMENT_PROJECT_STATUSES.NEED_REVISION]: 'Perlu Revisi',
    [PAYMENT_PROJECT_STATUSES.VERIFIED]: 'Verifikasi Berhasil',
  },
  project_detail: 'Detail Proyek',
  quick_pay: 'Berdasarkan Invoice',
  quick_pay_scheme: 'Skema Berdasarkan Invoice',
  register_project: 'Daftarkan Proyek',
  report_template: 'Template Laporan',
  required_documents: 'Dokumen Wajib',
  resubmit_verification: 'Ajukan Ulang',
  reupload: 'Reupload',
  review: 'Review',
  review_page_info: 'Selanjutnya, dokumen pengajuan akan diverifikasi oleh tim Kargo dan Anda dapat diminta untuk mengunggah dokumen tambahan jika dibutuhkan. Cek kembali semua dokumen untuk memperlancar proses pengajuan',
  scheme: 'Skema {{scheme}}',
  search_shipper_name: 'Cari Nama Shipper',
  sign_up_now: 'Daftar sekarang',
  size: 'Ukuran',
  some_documents_have_not_been_uploaded: 'Beberapa Dokumen belum terunggah',
  some_has_not_uploaded: 'Beberapa belum terupload',
  skip: 'Lewati',
  shared_on: 'Dibagikan pada {{date}}',
  shipper_documents: 'Dokumen Shipper',
  submission_review: 'Review Pengajuan',
  submit: 'Ajukan',
  submit_verification: 'Ajukan Verifikasi',
  submitted_limit: 'Pendanaan yang Diajukan',
  time_frame: 'Jangka Waktu',
  transporter_financial_documents: 'Dokumen Finansial Transporter',
  transporter_legal_documents: 'Dokumen Legal Transporter',
  transporter_main_documents: 'Dokumen Utama Transporter',
  try_quick_payment_service_now: 'Nikmati layanan Pembayaran Cepat sekarang!',
  update_financing_scheme_title: 'Apakah yakin ingin mengubah Preferensi Skema Pembayaran ?',
  update_financing_scheme_info: 'Beberapa Dokumen yang telah Anda upload akan terhapus dan Anda diperlukan untuk mengupload ulang dokumen yang baru',
  upload: 'Upload',
  upload_financial_legal_documents: 'Upload Dokumen Finansial & Legal',
  upload_verify_main_documents: 'Upload & Verifikasi Dokumen Utama',
  upload_customer_documents: 'Upload Dokumen Customer',
  yes_delete: 'Ya, Hapus',
  yes_its_appropriate: 'Ya, Sudah sesuai',
  yes_update_scheme: 'Ya, Ubah Skema',
  your_customer_detail: 'Detail Customer Anda',
  your_customer_company_name: 'Nama Perusahaan Customer Anda',
};
