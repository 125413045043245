/* eslint-disable max-len */

import {
  CONTAINER_STATUSES,
  FIRST_MILE_CONTAINER_SIZES,
  FIRST_MILE_DOCUMENT_TYPES,
} from '~/Configurations/constants';

export default {
  bill_of_lading: 'Bill of Lading',
  bulk_edit: 'Edit Bulk',
  container_arrival_eta: 'Estimated Arrival at Store',
  container_detail: 'Container Details',
  container_document_type: {
    [FIRST_MILE_DOCUMENT_TYPES.CONTAINER_RENTAL]: 'Container Rental Letter',
    [FIRST_MILE_DOCUMENT_TYPES.LOLO]: 'LOLO',
    [FIRST_MILE_DOCUMENT_TYPES.MATERIAL_SAFETY_AND_DATA_SHEET]: 'Material Safety Data Sheet',
    [FIRST_MILE_DOCUMENT_TYPES.PACKING_LIST]: 'Packing List',
    [FIRST_MILE_DOCUMENT_TYPES.PROOF_OF_DELIVERY]: 'Delivery Receipt',
    [FIRST_MILE_DOCUMENT_TYPES.SHIPPING_LINE_DELIVERY_ORDER]: 'Shipping Line Delivery Order',
    [FIRST_MILE_DOCUMENT_TYPES.TILA]: 'TILA',
  },
  container_holding_area: 'Holding Location',
  container_number: 'Container No.',
  container_return_depo: 'Return Depot',
  container_sizes: {
    [FIRST_MILE_CONTAINER_SIZES['20_FEET']]: '20"',
    [FIRST_MILE_CONTAINER_SIZES['20_FR']]: '20" FR',
    [FIRST_MILE_CONTAINER_SIZES['20_GP']]: '20" GP',
    [FIRST_MILE_CONTAINER_SIZES['20_OT']]: '20" OT',
    [FIRST_MILE_CONTAINER_SIZES['20_OW']]: '20" OW',
    [FIRST_MILE_CONTAINER_SIZES['40_FEET']]: '40"',
    [FIRST_MILE_CONTAINER_SIZES['40_FR']]: '40" FR',
    [FIRST_MILE_CONTAINER_SIZES['40_GP']]: '40" GP',
    [FIRST_MILE_CONTAINER_SIZES['40_HC']]: '40" HC',
    [FIRST_MILE_CONTAINER_SIZES['40_OT']]: '40" OT',
    [FIRST_MILE_CONTAINER_SIZES['40_OW']]: '40" OW',
    [FIRST_MILE_CONTAINER_SIZES['45_FEET']]: '45"',
  },
  container_status: {
    [CONTAINER_STATUSES.ARRIVED_AT_PORT]: 'Arrived at Port',
    [CONTAINER_STATUSES.INVOICED]: 'Invoiced',
    [CONTAINER_STATUSES.MOVED_TO_HOLDING]: 'Moved to Holding',
    [CONTAINER_STATUSES.NO_NOA]: 'NOA Not Received',
    [CONTAINER_STATUSES.NOA]: 'NOA',
    [CONTAINER_STATUSES.ON_SHIPMENT]: 'On Shipment',
    [CONTAINER_STATUSES.PAID]: 'Paid',
    [CONTAINER_STATUSES.PAYMENT_PENDING]: 'Payment Pending',
    [CONTAINER_STATUSES.PIB]: 'PIB',
    [CONTAINER_STATUSES.QUARANTINED]: 'Quarantined',
    [CONTAINER_STATUSES.SHIPMENT_COMPLETE]: 'Completed',
    [CONTAINER_STATUSES.SPPB]: 'SPPB',
    [CONTAINER_STATUSES.WAITING_FOR_ORIGINAL_DOC]: 'Waiting for Original Doc',
    [CONTAINER_STATUSES.WAITING_FOR_SPPB]: 'Waiting for SPPB',
  },
  container_type: 'Container Type',
  container_unloading_capacity: 'Daily Unloading Capacity',
  container_use_holding: 'Holding',
  container_using_holding: 'Container in Holding',
  container_weight: 'Container Weight',
  date_placed_in_holding: 'Date Placed in Holding',
  location_detail: 'Location Details',
  other_documents: 'Other Documents',
  port_address: 'Port Address',
  port_detail: 'Port Details',
  seal_number: 'Seal No.',
  terminal: 'Terminal',
};
