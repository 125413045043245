import { safeJSONParse } from '@kargotech/tms-common/Utilities/formatters';
import { useVariant } from '@unleash/proxy-client-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FEATURE_FLAG from '~/Configurations/constants/featureFlag';
import checkTheLorryTransporter from '~/Utilities/checkTheLorryTransporter';

export default function useTheLorryIntegration() {
  const SUP_THE_LORRY_NFT_MASKING = useVariant(FEATURE_FLAG.SUP_THE_LORRY_NFT_MASKING);
  const [isTheLorryTransporter, setIsTheLorryTransporter] = useState(false);

  const theLorryEntity = useMemo(() => {
    if (!SUP_THE_LORRY_NFT_MASKING?.enabled || !SUP_THE_LORRY_NFT_MASKING?.payload?.value) {
      return null;
    }

    return safeJSONParse(SUP_THE_LORRY_NFT_MASKING.payload.value);
  }, [SUP_THE_LORRY_NFT_MASKING]);

  const maskPbiCompanyToTheLorry = useCallback((entity = {}) => {
    if (typeof entity?.name !== 'string') {
      return null;
    }

    const isPbiName = theLorryEntity?.pbiNamesToMap?.some(
      pbiName => entity.name.toLowerCase().includes(pbiName.toLowerCase()),
    );
    if (isTheLorryTransporter && isPbiName) {
      return {
        name: theLorryEntity?.name,
        address: theLorryEntity?.address,
        npwp: theLorryEntity?.npwp,
      };
    }

    return entity;
  }, [isTheLorryTransporter, theLorryEntity]);

  useEffect(() => {
    setIsTheLorryTransporter(checkTheLorryTransporter());
  }, []);

  return {
    isTheLorryTransporter,
    maskPbiCompanyToTheLorry,
    theLorryEntity,
  };
}
