import { get } from 'lodash-es';
import {
  SHIPMENT_STATUSES,
} from '~/Configurations/constants';

export const TOTAL_HOURS_IN_A_DAY = 24;
export const TIME_WINDOW_DURATION = 2; // 2 hours
export const MAXIMUM_NAMED_MULTI_SHIPMENT_POINTS = 702;
// ONGOING TO PICKUP/DROPOFF, ARRIVE AT PICKUP/DROPOFF, START LOAD/UNLOAD, FINISH LOAD/UNLOAD
export const MULTI_SHIPMENT_POINT_STATUS_SEQUENCE_LENGTH = 4;
export const PLATFORM_NAME = 'NFT';

const PBI_METADATA = 'is_pbi';
const CONTAINER_METADATA = 'has_do_container_number';
const HAS_BODY_CODE_METADATA = 'body_code_info_access';

export const ROUTE_TYPE = {
  PICKUP: 'PICKUP',
  DROPOFF: 'DROPOFF',
};

/**
 * Check from the given company metadata
 * whether the company is Panthera Biru Indonesia
 * or not
 */
export const hasPbiMetadata = metadata => {
  if (!metadata) {
    return false;
  }

  if (typeof metadata === 'object') {
    return metadata[PBI_METADATA];
  }

  try {
    const { [PBI_METADATA]: isPbi } = JSON.parse(metadata);
    return isPbi;
  } catch (_) {
    return false;
  }
};

export const hasContainerMetadata = metadata => {
  if (!metadata) {
    return false;
  }

  if (typeof metadata === 'object') {
    return metadata[CONTAINER_METADATA];
  }

  try {
    const { [CONTAINER_METADATA]: hasContainerNumber } = JSON.parse(metadata);
    return hasContainerNumber;
  } catch (_) {
    return false;
  }
};

export const hasBodyCodeMetadata = metadata => {
  if (!metadata) {
    return false;
  }

  if (typeof metadata === 'object') {
    return metadata[HAS_BODY_CODE_METADATA];
  }

  try {
    const { [HAS_BODY_CODE_METADATA]: hasBodyCode } = JSON.parse(metadata);
    return hasBodyCode;
  } catch (_) {
    return false;
  }
};

export const mapRoutes = (shipmentPoints = [], type = '') => (
  (shipmentPoints || [])
    .filter(point => point.type === type)
    .map(point => ({
      name: get(point, 'name'),
      datetime: get(point, 'expectedDate.datetime'),
    }))
);

const {
  CANCELLED_BY_SHIPPER,
  CANCELLED_BY_TRANSPORTER,
  UNFULFILLED,
  REASSIGNED_BY_SHIPPER,
  REJECTED_BY_TRANSPORTER,
  CONFIRMATION_EXPIRED,
  ...SHIPMENT_ACTIVITY_LOG_STATUSES
} = SHIPMENT_STATUSES;

export { SHIPMENT_ACTIVITY_LOG_STATUSES };

export const VENDOR_ASSIGNMENT_TYPE = {
  BROADCAST_CONTRACTED: 'BROADCAST_CONTRACTED',
  PRIVATE_BIDDING: 'PRIVATE_BIDDING',
  SINGLE_CONTRACTED: 'SINGLE_CONTRACTED',
};

export const MAX_BID_LIMIT_TYPE = {
  VALUE: 'Rp',
  PERCENTAGE: '%',
};

export const CUSTOM_FIELDS = {
  EXTERNAL_ID: 'EXTERNAL_ID',
  CUSTOMER_ORDER_DATE: 'CUSTOMER_ORDER_DATE',
  SHIPMENT_TYPE: 'SHIPMENT_TYPE',
  INTERNAL_NOTES: 'INTERNAL_NOTES',
  OTHER: 'OTHER',
  CONTAINER_NUMBER: 'CONTAINER_NUMBER',
  SHIPPER_SEAL_NUMBER: 'SHIPPER_SEAL_NUMBER',
  CUSTOMER_SEAL_NUMBER: 'CUSTOMER_SEAL_NUMBER',
  CHASSIS_NUMBER: 'CHASSIS_NUMBER',
};

export const BTMS_POD_UPLOAD_STATUSES = [
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.START_UNLOADING,
  SHIPMENT_STATUSES.FINISH_UNLOADING,
];

export const BTMS_POL_UPLOAD_STATUSES = [
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
];

export const BTMS_POL_REUPLOAD_STATUSES = [
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION,
  SHIPMENT_STATUSES.ONGOING_TO_DESTINATION,
  SHIPMENT_STATUSES.ON_SHIPMENT,
];

export const BTMS_POD_REUPLOAD_STATUSES = [
  SHIPMENT_STATUSES.ON_SHIPMENT,
  SHIPMENT_STATUSES.ONGOING_TO_DESTINATION,
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.START_UNLOADING,
  SHIPMENT_STATUSES.FINISH_UNLOADING,
  SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION,
  SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
];

export const DOCUMENT_REQUEST_TYPE = {
  UPLOAD: 'UPLOAD',
  REUPLOAD: 'REUPLOAD',
};

export const SHIPMENT_DOCUMENT_STATUSES = {
  APPROVED: 'APPROVED',
  DISCARDED: 'DISCARDED',
  IGNORED: 'IGNORED',
  INVALID: 'INVALID',
  NEGLECTED: 'NEGLECTED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
};

export const SHIPMENT_DOCUMENT_STATUS_CONFIG = {
  [SHIPMENT_DOCUMENT_STATUSES.APPROVED]: {
    backgroundColor: '#D5F2E8',
    textColor: '#249D75',
  },
  [SHIPMENT_DOCUMENT_STATUSES.INVALID]: {
    backgroundColor: '#FDE9E8',
    textColor: '#AE1B18',
  },
  [SHIPMENT_DOCUMENT_STATUSES.PENDING]: {
    backgroundColor: '#EEEEFF',
    textColor: '#443FC2',
  },
  [SHIPMENT_DOCUMENT_STATUSES.REJECTED]: {
    backgroundColor: '#FDE9E8',
    textColor: '#AE1B18',
  },
};

export const POD_QUANTITY_INPUT_UNIT = [
  'Kg',
  'Kubikasi',
  'Unit',
  'Ton',
  'Box',
  'Karton',
  'Pcs',
  'Cases',
  'Koli',
  'Drum',
  'Bal',
  'Roll',
  'Bag',
  'Bottle',
  'Zak',
  'Meter',
];
