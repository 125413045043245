export const NOTIFICATION_CTA_TRANSLATION_KEY_ENUM = {
  ACCEPT_NOW: 'ACCEPT_NOW',
  COMPLETE_NOW: 'COMPLETE_NOW',
  FILL_TRUCK_DETAILS: 'FILL_TRUCK_DETAILS',
  FIND_OTHER_PAYLOAD: 'FIND_OTHER_PAYLOAD',
  MAKE_OFFER_NOW: 'MAKE_OFFER_NOW',
  SEE_DETAILS: 'SEE_DETAILS',
  VIEW_PARTNERSHIP_DETAILS: 'VIEW_PARTNERSHIP_DETAILS',
  VIEW_SHIPMENT_DETAILS: 'VIEW_SHIPMENT_DETAILS',
  VIEW_INVOICE_DETAILS: 'VIEW_INVOICE_DETAILS',
};

export const NOTIFICATION_CTA_TRANSLATION_TARGET = {
  '5-54': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.VIEW_PARTNERSHIP_DETAILS,
  '5-55': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.COMPLETE_NOW,
  '5-56': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.VIEW_PARTNERSHIP_DETAILS,
  '5-57': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.VIEW_PARTNERSHIP_DETAILS,
  '5-58': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.VIEW_PARTNERSHIP_DETAILS,
  '5-59': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.VIEW_PARTNERSHIP_DETAILS,
  '5-60': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.VIEW_PARTNERSHIP_DETAILS,
  '5-61': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.VIEW_PARTNERSHIP_DETAILS,
  '5-62': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.VIEW_SHIPMENT_DETAILS,
  '5-64': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.ACCEPT_NOW,
  '5-65': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.MAKE_OFFER_NOW,
  '5-66': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.ACCEPT_NOW,
  '5-67': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.FIND_OTHER_PAYLOAD,
  '5-68': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.SEE_DETAILS,
  '5-69': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.SEE_DETAILS,
  '5-70': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.SEE_DETAILS,
  '5-71': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.SEE_DETAILS,
  '5-72': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.SEE_DETAILS,
  '5-74': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.SEE_DETAILS,
  '5-75': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.SEE_DETAILS,
  '5-76': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.FILL_TRUCK_DETAILS,
  '5-77': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.VIEW_INVOICE_DETAILS,
  '5-79': NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.SEE_DETAILS,
};
