import React, { Fragment, useCallback, useContext, useEffect } from 'react';
import { Route as ReactRoute, Redirect, useLocation, useHistory } from 'react-router-dom';

import { PageSuspenseFallback } from '@kargotech/tms-ui/components';
import { AUTH_STATUS, AuthContext } from '@kargotech/tms-core/auth';
import { isEmpty } from 'lodash-es';
import { ProfileContext } from '../../Contexts/ProfileProvider';
import { NavigationContext } from '../../Contexts/NavigationProvider';
import checkTheLorryTransporter from '~/Utilities/checkTheLorryTransporter';

/**
 * Private Route: can only be accessed by authenticated user
 * @param {Object} props
 * @param {import('react').ReactElement} props.children
 * @param {import('react').ReactNode} props.Layout
 * @param {String} props.title
 * @param {String} props.subtitle
 * @param {Object[]} props.tabNavigation
 * @param {Boolean} props.skipTabNavigationSetup
 * @param {String} props.tabNavigation.path
 * @param {String} props.tabNavigation.title
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
function PrivateRoute({
  children,
  tabNavigation,
  Layout = Fragment,
  title,
  subtitle,
  skipTabNavigationSetup = false,
  ...routeProps
}) {
  const {
    authStatus,
    enrolledCompanies,
    isFirstTimeLogin,
    updateSessionStatus,
    selectedCompanyKsuid,
  } = useContext(AuthContext);
  const {
    getProfile,
    profile,
    resetProfile,
  } = useContext(ProfileContext);
  const {
    setTitle,
    setSubtitle,
    setTabNavigation,
  } = useContext(NavigationContext);

  const { pathname, search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    updateSessionStatus();
  }, [updateSessionStatus]);

  useEffect(() => {
    if (title !== undefined) {
      setTitle(title || '');
    }
    if (subtitle !== undefined) {
      setSubtitle(subtitle || '');
    }
    if (!skipTabNavigationSetup) {
      setTabNavigation(tabNavigation || []);
    }
  }, [title, setTitle, subtitle, setSubtitle, tabNavigation, setTabNavigation, skipTabNavigationSetup]);

  useEffect(() => {
    if (authStatus === AUTH_STATUS.AUTHENTICATED && selectedCompanyKsuid && isEmpty(profile)) {
      getProfile();
    }

    if (authStatus === AUTH_STATUS.NOT_AUTHENTICATED) {
      resetProfile();
    }
  }, [authStatus, getProfile, profile, resetProfile, selectedCompanyKsuid]);

  useEffect(() => {
    const isTheLorryTransporter = checkTheLorryTransporter();
    if (isFirstTimeLogin && !isTheLorryTransporter) {
      history.replace('/welcome');
    }
  }, [isFirstTimeLogin, history]);

  const wrapRender = useCallback(renderedEl => (
    <ReactRoute {...routeProps}>
      {renderedEl}
    </ReactRoute>
  ), [routeProps]);

  if (authStatus === AUTH_STATUS.LOADING
    || (authStatus === AUTH_STATUS.AUTHENTICATED && isEmpty(profile.company.ksuid))) {
    return wrapRender(
      <PageSuspenseFallback />,
    );
  }

  if (authStatus === AUTH_STATUS.NOT_AUTHENTICATED || !selectedCompanyKsuid) {
    return wrapRender(
      <Redirect
        to={{
          pathname: '/auth/login',
          state: { from: pathname, fromSearch: search },
        }}
      />,
    );
  }

  if (authStatus === AUTH_STATUS.AUTHENTICATED && !selectedCompanyKsuid && enrolledCompanies.length > 1) {
    history.replace('/auth/choose-company');
    return null;
  }

  return wrapRender(
    <Layout>
      {children}
    </Layout>,
  );
}

export default PrivateRoute;
