/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { LoadingSpinner } from '@kargotech/tms-ui/components';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import JOB_DETAILS from '~/Pages/Bidding/BiddingDoDetails/Queries/jobDetails_biddingDoJobDetails';
import useSearchParamsState from '~/Hooks/searchParamsStateHooks';
import { APOLLO_CLIENTS } from '~/Services/apollo';
import { PUSH_NOTIFICATION_CODES } from '~/Configurations/constants';

const JobRedirection = () => {
  const [{ jobKsuid, code }] = useSearchParamsState();

  const history = useHistory();

  const { data: { jobDetails } = {}, loading } = useQuery(JOB_DETAILS, {
    skip: !jobKsuid || jobKsuid === 'undefined',
    client: APOLLO_CLIENTS.LOAD,
    variables: { jobKsuid },
  });

  useEffect(() => {
    const shipmentKsuid = jobDetails?.shipment?.ksuid;
    // Redirect to the wallet for 5-97 code redirect to wallet page without jobDetail query
    if (code === PUSH_NOTIFICATION_CODES['5-97']) {
      history.replace(`/wallet`);
      return;
    }
    if (shipmentKsuid && code) {
      switch (code) {
        case PUSH_NOTIFICATION_CODES['5-94']:
        case PUSH_NOTIFICATION_CODES['5-95']:
          history.replace(`/bidding/details/available/${shipmentKsuid}`);
          break;
        case PUSH_NOTIFICATION_CODES['5-62']:
          history.replace(`/shipment/details/${shipmentKsuid}`);
          break;
        default:
          history.replace('/bidding/available');
          break;
      }
    }
  }, [code, history, jobDetails?.shipment?.ksuid, jobKsuid]);

  /**
   * Redirect to root page after timeout
   * Handle after loading finished/api called but not redirect to any page
   */
  useEffect(
    () => {
      if (!loading) {
        const handler = setTimeout(() => {
          history.replace('/');
        }, 5000);
        return () => {
          clearTimeout(handler);
        };
      }
    }, [history, loading],
  );

  return (
    <LoadingSpinner />
  );
};

export default JobRedirection;
