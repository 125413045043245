/* eslint-disable max-len */

import { NOTIFICATION_CTA_TRANSLATION_KEY_ENUM } from '~/Configurations/notificationUtilityMapping';

export default {
  notification_cta: {
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.ACCEPT_NOW]: 'Accept Now',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.COMPLETE_NOW]: 'Complete Now',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.FILL_TRUCK_DETAILS]: 'Fill Truck Details',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.FIND_OTHER_PAYLOAD]: 'Find Other Payload',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.MAKE_OFFER_NOW]: 'Make Offer Now',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.SEE_DETAILS]: 'See Details',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.VIEW_PARTNERSHIP_DETAILS]: 'View Partnership Details',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.VIEW_SHIPMENT_DETAILS]: 'View Shipment Details',
    [NOTIFICATION_CTA_TRANSLATION_KEY_ENUM.VIEW_INVOICE_DETAILS]: 'View Invoice',
  },
};
